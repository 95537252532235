export const getTranscriptFormattedDate = (date: Date): string => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = date.toLocaleString("default", { month: "short" });
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${day} ${month} ${hours}h${minutes}m`;
};
export const getNewTranscriptItemKey = (date: Date): string => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${year}${month}${day}${hours}${minutes}${seconds}`;
};
export const formatDateConvoList = (date: string) => {
    const d = new Date(date);
    d.setDate(d.getDate() + 1); // Add 30 days
    const year = d.getFullYear();
    const month = `0${d.getMonth() + 1}`.slice(-2); // Pad month with zero
    const day = `0${d.getDate()}`.slice(-2); // Pad day with zero
    return `${year}-${month}-${day}`;
};
export const formatDate30DaysConvoList = (date: string) => {
    const d = new Date(date);
    d.setDate(d.getDate() - 30); // Add 30 days
    const year = d.getFullYear();
    const month = `0${d.getMonth() + 1}`.slice(-2); // Pad month with zero
    const day = `0${d.getDate()}`.slice(-2); // Pad day with zero
    return `${year}-${month}-${day}`;
};
export const onSTT = (text: string, language: string): void => {
    console.log("¦whspr¦", text, language);
};
export const createWavBuffer = (samples: Int16Array, sampleRate: number): ArrayBuffer => {
    const buffer = new ArrayBuffer(44 + samples.length * 2);
    const view = new DataView(buffer);

    const bytesPerSample = 2; // 16-bit audio
    const numChannels = 1; // Mono
    const byteRate = sampleRate * numChannels * bytesPerSample;
    const blockAlign = numChannels * bytesPerSample;
    const bitsPerSample = 16;

    writeString(view, 0, "RIFF");
    view.setUint32(4, 36 + samples.length * bytesPerSample, true);
    writeString(view, 8, "WAVE");
    writeString(view, 12, "fmt ");
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, numChannels, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, byteRate, true);
    view.setUint16(32, blockAlign, true);
    view.setUint16(34, bitsPerSample, true);
    writeString(view, 36, "data");
    view.setUint32(40, samples.length * bytesPerSample, true);
    for (let i = 0; i < samples.length; i++) {
        view.setInt16(44 + i * 2, samples[i], true);
    }
    return buffer;
};
export const prepWavBuffer = (audioChunks: Float32Array[], recognitionResultOffset: number) => {
    const audioCtx = new AudioContext();
    const sampleRate = audioCtx.sampleRate;
    const startSample = Math.floor((recognitionResultOffset / 10000000) * sampleRate);
    const comboAudio = new Float32Array(audioChunks.reduce((acc, chunk) => acc + chunk.length, 0));
    let offset = 0;
    for (const chunk of audioChunks) {
        comboAudio.set(chunk, offset);
        offset += chunk.length;
    }
    let relevantAudio = comboAudio.slice(startSample);
    const int16Array = new Int16Array(relevantAudio.length);
    for (let i = 0; i < relevantAudio.length; i++) {
        int16Array[i] = Math.max(-32768, Math.min(32767, Math.floor(relevantAudio[i] * 32768)));
    }
    let wavBuffer = createWavBuffer(int16Array, sampleRate);
    return wavBuffer;
};
export const playWavBuffer = (buffer: ArrayBuffer) => {
    const audioContext = new AudioContext();
    audioContext.decodeAudioData(
        buffer,
        decodedData => {
            console.log("Decoded audio duration:", decodedData.duration);
            console.log("Decoded audio sample rate:", decodedData.sampleRate);
            console.log("Decoded audio number of channels:", decodedData.numberOfChannels);

            const source = audioContext.createBufferSource();
            source.buffer = decodedData;
            source.connect(audioContext.destination);
            source.start(0);
        },
        error => {
            console.error("Error decoding audio data", error);
        }
    );
};
const writeString = (view: DataView, offset: number, string: string) => {
    for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
    }
};
export const handleFocus = (event: any) => {
    event.target.setSelectionRange(event.target.value.length, event.target.value.length);
};
export const encodeHTML = (str: string): string => {
    return str.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, " ");
};
export const makeValidHtmlId = (str: string) => {
    // Remove any character that isn't a letter, number, hyphen, underscore, colon, or period
    let validStr = str.replace(/[^a-zA-Z0-9-_:.]/g, "");
    // Ensure starts with letter
    if (!/^[a-zA-Z]/.test(validStr)) validStr = "id-" + validStr;

    // Ensure it's not empty
    if (validStr.length === 0) validStr = "id";

    return validStr;
};
export const shuffleArray = (array: string[]) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
};
export const getLoc = () => {
    return window.location.hash.replace("#", "");
};
export const genNewConvoKey = () => {
    return new Date() // Convo Key (Datetime String)
        .toISOString()
        .replace(/[-:.TZ]/g, "")
        .slice(0, 14);
};
export const isElementVisible = (eid: string) => {
    const element = document.getElementById(eid);
    if (!element) return false;
    return true;
};
