import React, { useState } from "react";
import { Callout, TextField, PrimaryButton, Icon, DirectionalHint } from "@fluentui/react";

import styles from "./../SpaceUserListV2/SpaceUserListV2.module.css";
import { gselectedgroup } from "../../interfaces";
import { toast } from "react-toastify";

interface InviteUserCalloutProps {
    isOpen: boolean;
    onDismiss: () => void;
    addUserButtonRef: React.RefObject<HTMLElement>;
    inviteUser: (usr: string) => void;
    selectedGroup: gselectedgroup;
    activeConvoKey: string;
}

interface NewInviteUser {
    membermail: string;
}

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const InviteUserCallout: React.FC<InviteUserCalloutProps> = ({ isOpen, onDismiss, addUserButtonRef, inviteUser, selectedGroup, activeConvoKey }) => {
    const [newInviteUser, setNewInviteUser] = useState<NewInviteUser>({
        membermail: ""
    });
    const [isLoadingAPI, setIsLoadingAPI] = useState(false);
    const [expandedSection, setExpandedSection] = useState("");

    const handleEmailChange = (_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        setNewInviteUser({ ...newInviteUser, membermail: newValue || "" });
    };

    const handleInviteUser = (usr: NewInviteUser) => {
        if (!isLoadingAPI) {
            setIsLoadingAPI(true);
            inviteUser(usr.membermail);
        }
    };

    const textFieldStyles = {
        root: { borderRadius: "5px" },
        fieldGroup: {
            borderRadius: "5px",
            selectors: {
                "::after": {
                    borderRadius: "5px ",
                    border: "2px solid #404040"
                }
            }
        }
    };

    const calloutStyles = {
        root: { width: 300, borderRadius: "10px" },
        calloutMain: { borderRadius: "10px" },
        beakCurtain: { borderRadius: "10px" }
    };

    const renderSectionContent = (title: string) => {
        switch (title) {
            case "Get Link to Share Space":
                return (
                    <span>
                        <div className={styles.redeemCodeBox}>
                            {getShareURL(false)}
                            <button className={styles.copyRedeemCodeButton} onClick={e => copyToClipboard(getShareURL(false))}>
                                Copy
                            </button>
                        </div>
                    </span>
                );
            case "Get Link to Share Space and Active Conversation":
                return (
                    <span>
                        <div className={styles.redeemCodeBox}>
                            {getShareURLConvo(false)}
                            <button className={styles.copyRedeemCodeButton} onClick={e => copyToClipboard(getShareURLConvo(false))}>
                                Copy
                            </button>
                        </div>
                    </span>
                );
            case "Add User to Space by Email":
                return (
                    <>
                        <TextField label="Email" value={newInviteUser.membermail} onChange={handleEmailChange} styles={textFieldStyles} />
                        <PrimaryButton
                            text="Share Space"
                            styles={{ root: { width: "100%", borderRadius: "8px", marginTop: "10px" }, label: { fontSize: "14px", fontFamily: "Urbanist" } }}
                            onClick={() => handleInviteUser(newInviteUser)}
                            className={styles.inviteButton}
                            disabled={isLoadingAPI || newInviteUser.membermail.length < 1 || !emailRegex.test(newInviteUser.membermail)}
                        />
                    </>
                );
            default:
                return <span></span>;
        }
    };
    const copyToClipboard = (text: string) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {})
            .catch(err => {});

        toast.success("Invitation URL Copied");
    };
    const getShareURL = (anon: boolean) => {
        let sUrl = `${window.location.origin}?redeemCode=${encodeURIComponent(selectedGroup.selectionId)}&spt=${encodeURIComponent(
            selectedGroup.selectionText
        )}`;
        if (anon) {
            sUrl = `${sUrl}&A=1`;
        }
        return sUrl;
    };
    const getShareURLConvo = (anon: boolean) => {
        let sUrl = `${window.location.origin}?redeemCode=${encodeURIComponent(selectedGroup.selectionId)}&spt=${encodeURIComponent(
            selectedGroup.selectionText
        )}&convCode=${activeConvoKey}`;
        if (anon) {
            sUrl = `${sUrl}&A=1`;
        }
        return sUrl;
    };
    return (
        <Callout
            gapSpace={0}
            styles={calloutStyles}
            target={addUserButtonRef.current}
            onDismiss={onDismiss}
            setInitialFocus
            directionalHint={DirectionalHint.bottomLeftEdge}
            className={styles.calloutMain}
            hidden={!isOpen}
        >
            <div className={styles.calloutContent}>
                {[
                    selectedGroup.sharewithcode && selectedGroup.permissionlevel > 1 ? "Get Link to Share Space" : null,
                    selectedGroup.sharewithcode && selectedGroup.permissionlevel > 1 ? "Get Link to Share Space and Active Conversation" : null,
                    selectedGroup.permissionlevel > 1 ? "Add User to Space by Email" : null
                ].map(
                    title =>
                        title && (
                            <div key={title} className={styles.expandableSection}>
                                <div className={styles.sectionHeader} onClick={() => setExpandedSection(expandedSection === title ? "" : title)}>
                                    {title}
                                    <Icon iconName={expandedSection === title ? "ChevronUp" : "ChevronDown"} />
                                </div>
                                <div className={`${styles.sectionContent} ${expandedSection === title ? styles.expanded : ""}`}>
                                    {renderSectionContent(title)}
                                </div>
                            </div>
                        )
                )}
            </div>
        </Callout>
    );
};

export default InviteUserCallout;
