import React, { useState, useEffect } from "react";
import kgnfLogoOnly from "../../assets/kognifyLogo.png";
import styles from "./ProductPage.module.css";
import { guser, gtiers, gactivecomp, gselectedgroup } from "../../interfaces";
import axios from "axios";

// Define the prop types for the message component (if needed)
interface IMessageProps {
    message: string;
}

// Define the main ProductPage props (if any)
interface IProductPageProps {
    tier: gtiers;
    user: guser;
    activeComp: gactivecomp;
    selectedGroup: gselectedgroup;
    buText: string;
    endPoint: string;
    isAdd: boolean;
}

// Message component type definition using FC
const Message: React.FC<IMessageProps> = ({ message }) => (
    <section className={styles.productSection}>
        <div className={styles.productP}>{message}</div>
    </section>
);

// Main component with typed props
const ProductPage: React.FC<IProductPageProps> = ({ tier, user, activeComp, selectedGroup, buText, endPoint, isAdd }) => {
    const [message, setMessage] = useState<string>("");

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
            setMessage("Order placed! You will receive an email confirmation.");
        }

        if (query.get("canceled")) {
            setMessage("Order canceled -- continue to shop around and checkout when you're ready.");
        }
    }, []);
    const handleCancelSubmit = async (event: any) => {
        event.preventDefault();

        const formData = new FormData(event.target);

        try {
            const response = await axios.post(endPoint, formData);
            if (response.data.redirect_url) {
                //    window.location.href = response.data.redirect_url;
            } else {
                // Handle the response as needed
                window.location.href = "/";
                setMessage("Subscription process initiated successfully");
            }
        } catch (error) {
            console.error("Error:", error);
            setMessage("An error occurred. Please try again.");
        }
    };
    return (
        <div className={styles.productContainer}>
            <section className={styles.productSection}>
                {message ? (
                    <Message message={message} />
                ) : (
                    <>
                        <div className={styles.product}>
                            <img className={styles.productImg} src={kgnfLogoOnly} alt={`${tier.name}`} />
                            <div className={styles.description}>
                                <div className={styles.productH3}>{tier.name}</div>
                                <div className={styles.productH5}>€{tier.monthlypayment}</div>
                            </div>
                        </div>
                        {isAdd == true ? (
                            <form action={endPoint} method="POST">
                                <input type="hidden" name="userid" value={user.userId} />
                                <input type="hidden" name="subscriptionid" value={user.subscriptionid} />
                                <input type="hidden" name="tierid" value={tier.tier} />
                                <input type="hidden" name="compid" value={activeComp.companyid} />
                                <input type="hidden" name="groupid" value={selectedGroup.selectionId} />
                                <button className={styles.productButton} type="submit">
                                    {buText}
                                </button>
                            </form>
                        ) : (
                            <form onSubmit={handleCancelSubmit}>
                                <input type="hidden" name="userid" value={user.userId} />
                                <input type="hidden" name="subscriptionid" value={user.subscriptionid} />
                                <input type="hidden" name="tierid" value={tier.tier} />
                                <input type="hidden" name="compid" value={activeComp.companyid} />
                                <input type="hidden" name="groupid" value={selectedGroup.selectionId} />
                                <button className={styles.productButton} type="submit">
                                    {buText}
                                </button>
                            </form>
                        )}
                    </>
                )}
            </section>
        </div>
    );
};

// Default props for ProductPage
//ProductPage.defaultProps = {
//    productName: "Stubborn Attachments",
//    productPrice: "$20.00",
//    productImage: "https://i.imgur.com/EHyR2nP.png"
//};

export default ProductPage;
