import { IDropdownOption } from "@fluentui/react";
import pps from "./assets/pps.png";
import hda from "./assets/hda.png";
import aas from "./assets/aas.png";
import ipa from "./assets/ipa.png";
import tam from "./assets/tam.png";
import mic from "./assets/mic.png";
import cmp from "./assets/cmp.png";
import vid from "./assets/vid.png";

import anya from "../../frontend/src/assets/anya.png";

export interface finField {
    id: string;
    name: string;
    level: "main" | "item";
    description?: string;
    default?: boolean;
    systemDisabled: boolean;
}
export const finFieldList: finField[] = [
    // Main Fields
    {
        id: "contractor_name",
        name: "Contractor Name",
        level: "main",
        description: "The name of the Contractor/Supplier/Seller",
        default: true,
        systemDisabled: true
    },
    {
        id: "contractor_address",
        name: "Contractor Address",
        level: "main",
        description: "The address of the Contractor/Supplier/Seller",
        default: true,
        systemDisabled: true
    },
    {
        id: "contractor_vat_identification",
        name: "Contractor VAT Identification",
        level: "main",
        description: "The VAT Idenitification of the Contractor/Supplier/Seller",
        default: true,
        systemDisabled: true
    },
    { id: "customer_name", name: "Customer Name", level: "main", description: "The name of the Customer/Buyer,Reciever", default: true, systemDisabled: true },
    {
        id: "customer_address",
        name: "Customer Address",
        level: "main",
        description: "The address of the Customer/Buyer,Reciever",
        default: true,
        systemDisabled: true
    },
    {
        id: "customer_vat_identification",
        name: "Customer VAT Identification",
        level: "main",
        description: "The VAT Idenitification of the Customer/Buyer,Reciever",
        default: true,
        systemDisabled: true
    },
    { id: "invoice_total", name: "Invoice Total", level: "main", description: "The Grand Total amount of the Invoice", default: true, systemDisabled: true },
    { id: "invoice_date", name: "Invoice Date", level: "main", description: "The Date of the Invoice", default: true, systemDisabled: true },
    { id: "invoice_id", name: "Invoice ID", level: "main", description: "The ID of the Invoice", default: true, systemDisabled: true },
    { id: "invoice_currency", name: "Invoice Currency", level: "main", description: "The Currency of the Invoice", default: true, systemDisabled: true },
    { id: "vat", name: "VAT", level: "main", description: "The VAT Total amount of the Invoice", default: true, systemDisabled: true },
    { id: "purchase_order", name: "Purchase Order", level: "main", description: "The Purchase Order number", default: true, systemDisabled: false },
    { id: "iban", name: "IBAN", level: "main", description: "The IBAN number of the Customer/Buyer,Reciever", default: true, systemDisabled: false },
    { id: "page", name: "Page", level: "main", description: "The Page number of the Invoice (System Field)", default: true, systemDisabled: true },
    { id: "invoice_items", name: "Invoice Items", level: "main", description: "The Items of the Invoice", default: true, systemDisabled: true },
    // Item-Level Fields
    { id: "description", name: "Description", level: "item", description: "The Description of the Item", default: true, systemDisabled: false },
    { id: "quantity", name: "Quantity", level: "item", description: "The Quantity of the Item", default: true, systemDisabled: true },
    { id: "unit_price", name: "Unit Price", level: "item", description: "The Unit Price of the Item", default: true, systemDisabled: true },
    { id: "total_price", name: "Total Price", level: "item", description: "The Total Price of the Item", default: true, systemDisabled: true },
    { id: "item_number", name: "Item Number", level: "item", description: "The Sequential Number of the Item", default: true, systemDisabled: true },
    { id: "item_code", name: "Item Code", level: "item", description: "The Code of the Item", default: true, systemDisabled: false },
    { id: "item_measure", name: "Item Measure", level: "item", description: "The Measure of the Item", default: true, systemDisabled: false },
    { id: "item_type", name: "Item Type", level: "item", description: "The Type of the Item", default: true, systemDisabled: false },
    { id: "item_barcode", name: "Item Barcode", level: "item", description: "The Barcode of the Item", default: false, systemDisabled: false },
    {
        id: "item_secondary_id",
        name: "Item Secondary Id",
        level: "item",
        description: "Secondary Id of the Item",
        default: false,
        systemDisabled: false
    }
];

export const imgMap = {
    pps: pps,
    hda: hda,
    aas: aas,
    ipa: ipa,
    tam: tam,
    mic: mic,
    cmp: cmp,
    vid: vid,
    anya: anya,
    "": anya
};
export const nmeMap = {
    pps: "Policies & Procedures Assistant",
    hda: "IT Helpdesk Assistant",
    aas: "Automation API Agent",
    ipa: "Invoice Processing Agent",
    tam: "Technical Onboarding Mentor",
    anya: "Multi-Purpose Assistant",
    mic: "Interview Assistant",
    cmp: "Changes Assistant",
    vid: "Video Content Assistant",
    "": "Multi-Purpose Assistant"
};
export type ImgMapKeys = "pps" | "hda" | "aas" | "ipa" | "tam" | "mic" | "cmp" | "vid" | "";

export const assistantCardItems = [
    {
        title: "Policies & Procedures Assistant",
        description: "Provides quick, accurate and up-to-date information on Company policies and procedures.",
        imageSrc: pps,
        disabled: false,
        creationData: {
            custaiinst:
                "You are an assistant that provides quick, accurate and up-to-date information on company policies and procedures. You are here to help employees find the information they need to do their jobs effectively and efficiently.",
            custgreetingmessage:
                "Hello! I'm here to help you with any questions you have about the company policies and procedures.<br><br>How can I help you today?",
            allowyoutube: 0,
            allowweb: 1,
            invoicesgroup: 0,
            allowaudiodocentry: 0,
            assistanttype: "pps",
            showexamplequestionsonstart: 1,
            usedocintel: 1,
            forceocr: 0
        }
    },
    {
        title: "IT Helpdesk Assistant",
        description: "Optimized for IT Support, FAQs. Provides solutions and sources increasing first call resolution. (Integration options comming soon)",
        imageSrc: hda,
        disabled: false,
        creationData: {
            custaiinst:
                "You are a Helpdesk assistant that provides solutions and sources. You are here to help employees with IT support, troubleshooting, and FAQs.",
            custgreetingmessage: "Hello! I'm here to help you with any **support**, **troubleshooting**, and **FAQs**.<br><br>How can I help you today?",
            allowyoutube: 0,
            allowweb: 1,
            invoicesgroup: 0,
            allowaudiodocentry: 0,
            assistanttype: "hda",
            showexamplequestionsonstart: 1,
            usedocintel: 1,
            forceocr: 0
        }
    },
    {
        title: "Automation API Agent",
        description: "Primed for Business Process Automation, RPA and API integrations.",
        imageSrc: aas,
        disabled: false,
        creationData: {
            custaiinst: " ",
            custgreetingmessage: "Hi, this is an AI Automation API Agent.<br><br>What can I do for you?",
            allowyoutube: 0,
            allowweb: 1,
            invoicesgroup: 1,
            allowaudiodocentry: 0,
            assistanttype: "aas",
            showexamplequestionsonstart: 1,
            usedocintel: 0,
            forceocr: 0
        }
    },
    {
        title: "Invoice Processing Agent",
        description: "Fully automated invoice processing and reporting. Invoice Data extraction and validation.",
        imageSrc: ipa,
        disabled: false,
        creationData: {
            custaiinst: " ",
            custgreetingmessage: "Hi, I'm here to help you with invoice processing and reporting.<br><br>How can I help you today?",
            allowyoutube: 0,
            allowweb: 1,
            invoicesgroup: 1,
            allowaudiodocentry: 0,
            assistanttype: "ipa",
            showexamplequestionsonstart: 1,
            usedocintel: 1,
            forceocr: 0
        }
    },
    {
        title: "Technical Onboarding Mentor",
        description: "Optimized for technical documentation, onboarding and training.",
        imageSrc: tam,
        disabled: false,
        creationData: {
            custaiinst: "You are a Technical Onboarding Mentor AI assistant that provides technical documentation, onboarding and training.",
            custgreetingmessage: "Hello! I'm here to help you with any technical documentation and training.<br><br>How can I help you today?",
            allowyoutube: 0,
            allowweb: 1,
            invoicesgroup: 0,
            allowaudiodocentry: 0,
            assistanttype: "tam",
            showexamplequestionsonstart: 1,
            usedocintel: 1,
            forceocr: 0
        }
    },
    {
        title: "Interview Assistant",
        description: "Records and transcribes interviews. Analyses and provides tools to extract insights from interviews. (Beta)",
        imageSrc: mic,
        disabled: false,
        creationData: {
            custaiinst: "You are an assistant that helps users get the most out of their interview data.",
            custgreetingmessage: "Hello! I'm here to help you get the most out of your interview data. How can I help you today?",
            allowyoutube: 0,
            allowweb: 1,
            invoicesgroup: 0,
            allowaudiodocentry: 1,
            assistanttype: "mic",
            showexamplequestionsonstart: 1,
            usedocintel: 0,
            forceocr: 0
        }
    },
    {
        title: "Changes Assistant",
        description: "Intelligently compare and analyze differences in content. (Beta)",
        imageSrc: cmp,
        disabled: false,
        creationData: {
            custaiinst: "You are an assistant that helps users compare and analyze changes in documents.",
            custgreetingmessage: "Hello! I'm here to help you compare and analyze changes in documents. How can I help you today?",
            allowyoutube: 0,
            allowweb: 0,
            invoicesgroup: 0,
            allowaudiodocentry: 1,
            assistanttype: "cmp",
            showexamplequestionsonstart: 1,
            usedocintel: 1,
            forceocr: 1
        }
    },
    {
        title: "Video Content Assistant",
        description: "Upload and consult with Video Content. Convert Video to Presentations. (Beta)",
        imageSrc: vid,
        disabled: false,
        creationData: {
            custaiinst: "You are an assistant that helps users recal content from videos.",
            custgreetingmessage: "Hello! I'm here to help you learn from videos?",
            allowyoutube: 0,
            allowweb: 0,
            invoicesgroup: 0,
            allowaudiodocentry: 0,
            assistanttype: "vid",
            showexamplequestionsonstart: 1,
            usedocintel: 0,
            forceocr: 0,
            allowvideofiles: 1
        }
    }
];
export const multiPurposeAssistantCreationData = {
    custaiinst: "You are an assistant that helps users by answering questions and providing information.",
    custgreetingmessage: "Hello! I'm here to help you with any questions that you might have. How can I help you today?",
    allowyoutube: 0,
    allowweb: 1,
    invoicesgroup: 0,
    allowaudiodocentry: 0,
    assistanttype: "",
    showexamplequestionsonstart: 1,
    usedocintel: 1,
    forceocr: 0,
    allowvideofiles: 1
};
export const detailsListContentTypeOptions = [
    { key: "document", text: "document" },
    { key: "invoice", text: "invoice" },
    { key: "form", text: "form" },
    { key: "receipt", text: "receipt" },
    { key: "contract", text: "contract" },
    { key: "book", text: "book" },
    { key: "policy", text: "policy" },
    { key: "data", text: "data" },
    { key: "other", text: "other" }
];
export const transcriptLanguageOptions: IDropdownOption[] = [
    { key: "en-US", text: "English" },
    { key: "bg-BG", text: "Bulgarian" }
];

export const defaultPromptTemplateAsInPythonBackend = `"Assistant helps find answers to questions about information in the available documents. If there isn't enough information below, ask for more specific information."`;
export const defaultGreetingsMessage = `<div class="answerText">Hey,</div>
<div class="answerText">I'm <b>Kognify </b> and I'm here to help you understand and get insights from your content.</div>
<div class="answerText">You can chat with me or you can check the Documents that I know about in the Manage Section</div>
<div class="answerCardContainer">
</answerSuggestions>
</div>`;
export const msgLmtHit = "Space Messages Exhausted for the day. Please Consider Upgrading to Premium";

export const defaultGreetingContent = `
<div class="greetingPretext">It looks like there is no content for this Assistant and you have permissions to add content. Head on to the Content Section</div>
<div class="answerCard" onclick="window.greetingMessageViewDocs('')">
    <div class="greetingItemTitle" style="font-size: 24px; color: ">View Content List</div>
    <div class="topGreetingText">View what documents are available to this Assistant. Add Content to get started.</div>
</div>
`;
export const noContentNoAccessGreetingContent = `<div class="greetingPretext">It looks like there is no content for this Assistant yet.</div>`;

export const answerCardHTML = `
<div class="answerCard" onclick="window.greetingMessageBeginConversation('{kgnf_replace}');">
    <div class="topGreetingText">Suggested Question</div>
    <hr class="hrzntlLn">
    <i class="fas fa-lightbulb" style="font-size: 24px; color: #ffc107;"></i>
    <h3 class="greetingItemTitle">{kgnf_replace}</h3>
</div>
`;
