import React, { useEffect, useContext, useState, useRef, useCallback } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import type { PDFDocumentProxy, PDFPageProxy } from "pdfjs-dist";
import styles from "../ViewerPDF/ViewerPDF.module.css";
import stylesXplrr from "./XplrrBOXPDF.module.css";
import { GlobalContext } from "../../GlobalContext";
import { gdiff, gfile, gpagedimensions, gpdftextitem } from "../../interfaces";
import debounce from "lodash/debounce";
import { ProgressIndicator } from "@fluentui/react/lib/ProgressIndicator";
import { ImFilesEmpty } from "react-icons/im";
import { MdErrorOutline } from "react-icons/md";
import "react-pdf/dist/cjs/Page/AnnotationLayer.css";
import "react-pdf/dist/cjs/Page/TextLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

(pdfjs.GlobalWorkerOptions as any).fontExtraProperties = true;
(pdfjs.GlobalWorkerOptions as any).disableFontFace = false;
(pdfjs.GlobalWorkerOptions as any).useSystemFonts = true;

let timeoutId: number;

const contentPath = (filename: string, fileid: string) => {
    return `${filename}_fid=${fileid}`;
};
const pdfCacheKey = (fId: string, modifiedDateString: string) => {
    try {
        let mdfd = new Date(modifiedDateString).getTime();
        return `${fId}_${mdfd}`;
    } catch {
        return `${fId}`;
    }
};

interface PDFBoxDetails {
    file: gfile | undefined;
    handleTimeout: (fileid: string) => void;
    handleScroll: (pageNumCur: number, event: any) => void;
    setPages: (numPages: number) => void;
    startPage: number;
    callBackVisibleEntries: (vis: number[]) => void;
    setContentList: (contentList: { title: string; dest: number }[]) => void;
    callBackIsLoading: (isL: boolean) => void;
    companyId: string;
    srcLoc: string;
    diff?: gdiff[];
}
interface PDFPageDetails {
    handleTimeout: (fileid: string) => void;
    setContentList: (contentList: { title: string; dest: number }[]) => void;
    nums: number;
    pdf: PDFDocumentProxy;
    startPage: number;
    fileid: string;
    fileIndexChunks: number[];
    filePageDimsState: gpagedimensions[] | [];
}
function areAllFlagsTrue(numbers: number[], map?: Map<number, { count: number; flag: boolean }>): boolean {
    for (const number of numbers) {
        const item = map?.get(number);
        if (!item || !item.flag) {
            return false;
        }
    }
    return true;
}
function findRange(numbers: number[], target: number): number[] {
    numbers.sort((a: number, b: number) => a - b);
    let startIndex = numbers.findIndex((num: number) => num > target);
    let upper = startIndex === -1 ? numbers[numbers.length - 1] : numbers[startIndex];
    let endIndex = numbers.lastIndexOf(target);
    let lower = endIndex !== -1 ? numbers[endIndex] : numbers[startIndex - 1];
    if (lower === undefined || upper === undefined) {
        return [];
    }
    let result = [];
    for (let i = lower; i <= upper; i++) {
        result.push(i - 1);
    }
    if (target === upper) {
        result.push(upper - 1);
    }
    return result;
}
const getDesiredWidth = (srcLoc: string): number => {
    let widthFactor = 1.9;
    if (srcLoc === "modal" || srcLoc === "inv") {
        // Directly related to components\XplrrPDF\XplrrPDF.module.css - pdfViewC
        let modifierReduct = 2;

        if (window.innerWidth > 1800) {
            widthFactor = 1.8 * modifierReduct;
        } else if (window.innerWidth > 1600) {
            widthFactor = 1.6 * modifierReduct;
        } else if (window.innerWidth > 1400) {
            widthFactor = 1.4 * modifierReduct;
        } else if (window.innerWidth > 1200) {
            widthFactor = 1.3 * modifierReduct;
        } else if (window.innerWidth > 1000) {
            widthFactor = 1.2 * modifierReduct;
        } else if (window.innerWidth > 800) {
            widthFactor = 1.13 * modifierReduct;
        } else if (window.innerWidth > 600) {
            widthFactor = 1.08 * modifierReduct;
        } else if (window.innerWidth > 470) {
            widthFactor = 1.1 * modifierReduct;
        } else if (window.innerWidth > 350) {
            widthFactor = 1.11 * modifierReduct;
        } else if (window.innerWidth > 300) {
            widthFactor = 1.15 * modifierReduct;
        } else {
            //Smallest
            widthFactor = 1.15 * modifierReduct;
        }
    } else {
        // "sbs"
        if (window.innerWidth > 800) {
            widthFactor = 1.85;
        } else {
            //Smallest
            widthFactor = 1.85;
        }
    }
    try {
        return window.innerWidth / widthFactor;
    } catch (e) {
        console.log("gtDsirdWdth Error", e);
        return 800;
    }
};
const getDesiredHeight = (pageDims: gpagedimensions, srcLoc: string) => {
    const A4_ASPECT_RATIO = 297 / 210;
    const originalWidth = pageDims?.width;
    const originalHeight = pageDims?.height;
    const desiredWidth = getDesiredWidth(srcLoc);

    if (originalWidth && originalHeight) {
        const aspectRatio = originalHeight / originalWidth;
        return desiredWidth * aspectRatio;
    }

    return desiredWidth * A4_ASPECT_RATIO;
};
const XplrrBOXDIFFPDF = ({
    file,
    handleTimeout,
    handleScroll,
    setPages,
    startPage,
    callBackVisibleEntries,
    setContentList,
    callBackIsLoading,
    companyId,
    srcLoc,
    diff
}: PDFBoxDetails) => {
    const [progress, setProgress] = useState<number>();
    const [isLoa, setIsLoa] = useState(true);
    const [fileIndexChunks, setFileIndexChunks] = useState<number[]>([]);
    const [pgs, setPgs] = useState<React.ReactElement<any, any>[]>([]);
    const [isBlobPulling, setIsBlobPulling] = useState<boolean>(false);
    const [loadingMessage, setLoadingMessage] = useState<string>();
    const pageRefs = useRef<(Element | null)[]>([]);
    const observerRef = useRef<IntersectionObserver | null>(null);
    const containerRef = useRef(null);
    const [resizeCount, setResizeCount] = useState(0);
    const [scrollTr, setScrollTr] = useState("");
    const [rescaleWidth, setRescaleWidth] = useState<number>(getDesiredWidth(srcLoc));
    const [filePageDimsState, setFilePageDimsState] = useState<gpagedimensions[] | []>([]);
    const [loadMark, setLoadMark] = useState<string>();
    const [onDemandRefresh, setOnDemandRefresh] = useState<number>(0);

    const { fileList } = useContext(GlobalContext);

    useEffect(() => {
        const handleResize = debounce(() => {
            setResizeCount(prevCount => prevCount + 1);
            setRescaleWidth(getDesiredWidth(srcLoc));
        }, 100);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
            handleResize.cancel();
        };
    }, []);

    useEffect(() => {
        console.log("diff_mo");
        if (!file?.fileid) return;

        console.log("diff_ue");
        let visibleEntries: number[] = [];
        let cacheEntries: number[] = [];
        observerRef.current = new IntersectionObserver(
            entries => {
                let currentEntries: number[] = [];
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const pageNumber = parseInt((entry.target as HTMLElement).dataset.pageNumber || "1", 10);
                        if (!visibleEntries.includes(pageNumber)) {
                            visibleEntries.push(pageNumber);
                        }
                        currentEntries.push(pageNumber);
                    }
                });
                visibleEntries = visibleEntries.sort((a, b) => a - b);
                if (currentEntries.length < 1) {
                    currentEntries = cacheEntries;
                }
                visibleEntries = currentEntries;
                const minVisible = Math.min(...visibleEntries);
                const maxVisible = Math.max(...visibleEntries);
                for (let i = 1; i <= 5; i++) {
                    visibleEntries.push(maxVisible + i);
                    visibleEntries.push(minVisible - i);
                }
                if (currentEntries.length > 0) {
                    cacheEntries = currentEntries;
                }
                callBackVisibleEntries(visibleEntries);
                saveEntriesToGlobal(currentEntries);
            },
            { root: containerRef.current, rootMargin: "1000px 0px" }
        );
        pageRefs.current.forEach(ref => {
            if (ref) observerRef?.current?.observe(ref);
        });
    }, [file, resizeCount]);

    useEffect(() => {
        callBackIsLoading(true);
        wigglePixel();
        setLoadMark(`${file ? "fileY" : "fileN"}_${file?.fileid}_${startPage}_${window.globalBase64StringDiff?.get(file?.fileid ?? "0")?.length}`);

        if (!file) return;

        console.log("file", file);
        let fileIdStr = `${file?.fileid}`;

        if (file.indexchunks && startPage) {
            setFileIndexChunks(findRange(file.indexchunks, startPage));
        } else {
            setFileIndexChunks([startPage - 1]);
        }
        const cacheBase64Wndw = async (fl: gfile, fileIdStr: string): Promise<boolean> => {
            console.log("diff_cbw");

            if (!fl || !fl.fileid || !fl.modified) return false;

            setIsBlobPulling(true);
            initCache();
            initDiffCache();

            const cacheKey = pdfCacheKey(fl.fileid, fl.modified);
            const flPath = contentPath(fl.name, fl.fileid);
            if (!window.globalPDFCache.has(cacheKey) && window.globalPreLoadMap.has(cacheKey)) return false;

            if (window.globalPDFCache.has(cacheKey)) {
                setLoadingMessage(`CONTENT FETCH ${Math.round(parseInt(fl.size) / (1024 * 1024))}MB`);
                const cacheVal = window.globalPDFCache.get(cacheKey)?.base64string;
                if (!cacheVal || cacheVal == "" || cacheVal == undefined) {
                    window.globalPDFCache.delete(cacheKey);
                    window.globalPreLoadMap.delete(cacheKey);
                    return false;
                }
                let pgDms = window.globalPDFCache.get(cacheKey)?.pageDimensions;
                if (!pgDms || pgDms.length == 0) {
                    pgDms = fl.pagedimensions;
                }
                window.globalPDFPageDimensionsDiff.set(fileIdStr, pgDms);
                window.globalBase64StringDiff.set(fileIdStr, `data:application/pdf;base64,${cacheVal}`);

                setOnDemandRefresh(prevNum => prevNum + 1);
                window.globalBase64StringIdDiff.set(fileIdStr, cacheKey);
                return true;
            } else {
                window.globalPreLoadMap.set(cacheKey, cacheKey);
                let mdfd = new Date(fl.modified).getTime();
                const params = new URLSearchParams({
                    cachemodstring: mdfd.toString()
                });
                let flSz = Math.round(parseInt(fl.size) / (1024 * 1024));
                setLoadingMessage(`INITIAL LOAD ${flSz}MB ${flSz > 5 ? "PLEASE WAIT..." : ""}`);

                const base64 = await downloadFile(`/content/${companyId}/${flPath}` + `?${params.toString()}`);

                if (!base64 || base64 == "" || base64 == undefined) {
                    window.globalPDFCache.delete(cacheKey);
                    window.globalPreLoadMap.delete(cacheKey);
                    return false;
                }
                window.globalPDFPageDimensionsDiff.set(fileIdStr, fl.pagedimensions);
                window.globalBase64StringDiff.set(fileIdStr, `data:application/pdf;base64,${base64}`);
                window.globalBase64StringIdDiff.set(fileIdStr, cacheKey);
                window.globalPDFCache.set(cacheKey, {
                    base64string: `${base64}`,
                    fileid: fl.fileid,
                    name: fl.name,
                    modified: fl.modified,
                    pageContent: fl.pagecontent,
                    pageDimensions: fl.pagedimensions
                });
                setIsBlobPulling(false);
                setIsLoa(false);
                setProgress(100);
                setLoadMark(`${file ? "fileY" : "fileN"}_${file?.fileid}_${startPage}_${window.globalBase64StringDiff.get(fl.fileid)?.length}`);
                setOnDemandRefresh(prevNum => prevNum + 1);

                return true;
            }
        };

        if (file?.fileid != null && file?.fileid != "" && file?.fileid != undefined) {
            cacheBase64Wndw(file, fileIdStr).then((opResult: boolean) => {
                if (opResult == true) {
                    setIsLoa(false);
                }
                callBackIsLoading(false);
                window.globalWigglePixel = 0;
            });
        } else {
            callBackIsLoading(false);
            window.globalWigglePixel = 0;
        }
    }, [file, resizeCount]);

    const saveEntriesToGlobal = (currentEntries: number[]) => {
        if (window.globalCurrentVisibleEntriesDiff == undefined) {
            window.globalCurrentVisibleEntriesDiff = new Map<string, number[]>();
        }
        window.globalCurrentVisibleEntriesDiff.set(`${file?.fileid}`, currentEntries);
    };
    const wigglePixel = async () => {
        if (window.globalWigglePixel == 1) {
            return;
        }
        window.globalWigglePixel = 1;
        while (window.globalWigglePixel == 1) {
            await document.getElementById(`pdfmainid9${file?.fileid}`)?.scrollBy(1, 0);
            await new Promise(r => setTimeout(r, 100));

            await document.getElementById(`pdfmainid9${file?.fileid}`)?.scrollBy(-1, 0);
            await new Promise(r => setTimeout(r, 100));
        }
        window.globalWigglePixel = 0;
    };
    const initCache = () => {
        if (window.globalPDFCache === undefined) {
            window.globalPDFCache = new Map<
                string,
                {
                    base64string: string;
                    fileid: string;
                    name: string;
                    modified: string;
                    pageContent: Array<{ page_num: string; text: string }>;
                    pageDimensions: Array<{ page_number: string; width: number; height: number }>;
                }
            >();
        }
        if (window.globalPreLoadMap === undefined) window.globalPreLoadMap = new Map<string, string>();
    };
    const initDiffCache = () => {
        if (window.globalBase64StringDiff === undefined) {
            window.globalBase64StringDiff = new Map<string, string>();
        }
        if (window.globalBase64StringIdDiff === undefined) {
            window.globalBase64StringIdDiff = new Map<string, string>();
        }
        if (window.globalPDFPageDimensionsDiff === undefined) {
            window.globalPDFPageDimensionsDiff = new Map<string, { page_number: string; width: number; height: number }[] | []>();
        }
        if (window.globalItemCountsDiff === undefined) {
            window.globalItemCountsDiff = new Map<string, Map<number, { count: number; flag: boolean }>>();
            if (file?.fileid) window.globalItemCountsDiff.set(`${file?.fileid}`, new Map<number, { count: number; flag: boolean }>());
        }
        if (window.globalNumPagesDiff === undefined) {
            window.globalNumPagesDiff = new Map<string, number>();
        }
        if (window.globalCurrentVisibleEntriesDiff === undefined) {
            window.globalCurrentVisibleEntriesDiff = new Map<string, number[]>();
        }
        if (window.globalAllNessesaryPDFPagesLoadedDiff === undefined) {
            window.globalAllNessesaryPDFPagesLoadedDiff = new Map<string, boolean>();
            if (file?.fileid) window.globalAllNessesaryPDFPagesLoadedDiff.set(`${file?.fileid}`, false);
        }
        if (window.globalpdfObservableRange === undefined) {
            window.globalpdfObservableRange = 0;
        }
    };
    const preparePdfPage = ({ handleTimeout, nums, setContentList, pdf, startPage, fileid, fileIndexChunks, filePageDimsState }: PDFPageDetails) => {
        const pages = [];
        for (let i = 0; i < nums; i++) {
            pages.push(
                <Page
                    key={`${"KY"}_${i + 1}`}
                    pageNumber={i + 1}
                    style={{ overflowY: true, behavior: "smooth", border: "1px solid black" }}
                    width={getDesiredWidth(srcLoc)}
                    loadingText={<div className={styles.loadingText}>LDNG</div>}
                    noData={<div className={styles.loadingText}>LDNG</div>}
                    renderAnnotationLayer={false}
                    onPageChange={(page: any) => {
                        console.log("Page Change!, ", page);
                    }}
                    onGetTextSuccess={(items: any, styles: any) => {
                        if (!window.globalItemCountsDiff.has(fileid)) {
                            window.globalItemCountsDiff.set(fileid, new Map());
                        }
                        window.globalItemCountsDiff.get(fileid)?.set(i, { count: items.items.length, flag: false });
                    }}
                    customTextRenderer={({ pageIndex, pageNumber, itemIndex, str }: gpdftextitem) => {
                        if (itemIndex == (window.globalItemCountsDiff.get(fileid)?.get(i)?.count || 0) - 1) {
                            window.globalItemCountsDiff.get(fileid)?.set(i, {
                                count: window.globalItemCountsDiff.get(fileid)?.get(i)?.count || 0,
                                flag: true
                            });
                            if (areAllFlagsTrue(fileIndexChunks, window.globalItemCountsDiff.get(fileid))) {
                                if (window.globalAllNessesaryPDFPagesLoadedDiff.get(fileid) == false) {
                                    window.globalAllNessesaryPDFPagesLoadedDiff.set(fileid, true);
                                    console.log("¦loaded_all¦", fileid);
                                    try {
                                        clearTimeout(timeoutId);
                                    } catch {}
                                    timeoutId = setTimeout(() => {
                                        handleTimeout(fileid);
                                    }, 100) as unknown as number;
                                }
                            }
                        }
                        return `<span id='CSP_${fileid}_${pageIndex}_${itemIndex}' class='pdf_itm_spans pdf_itm_${fileid}_${pageIndex} spans_diff'>${str}</span>`;
                    }}
                    onRenderSuccess={(pdfPage: PDFPageProxy) => {
                        if (pdfPage.pageNumber == startPage) {
                            console.log("diff_render_success", startPage);
                            //var pageElement = document.querySelector(`[data-page-number="${startPage}"`);
                        }
                        if (i == nums - 1) {
                            console.log("rndr_scss");
                        }
                    }}
                />
            );
        }
        return pages;
    };
    const handleLoadSuccess = async (pdfObject: PDFDocumentProxy) => {
        console.log("load_success", pdfObject.numPages);
        const numPages = pdfObject.numPages;
        window.globalNumPagesDiff.set(`${file?.fileid}`, numPages);
        window.globalAllNessesaryPDFPagesLoadedDiff.set(`${file?.fileid}`, false);
        const pgs = preparePdfPage({
            handleTimeout,
            nums: numPages,
            setContentList,
            pdf: pdfObject,
            startPage: startPage,
            fileid: `${file?.fileid}`,
            fileIndexChunks: fileIndexChunks,
            filePageDimsState: filePageDimsState
        });

        setPgs(pgs);
        setFilePageDimsState(window.globalPDFPageDimensionsDiff.get(`${file?.fileid}`) || []);
        setOnDemandRefresh(prevNum => prevNum + 1);
        setTimeout(() => {
            setOnDemandRefresh(prevNum => prevNum + 1);
        }, 100);
        setPages(numPages);
    };
    const downloadFile = async (url: string): Promise<string | null> => {
        try {
            console.log("dwnld");
            setProgress(undefined);
            const response = await fetch(url);
            if (response && response.body) {
                const reader = response.body.getReader();
                const contentLengthHeader = response.headers.get("Content-Length");
                const contentLength = contentLengthHeader ? parseInt(contentLengthHeader, 10) : 0;

                let receivedLength = 0;
                const chunks: Uint8Array[] = [];

                while (true) {
                    const { done, value } = await reader.read();
                    if (done) break;
                    chunks.push(value);
                    receivedLength += value.length;
                    setProgress(receivedLength / contentLength);
                    setLoadingMessage(prevMessage => prevMessage?.replace("INITIAL LOAD", "DOWNLOADING ON DEVICE"));
                }
                const allChunks = new Uint8Array(receivedLength);
                let position = 0;
                for (const chunk of chunks) {
                    allChunks.set(chunk, position);
                    position += chunk.length;
                }
                const chunkSize = 0x8000;
                let base64 = "";
                for (let i = 0; i < allChunks.length; i += chunkSize) {
                    const chunk = allChunks.subarray(i, i + chunkSize);
                    base64 += String.fromCharCode.apply(null, chunk as unknown as number[]);
                }
                console.log("btoa");
                return btoa(base64);
            }
        } catch (err) {
            console.error("download_fail:", err);
        }
        return null;
    };
    const addToObserver = useCallback((ref: any, index: number) => {
        if (ref) {
            pageRefs.current[index] = ref;
            ref.dataset.pageNumber = (index + 1).toString();

            if (observerRef.current) {
                observerRef.current.observe(ref);
            }
        }
    }, []);
    function getCurrentPage(pageDimensions: gpagedimensions[], scrollTop: number, clientHeight: number): number {
        let cumulativeHeight = 0;
        const middleOfViewport = scrollTop + clientHeight / 2;

        for (let i = 0; i < pageDimensions.length; i++) {
            cumulativeHeight += getDesiredHeight(pageDimensions[i], srcLoc);
            if (cumulativeHeight > middleOfViewport) {
                return i + 1; // Index to Page Number
            }
        }

        // Last Page
        return pageDimensions.length;
    }
    const handleScrollCallAdv = (event: any) => {
        let { scrollTop, clientHeight, scrollHeight } = event.currentTarget;

        handleScroll(event, getCurrentPage(filePageDimsState, scrollTop, clientHeight));
        debounce(() => {
            setScrollTr(new Date().toISOString());
        }, 50)();
    };
    return (
        <div id={`pdf-viewer${file?.fileid}`} className={stylesXplrr.pdfViewerDiff}>
            {fileList.length == 0 ? (
                <div className={stylesXplrr.noDocs}>
                    <div className={stylesXplrr.iconNoContent}>
                        <ImFilesEmpty size={24} />
                    </div>
                    <div>Space Empty. Upload documents from the Management Tab</div>
                </div>
            ) : (
                <div className={styles.loadingIndicatorContainer}>
                    {progress == 1 && (
                        <div key={rescaleWidth} className={`${stylesXplrr.loadingIndicatorDiff} `}>
                            {/*isBlobPulling ? "BLOB PULL START" : "BLOB PULL END"*/}
                            <ProgressIndicator percentComplete={progress} barHeight={20} progressHidden={progress == 1 ? true : false} />
                        </div>
                    )}

                    <div className={`${isLoa ? stylesXplrr.loadingIndicatorDiff : stylesXplrr.loadingIndicatorDiffHidden}`}>
                        {loadingMessage}
                        {loadingMessage == "ERROR LOADING PDF" ? null : (
                            <div className={stylesXplrr.typingDots}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {!window.globalBase64StringDiff ? null : file?.fileid &&
              window.globalBase64StringDiff &&
              window.globalBase64StringDiff.has(`${file?.fileid}`) &&
              window.globalBase64StringDiff.get(`${file?.fileid}`) == "data:application/pdf;base64," ? null : file?.fileid &&
              window.globalBase64StringDiff.has(`${file?.fileid}`) ? (
                <div id={`WPDF_${file?.fileid}`} className={stylesXplrr.pdfViewerSub} ref={containerRef}>
                    <Document
                        key={`DC_${rescaleWidth}${file?.fileid}`}
                        className={stylesXplrr.pdfViewerDiffXPL}
                        file={window.globalBase64StringDiff.get(`${file?.fileid}`)}
                        onLoadSuccess={handleLoadSuccess}
                        noData={<div className={stylesXplrr.loadingText}>Loading...</div>}
                        loading={<div className={stylesXplrr.loadingText}>Loading...</div>}
                        onLoadError={(error: any) => {
                            console.log("PDF ERROR", error);
                            setLoadingMessage("ERROR LOADING PDF");
                        }}
                        error={
                            <div className={stylesXplrr.errorPDFLoadSystemMsg}>
                                <div className={stylesXplrr.iconNoContent}>
                                    <MdErrorOutline size={26} />
                                </div>
                                <div>Error Loading PDF</div>
                            </div>
                        }
                    >
                        <div className={stylesXplrr.pdfDocContainer}>
                            <div className={stylesXplrr.pdfMain} id={`pdfmainid9${file?.fileid}`} onScroll={handleScrollCallAdv}>
                                {filePageDimsState?.map((dim: gpagedimensions, index: number) =>
                                    (index > startPage - (window.globalpdfObservableRange || 25) &&
                                        index < startPage + (window.globalpdfObservableRange || 25)) ||
                                    (window.globalUserScrollLazyLoadEnabled == true &&
                                        window.globalCurrentVisibleEntriesDiff.get(`${file?.fileid}`)?.includes(index)) ? (
                                        <div
                                            key={`GLOP_${file?.fileid}_${index}`}
                                            data-load-mark={loadMark}
                                            data-p-type={"page"}
                                            data-diff-page={`DDP_${file?.fileid}_${index}`}
                                            data-doc={`ddoc_${file?.fileid}`}
                                            ref={(ref: any) => addToObserver(ref, index)}
                                            style={{
                                                width: getDesiredWidth(srcLoc),
                                                height: getDesiredHeight(dim, srcLoc),
                                                backgroundColor: "#f0f0f0",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                        >
                                            {pgs[index]}
                                        </div>
                                    ) : (
                                        <div
                                            key={`GLOP_${file?.fileid}_${index}`}
                                            data-load-mark={loadMark}
                                            data-p-type={"mock"}
                                            data-diff-page={`DDP_${file?.fileid}_${index}`}
                                            ref={(ref: any) => addToObserver(ref, index)}
                                            style={{
                                                width: getDesiredWidth(srcLoc),
                                                height: getDesiredHeight(dim, srcLoc),
                                                backgroundColor: "#f0f0f0",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                        ></div>
                                    )
                                )}
                            </div>
                        </div>
                    </Document>
                </div>
            ) : null}
        </div>
    );
};
export default XplrrBOXDIFFPDF;
