import React, { FormEvent, useRef, useEffect, useState, MouseEvent } from "react";
import { TextField } from "@fluentui/react/lib/TextField";
import {
    IconButton,
    IDropdownOption,
    IDetailsHeaderProps,
    DetailsHeader,
    TooltipHost,
    DirectionalHint,
    IObjectWithKey,
    Modal,
    ContextualMenu,
    IContextualMenuItem,
    Dropdown,
    IDropdown,
    Sticky,
    StickyPositionType,
    ShimmeredDetailsList,
    ThemeProvider,
    IDetailsRowStyles
} from "@fluentui/react";
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IColumn, IDetailsRowProps, DetailsRow } from "@fluentui/react/lib/DetailsList";
import { MarqueeSelection } from "@fluentui/react/lib/MarqueeSelection";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { Fabric } from "@fluentui/react/lib/Fabric";

import Papa from "papaparse"; // Only needed if using npm module
import UploadCards from "../../components/UploadCards/UploadCards";
import JsonGrid from "../../components/JsonGrid/JsonGrid";
import AISearchJsonGrid from "../../components/AISearchJsonGrid/AISearchJsonGrid";
import CollapsibleSections from "../../components/CollapsibleSections/CollapsibleSections";
import { AddURLs } from "../../components/AddURLs";
import { TogglePanelButton } from "../../components/TogglePanelButton";
import ConversationRecognizer from "../../components/SpeechRecognizer/ConversationRecognizer";

import { BiError } from "react-icons/bi";
import { RiCheckboxMultipleBlankLine } from "react-icons/ri";
import { FiYoutube } from "react-icons/fi";
import { CgWebsite } from "react-icons/cg";
import { FaRegCheckCircle } from "react-icons/fa";
import { MdErrorOutline } from "react-icons/md";
import { TbTableDown } from "react-icons/tb";
import { FiSearch, FiEdit, FiBarChart2 } from "react-icons/fi";

import { reindexApi, dataExtractionApi, downloadPDF, deleteFile, publishTranscriptDraft, getAiDiff, ai_comparison } from "../../api";
import { greindexparams, gtranscriptpublish, gdiff } from "../../interfaces";
import { detailsListContentTypeOptions } from "../../lsts";
import { dropdownDetailsListStyles, detailsListTheme } from "../../styles_glob";
import { onSTT } from "../../util_glob";
import RollingStatus from "../../components/RollingStatus/RollingStatus";
import styles from "./detailedList.module.css";
import ButtonHold from "../../components/ButtonHold/ButtonHold";

import { toast } from "react-toastify";
import Select from "react-select";

import {
    classNames,
    controlStyles,
    textFieldStyles,
    IDocument,
    IDetailsListDocumentsExampleState,
    IDetailsListDocumentsExampleProps,
    pdfI,
    formatDateTime,
    parseDocuments,
    _copyAndSort,
    _copyAndSortAdv,
    _copyAndSortAdvTotal,
    _copyAndSortInvStr,
    tooltipGrandTotalMismatchContent,
    tooltipItemMismatchContent
} from "./detailedMdls";

export class DetailsListDocumentsExample extends React.Component<IDetailsListDocumentsExampleProps, IDetailsListDocumentsExampleState> {
    public _selection: Selection;
    private _allItems: IDocument[];

    constructor(props: IDetailsListDocumentsExampleProps) {
        super(props);

        this._allItems = parseDocuments(this.props.fileList || []);
        this._onChangeCompactMode = this._onChangeCompactMode.bind(this);

        const columns: IColumn[] = [
            ...(this.props.selectedGroup.invoicesgroup != 1
                ? [
                      {
                          key: "kgnf_filetype",
                          name: "File Type",
                          className: classNames.fileIconCell,
                          iconClassName: classNames.fileIconHeaderIcon,
                          ariaLabel: "Press to sort on File type",
                          iconName: "Page",
                          isIconOnly: true,
                          fieldName: "name",
                          minWidth: 16,
                          maxWidth: 16,
                          isPadded: false,
                          onColumnClick: this._onColumnClick,
                          onRender: (item: IDocument) => {
                              {
                                  return item.sourcetype == "youtube" ? <FiYoutube /> : item.sourcetype == "web" ? <CgWebsite /> : pdfI;
                              }
                          }
                      }
                  ]
                : []),
            {
                key: "kgnf_key",
                name: "Id",
                fieldName: "fileid",
                minWidth: 25,
                maxWidth: 25,
                isResizable: true,
                data: "number",
                onRender: (item: IDocument) => {
                    return <span style={{ font: "normal normal 11px/20px Urbanist,Roboto", color: "#2F4858" }}>{item.key}</span>;
                },
                isPadded: false
            },
            {
                key: "kgnf_name",
                name: "Name",
                fieldName: "displayName",
                minWidth: 200,
                isRowHeader: true,
                isResizable: true,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: "Sorted A to Z",
                sortDescendingAriaLabel: "Sorted Z to A",
                onColumnClick: this._onColumnClick,
                data: "string",
                isPadded: true,
                onRender: (item: IDocument) => {
                    return (
                        <div
                            title={item.name}
                            style={{ width: "min-content", cursor: "pointer" }}
                            onClick={() => {
                                this.handleDocumentDisplay(item);
                            }}
                        >
                            {item.name}
                        </div>
                    );
                }
            },
            {
                key: "kgnf_path",
                name: "",
                minWidth: 20,
                maxWidth: 20,
                isResizable: false,
                isPadded: false,
                onRender: (item: IDocument) => (
                    <IconButton
                        iconProps={{ iconName: "MoreVertical" }}
                        styles={{ root: { height: "100%" } }}
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                            e.stopPropagation();
                            const domEvent = e.nativeEvent;

                            this.onItemContextMenu(item, 0, domEvent);
                        }}
                    />
                )
            },
            {
                key: "kgnf_mod",
                name: "Modified",
                fieldName: "dateModifiedValue",
                minWidth: 110,
                maxWidth: 200,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: "number",
                onRender: (item: IDocument) => {
                    return <span title={formatDateTime(item.dateModified)}>{formatDateTime(item.dateModified)}</span>;
                },
                isPadded: true
            },
            {
                key: "kgnf_size",
                name: "Size",
                fieldName: "fileSize",
                minWidth: 40,
                maxWidth: 40,
                isResizable: true,
                isCollapsible: true,
                data: "number",
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span title={item.fileSize}>{item.fileSize}</span>;
                }
            },
            {
                key: "kgnf_type",
                name: "Type",
                fieldName: "doc_category",
                minWidth: 70,
                maxWidth: 190,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: "string",
                onRender: (item: IDocument) => {
                    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
                    const dropdownRef = useRef<IDropdown | null>(null);
                    useEffect(() => {
                        if (isDropdownVisible && dropdownRef.current) {
                            dropdownRef.current.focus(); // Focus Dropdown so onBLur works
                        }
                    }, [isDropdownVisible]);

                    let options = [{ key: item.doc_category, text: item.doc_category || "Pending" }];
                    for (let i = 0; i < detailsListContentTypeOptions.length; i++) {
                        if (item.doc_category != detailsListContentTypeOptions[i].key) {
                            options.push(detailsListContentTypeOptions[i]);
                        }
                    }

                    return this.props.selectedGroup.permissionlevel >= 1 && item.doc_category && item?.doc_category !== "Pending" && isDropdownVisible ? (
                        <div>
                            <div style={{ padding: "0px !important", marginTop: "0px", marginBottom: "0px" }}>
                                <Dropdown
                                    componentRef={dropdownRef}
                                    selectedKey={item.doc_category}
                                    options={options}
                                    onChange={(event, option) => this.qChangeType(option?.key || "0")}
                                    styles={dropdownDetailsListStyles}
                                    onBlur={() => {
                                        setIsDropdownVisible(false);
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <div onClick={() => setIsDropdownVisible(true)} className={styles.drpdwnNotActive} title={item.doc_category}>
                            {item.doc_category ?? "Pending"}
                        </div>
                    );
                },
                isPadded: true
            },
            {
                key: "kgnf_status",
                name: "Status",
                fieldName: "docstatus",
                minWidth: 100,
                maxWidth: 190,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: "string",
                onRender: (item: IDocument) => {
                    return (
                        <div className={`${this.isItemReady(item) == true ? styles.docStatusReady : styles.docStatusNotReady}`}>
                            <RollingStatus newText={`${item?.isDeleting == true ? "Deleting" : this.isItemReady(item) == true ? "Ready" : item?.docstatus}`} />
                        </div>
                    );
                },
                isPadded: true
            }
        ];
        if (this.props.selectedGroup.invoicesgroup == 1) {
            columns.splice(4, 0, {
                key: "kgnf_customer",
                name: "Customer",
                fieldName: "invoicedataobj",
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: "string",
                isPadded: true,
                onRender: (item: IDocument) => <div title={item?.invoicedataobj?.["customer_name"]}>{item?.invoicedataobj?.["customer_name"]}</div>
            });
            columns.splice(4, 0, {
                key: "kgnf_contractor",
                name: "Contractor",
                fieldName: "invoicedataobj",
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: "string",
                isPadded: true,
                onRender: (item: IDocument) => <div title={item?.invoicedataobj?.["contractor_name"]}>{item?.invoicedataobj?.["contractor_name"]}</div>
            });
            columns.splice(4, 0, {
                key: "kgnf_vat",
                name: "Vat",
                fieldName: "invoicedataobj",
                minWidth: 20,
                maxWidth: 40,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: "string",
                isPadded: true,
                onRender: (item: IDocument) => <div title={item?.invoicedataobj?.["vat"]}>{item?.invoicedataobj?.["vat"]}</div>
            });
            columns.splice(4, 0, {
                key: "kgnf_total",
                name: "Total",
                fieldName: "invoicedataobj",
                minWidth: 20,
                maxWidth: 40,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: "string",
                isPadded: true,
                onRender: (item: IDocument) => <div title={item?.invoicedataobj?.["invoice_total"]}>{item?.invoicedataobj?.["invoice_total"]}</div>
            });
            columns.splice(4, 0, {
                key: "kgnf_valid",
                name: "Valid",
                fieldName: "invoicedataobj",
                minWidth: 40,
                maxWidth: 40,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: "string",
                isPadded: true,
                onRender: (item: IDocument) =>
                    this.props.selectedGroup.permissionlevel >= 1 &&
                    (item.invoicedata != undefined && item.invoicedata != "" ? (
                        item.docstatus != "Ready" ? (
                            <div style={{ margin: "0px 0px 0px -10px", padding: "0", maxWidth: "50px" }}>
                                <Spinner size={SpinnerSize.small} ariaLive="assertive" />
                            </div>
                        ) : (
                            <div
                                style={{ display: "flex", margin: "2px 0px 0px 10px", padding: "0", maxWidth: "50px", cursor: "pointer" }}
                                onClick={() => {
                                    this.handleDocumentDisplay(item);
                                }}
                            >
                                {item?.invoicedataobj?.["grand_total_mismatch"] == false &&
                                item?.invoicedataobj?.["insufficient_totals_data"] == false &&
                                item?.invoicedataobj?.["items_with_insufficient_data"] == 0 &&
                                item?.invoicedataobj?.["items_with_mismatches"] == 0 ? (
                                    <div className={styles.validColContainer}>
                                        <FaRegCheckCircle size={15} color="#4bbc8e" />
                                    </div>
                                ) : item?.invoicedataobj?.["grand_total_mismatch"] == true ? (
                                    <TooltipHost content={tooltipGrandTotalMismatchContent(item)} directionalHint={DirectionalHint.topCenter}>
                                        <div className={styles.invalidColContainer}>
                                            <MdErrorOutline size={15} color="#cb4788" />
                                            <div className={styles.validErrLable} title={`Grand Total Mismatch`}>
                                                Total
                                            </div>
                                        </div>
                                    </TooltipHost>
                                ) : item?.invoicedataobj?.["insufficient_totals_data"] == true ? (
                                    <TooltipHost content="Insufficient Grand Total Data" directionalHint={DirectionalHint.topCenter}>
                                        <div className={styles.invalidColContainer}>
                                            <MdErrorOutline size={15} color="#cb4788" />
                                            <div className={styles.validErrLable} title={`Insufficient Grand Total Data`}>
                                                Total
                                            </div>
                                        </div>
                                    </TooltipHost>
                                ) : item?.invoicedataobj?.["items_with_insufficient_data"]?.length > 0 ? (
                                    <div
                                        className={styles.invalidColContainer}
                                        title={`Insufficient Items Data ${item.invoicedataobj["items_with_insufficient_data"]}`}
                                    >
                                        <TooltipHost content={tooltipItemMismatchContent(item)} directionalHint={DirectionalHint.topCenter}>
                                            <div className={styles.invalidColContainer}>
                                                <div style={{ marginTop: "-1px" }}>
                                                    <BiError size={17} color="#ffca80" />
                                                </div>
                                                <div className={styles.validErrLable}>Items</div>
                                            </div>
                                        </TooltipHost>
                                    </div>
                                ) : item?.invoicedataobj?.["items_with_mismatches"]?.length > 0 ? (
                                    <TooltipHost content={tooltipItemMismatchContent(item)} directionalHint={DirectionalHint.topCenter}>
                                        <div className={styles.invalidColContainer}>
                                            <div style={{ marginTop: "-1px" }}>
                                                <BiError size={17} color="#ffca80" />
                                            </div>

                                            <div className={styles.validErrLable}>Items</div>
                                        </div>
                                    </TooltipHost>
                                ) : null}
                                {item?.invoicedataobj?.["multiple_invoice_suspicion"] == true && (
                                    <TooltipHost content="Multiple Invoices Suspected [only first extracted]" directionalHint={DirectionalHint.topCenter}>
                                        <div className={styles.validColContainer}>
                                            <MdErrorOutline size={15} color="#cb4788" />
                                        </div>
                                    </TooltipHost>
                                )}
                                {item?.invoicedataobj?.["duplicate_invoice_suspicion"] == true && (
                                    <TooltipHost content="Duplicate Invoice Suspected [only first extracted]" directionalHint={DirectionalHint.topCenter}>
                                        <div className={styles.otherValidationIcons}>
                                            <RiCheckboxMultipleBlankLine size={17} color="#cb4788" />
                                        </div>
                                    </TooltipHost>
                                )}
                            </div>
                        )
                    ) : this.props.selectedGroup.permissionlevel >= 2 ? (
                        item.docstatus == "Ready" && (item.doc_category == "invoice" || item.doc_category == "form") ? (
                            <IconButton
                                iconProps={{
                                    iconName: "Generate",
                                    styles: { root: { height: "10px", padding: "0", margin: "-5px 0px 0px 0px", color: "rgb(241, 100, 30)" } }
                                }}
                                title="Extract Data"
                                styles={{ icon: { fontSize: "15px" }, root: { height: "25px", padding: "0", margin: "-5px 0px 0px 0px" } }}
                                ariaLabel="Extract Data"
                                disabled={this.state.isWorkingOnDelete}
                                onClick={async () => {
                                    await this.handleInvoiceExtractionButtonClick([item]);
                                }}
                            />
                        ) : (
                            <div style={{ margin: "0px 0px 0px -10px", padding: "0", maxWidth: "50px" }}></div>
                        )
                    ) : null)
            });
            columns.splice(4, 0, {
                key: "kgnf_confirm",
                name: "Confirmed",
                fieldName: "approved",
                minWidth: 80,
                maxWidth: 80,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: "string",
                isPadded: false,

                onRender: (item: IDocument) => {
                    return (
                        <div className={styles.confirmInvoiceButton}>
                            <ButtonHold
                                confirmed={item.approved == 1}
                                confirm={() => {
                                    this.qApprove();
                                }}
                            />
                        </div>
                    );
                }
            });
        }
        if (this.props.selectedGroup.allowaudiodocentry == 1) {
            columns.splice(4, 0, {
                key: "kgnf_edit",
                name: "Edit",
                fieldName: "editcntnt",
                minWidth: 5,
                maxWidth: 5,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                isPadded: true,
                onRender: (item: IDocument) => {
                    const hasTranscriptions = (item?.transcription?.length || 0) > 0;
                    return (item?.transcription?.length || 0) > 0 ? (
                        <div
                            onClick={() => {
                                this.setState({ isMicModalVisible: true });
                            }}
                            style={{ marginTop: "2px", marginBottom: "-10px", cursor: "pointer" }}
                        >
                            <FiEdit size={15} />
                        </div>
                    ) : (
                        <div></div>
                    );
                }
            });
        }
        if (this.props.selectedGroup.assistanttype == "cmp") {
            columns.splice(4, 0, {
                key: "kgnf_diff",
                name: "Diff",
                fieldName: "highlightItemId",
                minWidth: 5,
                maxWidth: 5,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                isPadded: true,
                onRender: (item: IDocument) => {
                    return (item?.diffids?.length || 0) > 0 ? (
                        item?.diffids.map((diffid: number, index: number) => (
                            <div
                                onClick={(e: React.MouseEvent<HTMLElement>) => {
                                    console.log("e", e.currentTarget.getAttribute("data-item-id"));
                                    let item_second = this._allItems.filter(item => item.key == e.currentTarget.getAttribute("data-item-id"))[0];
                                    this.handleDiffButtonClick([item, item_second], true);
                                }}
                                onMouseEnter={() => {
                                    this.setState({
                                        highlightItemId: diffid.toString()
                                    });
                                }}
                                onMouseLeave={() => {
                                    this.setState({
                                        highlightItemId: ""
                                    });
                                }}
                                style={{ marginTop: "2px", marginBottom: "-10px", cursor: "pointer" }}
                                data-item-id={diffid}
                            >
                                <TooltipHost content="Comparison between these 2 documents is available" directionalHint={DirectionalHint.leftCenter}>
                                    {this.state.highlightItemId == item.key.toString() ? (
                                        <div className={styles.diffItemHighlight}>{diffid}</div>
                                    ) : (
                                        <div className={styles.diffItem}>{diffid}</div>
                                    )}
                                </TooltipHost>
                            </div>
                        ))
                    ) : (
                        <div></div>
                    );
                }
            });
        }
        this.state = {
            items: this._allItems,
            columns: columns,
            selectionDetails: this._getSelectionDetails(),
            isModalSelection: true,
            isCompactMode: false,
            announcedMessage: undefined,
            itemSelectionString: "",
            pathSelectionString: [],
            isModalOpen: false,
            isSecurityModalOpen: false,
            isContextMenuVisible: false,
            isSpaceFilterVisible: false,
            contextMenuTarget: null,
            isWorkingOnDelete: false,
            spacesFilter: "",
            activeFile: {},
            hasDeleted: false,
            invoiceModalItem: undefined,
            summaryModalItem: undefined,
            targetItem: undefined,
            callOutVisibilityBool: false,
            aiSearchData: undefined,
            isMicModalVisible: false,
            highlightItemId: undefined
        };
        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({
                    selectionDetails: this._getSelectionDetails(),
                    itemSelectionString: this._selection
                        .getSelection()
                        .map((item: any) => item?.name)
                        .join(";"),
                    pathSelectionString: this._selection.getSelection().map((item: any) => item?.full_path),
                    activeFile: this.getActiveFile(this._selection.getSelection())
                });
                this.props.onSelectionChange(
                    this._selection.getSelection().map((item: any) => {
                        return { name: item?.name, groupIds: item?.groupIds, groupNames: item?.groupNames };
                    })
                );
            }
        });
    }

    componentDidMount() {
        this.state.items.forEach((item: any) => {
            if (this.props.selectionPass.split(";").includes(item.name)) {
                this._selection.setKeySelected(item.key, true, false);
            }
        });
    }

    onRenderDetailsHeader = (props: IDetailsHeaderProps, defaultRender?: (props: IDetailsHeaderProps) => JSX.Element) => {
        const customStyles = {
            root: {
                padding: "0",
                font: "normal normal 13px/40px Urbanist,Roboto !important"
            }
        };
        return (
            <Sticky stickyPosition={StickyPositionType.Header}>
                <DetailsHeader {...props} styles={customStyles} />
            </Sticky>
        );
    };
    getActiveFile = (selection: any) => {
        if (selection?.length > 0) {
            console.log("getActiveFile", selection);
            const selFileKey = selection.map((item: any) => item?.key)[0] || "";
            const selectedFile = (this.props.fileList || []).filter(item => item?.fileid == selFileKey);
            return selectedFile[0] || {};
        }
    };
    getHeader = () => {
        return {
            ...(this.props.loggedInUser?.token ? { Authorization: `Bearer ${this.props.loggedInUser.token}` } : {}),
            "X-MS-CLIENT-PRINCIPAL-NAME": this.props.loggedInUser?.mail ? this.props.loggedInUser.mail : "",
            "X-MS-CLIENT-PRINCIPAL-ID": this.props.loggedInUser?.userId ? this.props.loggedInUser.userId : "",
            "Content-Type": "application/json"
        };
    };
    onItemContextMenu = (item: any, index: number | undefined, ev: Event | undefined): boolean | void => {
        (ev as PointerEvent)?.preventDefault();
        import.meta.env.DEV === true && console.log("¦ITM¦", item);
        if (this.props.selectedGroup.permissionlevel >= 2) {
            this.setState({
                isContextMenuVisible: true,
                targetItem: item,
                contextMenuTarget: { x: ev ? (ev as PointerEvent).clientX : 0, y: ev ? (ev as PointerEvent).clientY : 0 }
            });
        }
        return true;
    };
    onContextMenuItemClick = (ev?: any, item?: IContextualMenuItem | undefined) => {
        console.log("ctx", item);
        if (item?.key === "Share") {
            this.showSecurityModal();
        } else if (item?.key === "Download") {
            this.handleDownloadButtonClick(this._selection.getSelection());
        } else if (item?.key === "Reindex") {
            this.handleReindexButtonClick(this._selection.getSelection());
        } else if (item?.key === "Invoice") {
            this.handleInvoiceExtractionButtonClick(this._selection.getSelection());
        } else if (item?.key === "Delete") {
            this.gHandleBatchDelete(this._selection.getSelection());
        } else if (item?.key === "IndexView") {
            this.handleIndexViewButtonClick(this._selection.getSelection());
        } else if (item?.key === "Diff") {
            this.handleDiffButtonClick(this._selection.getSelection(), false);
        }

        this.setState({ isContextMenuVisible: false });
    };
    visualMarkingCallback = (filesToDel: IDocument[]) => {
        filesToDel.forEach(file => {});
    };
    qChangeType = async (doc_category: string | number) => {
        console.log("AF", this.state.activeFile);
        let fid = this.state.activeFile.fileid;

        let response = await fetch(`/qChangeFileType`, {
            method: "POST",
            headers: this.getHeader(),
            body: JSON.stringify({ fid: fid, doc_category: doc_category })
        }).then(res => {
            if (!res || res.ok == false) {
                toast.error("Error changing Item Type");
            } else {
                toast.success("Changed Item Type");
                this.props.listRefreshRequest();
            }
        });
    };
    qApprove = async () => {
        let fid = this.state.activeFile.fileid;
        let response = await fetch(`/qApprove`, {
            method: "POST",
            headers: this.getHeader(),
            body: JSON.stringify({ fid: fid })
        }).then(res => {
            if (!res || res.ok == false) {
                toast.error("Error approving Item");
            } else {
                toast.success("Item Approved");
                this.props.listRefreshRequest();
            }
        });
    };
    gHandleBatchDelete = async (selFiles: IObjectWithKey[]) => {
        let fileids = selFiles.map(file => file.key).join(";");
        let filesToDel = this._allItems.filter(item => selFiles.some(selFile => selFile.key === item.key));
        let filesKeep = this._allItems.filter(item => selFiles.every(selFile => selFile.key !== item.key));
        let filenms = filesToDel.map(file => file.name).join(";");

        this._allItems = this._allItems.map(currentItem => {
            if (selFiles.some(selFile => selFile.key === currentItem.key)) {
                return { ...currentItem, isDeleting: true };
            }
            return currentItem;
        });
        this.setState({
            items: this._allItems
        });
        let response = await deleteFile(filenms, fileids, this.getHeader(), this.props.companyId, this.props.listRefreshRequest);

        console.log("SLC¦handleDelButtonClick", response);
        if (response.success !== true) {
            throw Error("Failed deleteFile");
        } else {
            this._allItems = filesKeep;
            this.setState({
                items: this._allItems,
                hasDeleted: true
            });
            this.props.onDelFile();
        }
    };
    onSpaceFilterClick = (option?: IDropdownOption) => {
        let items = this.filterSpace(option?.key as string);
        this.setState({ items: items, spacesFilter: option?.key ? (option.key as string) : "" });
    };
    handleDelButtonClick = async (item: any) => {
        this._allItems = this._allItems.map(currentItem => {
            if (currentItem.key == item.key) {
                // Set isDeleting to true for items that match the condition
                return { ...currentItem, isDeleting: true };
            }
            return currentItem;
        });
        this.setState({
            items: this._allItems
        });
        const response = await deleteFile(item.name, item.key, this.getHeader(), this.props.companyId);
        console.log("SLC¦handleDelButtonClick", response);
        if (response.success !== true) {
            throw Error("Failed deleteFile");
        } else {
            this._allItems = this._allItems.filter(currentItem => currentItem.key !== item.key);
            //console.log("SLC¦Del", item.name, this.state.items, this._allItems);
            this.setState({
                items: this._allItems,
                hasDeleted: true
            });
            this.props.onDelFile();
        }
    };
    handleDownloadButtonClick = async (item: any[]) => {
        for (let i = 0; i < item.length; i++) {
            console.log("SLC¦Download", item[i].name, this.getContentPath(item[i].name));
            const response = downloadPDF(this.getContentPath(item[i].name), item[i].name, this.props.companyId, this.props.loggedInUser);
        }
    };
    handleReindexButtonClick = async (items: any[]) => {
        let itemAppendedStrings = [];
        let modStrings = [];
        let idStrings = [];
        for (let i = 0; i < items.length; i++) {
            itemAppendedStrings.push(this.getContentPath(items[i].name));
            modStrings.push(new Date(this.getItemFromSel(items[i].name).dateModified)?.getTime()?.toString());
            idStrings.push(items[i].key);
        }
        let itemAppendedArr = itemAppendedStrings.join(";");

        let reindexParams: greindexparams = {
            fileString: itemAppendedArr,
            selectedGroup: this.props.selectedGroup,
            userId: this.props.loggedInUser.userId,
            company: this.props.companyId,
            header: this.getHeader(),
            modString: modStrings?.join(";"),
            idString: idStrings?.join(";")
        };
        this._allItems = this._allItems.map(currentItem => {
            if (items.some(selFile => selFile.key === currentItem.key)) {
                return { ...currentItem, docstatus: "Reindexing" };
            }
            return currentItem;
        });
        this.setState({
            items: this._allItems
        });
        console.log("SLC¦ReindexParams", reindexParams);

        const response = await reindexApi(reindexParams, this.props.listRefreshRequest);
        if (response.success !== true) {
            console.log("SLC¦Reindex", response);
            throw Error("Failed reindexFile");
        } else {
            this.props.updateCatsState(itemAppendedArr);
            console.log("SLC¦Reindex", response);
        }
    };
    handleInvoiceExtractionButtonClick = async (items: any[]) => {
        let itemAppendedStrings = [];
        let modStrings = [];
        let idStrings = [];
        for (let i = 0; i < items.length; i++) {
            itemAppendedStrings.push(this.getContentPath(items[i].name));
            modStrings.push(new Date(this.getItemFromSel(items[i].name).dateModified)?.getTime()?.toString());
            idStrings.push(items[i].key);
        }
        let itemAppendedArr = itemAppendedStrings.join(";");
        let reindexParams: greindexparams = {
            fileString: itemAppendedArr,
            selectedGroup: this.props.selectedGroup,
            userId: this.props.loggedInUser.userId,
            company: this.props.companyId,
            header: this.getHeader(),
            modString: modStrings?.join(";"),
            idString: idStrings?.join(";")
        };
        console.log("SLC¦ReindexParams", reindexParams);
        const response = await dataExtractionApi(reindexParams, this.props.listRefreshRequest);
        if (response.success !== true) {
            console.log("SLC¦Reindex", response);
            throw Error("Failed reindexFile");
        } else {
            this.props.updateCatsState(itemAppendedArr);
            console.log("SLC¦Reindex", response);
        }
    };
    handleIndexViewButtonClick = async (items: any[]) => {
        const params = new URLSearchParams({
            fkey: items[0].key,
            companyid: this.props.companyId
        });
        const url = "/srch_by_filename" + `?${params.toString()}`;
        const response = await fetch(url, {
            headers: this.getHeader()
        });
        let indx_results = await response.json();
        console.log("SLC¦handleIndexViewButtonClick", indx_results);
        this.setState({
            aiSearchData: {
                title: items[0].name,
                indx_results: indx_results
            }
        });
    };
    prepDiffCitationURL = (item: any) => {
        let itemString = `${item.name}#page=1_fid=${item.key}`;
        console.log("diff_str", itemString);
        return itemString;
    };
    handleDiffButtonClick = async (items: any[], isCellButton: boolean) => {
        if (isCellButton) {
            if (items.length == 2) {
                let itemids = items.map(item => item.key);
                if (
                    (items[0].diffids.includes(items[0].key) || items[0].diffids.includes(items[1].key)) &&
                    (items[1].diffids.includes(items[0].key) || items[1].diffids.includes(items[1].key))
                ) {
                    let results: gdiff[] = await getAiDiff(items[0].key, items[1].key, this.props.loggedInUser);
                    this.props.onCompareClicked(this.prepDiffCitationURL(items[0]), this.prepDiffCitationURL(items[1]), results);
                } else {
                    await ai_comparison(items[1].key, items[0].key, this.props.loggedInUser, this.props.companyId, this.props.listRefreshRequest);
                }
            } else {
                toast.error("Please select 2 files to compare");
            }
        } else {
            await ai_comparison(items[1].key, items[0].key, this.props.loggedInUser, this.props.companyId, this.props.listRefreshRequest);
        }
    };
    qHandleFileSelectionSpaceChange = async (selectionId: string, selectionText: string) => {
        let selFiles = this._selection.getSelection();
        let fileids = selFiles.map(file => file.key).join(";");
        selFiles.forEach(async (file: any) => {
            const response = await fetch("/qSetFilePermissions", {
                method: "POST",
                headers: this.getHeader(),
                body: JSON.stringify({ fileKey: file.key, groupKey: selectionId })
            });

            this._allItems = this._allItems.map(item => {
                if (item.key === file.key) {
                    //console.log("FILESELSPACE¦Update Space", item.key, file.key, selectionId, selectionText);
                    return { ...item, groupIds: selectionId ? selectionId?.split(",") : [], groupNames: selectionText ? selectionText?.split(",") : [] }; // spread the original item and update the Group Props
                }
                return item;
            });
            this.setState({
                items: this._allItems
            });
            this.props.fileSpaceChangeTrigger(new Date().toISOString());
        });
        this.hideSecurityModal();
        const response = await fetch("/srch_update", {
            method: "POST",
            headers: this.getHeader(),
            body: JSON.stringify({ fileids: fileids, groupids: selectionId, companyid: this.props.companyId })
        });
    };

    showModal = () => this.setState({ isModalOpen: true });
    hideModal = () => this.setState({ isModalOpen: false });
    showSecurityModal = () => this.setState({ isSecurityModalOpen: true });
    hideSecurityModal = () => this.setState({ isSecurityModalOpen: false });
    hideInvoiceModal = () => this.setState({ invoiceModalItem: undefined });
    hideAISearchDataModal = () => this.setState({ aiSearchData: undefined });
    hideSummaryModal = () => this.setState({ summaryModalItem: undefined });
    isMicModalVisible = () => this.setState({ isMicModalVisible: false });
    showInvoiceModal = (itm: IDocument) => {
        this.setState({ invoiceModalItem: itm });
    };
    getContentPath = (itemSelectionString: string) => {
        const itemSel = itemSelectionString.split(";")[0];
        const itemid = this._allItems.filter(item => item.name == itemSel).map(item => item.key)[0];
        const contPath = `${itemSel}_fid=${itemid}`;
        //console.log("SLC¦ContPath", contPath);
        return contPath;
    };
    getItemFromSel = (itemSelectionString: string) => {
        const itemSel = itemSelectionString.split(";")[0];
        const item = this._allItems.filter(item => item.name == itemSel)[0];
        return item;
    };
    handleDocumentDisplay = (item: any) => {
        if (this.isItemReady(item) == true) {
            console.log("SLC¦handleDocumentDisplay", item);
            this.props.onCitationClicked(`${item.name}#page=1_fid=${item.key}`, null);
        } else {
            toast.warning(`"Item not Ready Yet ${item.docstatus}`);
        }
    };
    isItemReady = (item: IDocument) => {
        if ((item?.docstatus == "Ready" || item?.docstatus == undefined) && item?.isDeleting != true) {
            return true;
        } else {
            return false;
        }
    };
    onUploadCardClick = (typeSrc: string) => {
        console.log("SLC¦onUpldCrdClck", typeSrc);
        let elB = document.getElementById(typeSrc)?.click();
    };
    publishTranscriptDraftHandler = async (trnscptPbls: gtranscriptpublish, listRefreshRequest: () => void) => {
        try {
            await publishTranscriptDraft(trnscptPbls, listRefreshRequest);
        } catch (error) {
            console.error(error);
        }
    };
    callOutVisibility = (callOutVisibility: boolean) => {};

    exportCSV = async () => {
        const params = new URLSearchParams({ group_oid: this.props.selectedGroup?.oid.toString() ?? "" });
        const url = "/get_structured_data_export" + `?${params.toString()}`;
        fetch(url, {
            method: "GET",
            headers: this.getHeader()
        }).then(res => {
            res.json().then(data => {
                console.log("SLC¦ExportCSV", data);

                // Convert data to CSV using PapaParse
                const csv = Papa.unparse(data, {
                    quotes: true, // Wrap all fields in quotes for safety
                    header: true // Include header row automatically
                });

                // Add UTF-8 BOM for correct Cyrillic encoding
                const csvWithBom = "\uFEFF" + csv;

                const blob = new Blob([csvWithBom], { type: "text/csv;charset=utf-8" });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = "data.csv";
                a.click();
                window.URL.revokeObjectURL(url);
            });
        });
    };

    public render() {
        const { columns, isCompactMode, items, selectionDetails, isModalSelection, announcedMessage, isModalOpen, pathSelectionString, itemSelectionString } =
            this.state;
        return (
            <Fabric>
                <div className={classNames.controlWrapper} id="#dLM">
                    {this.props.selectedGroup.selectionId !== "" &&
                    this.props.selectedGroup.selectionId !== "FileSelect" && // Exclude FileSelect Group (Legacy)
                    this.props.getOFromListVal >= 2 ? ( // Add Space Button ¦ Selected Group Permission Level greater then Read (Member or Owner)
                        <div className={styles.spaceHead}>
                            {this.props.selectedGroup.selectionId !== this.props.user.userId && this.props.getOFromListVal >= 10 && (
                                <div className={styles.spaceOptionButtons}>
                                    <TogglePanelButton className={styles.spaceManButtons} onClick={this.props.openSpacesEditPanel} text="" iconType={FiEdit} />
                                    <TogglePanelButton
                                        className={styles.spaceManButtons}
                                        onClick={this.props.openSpacesAnalyticsPanel}
                                        text=""
                                        iconType={FiBarChart2}
                                    />
                                </div>
                            )}

                            <div className={styles.rightButtons}></div>

                            <div className={styles.rightButtons}>
                                {/* Upload */}
                                {this.props.selectedGroup.permissionlevel > 1 && (
                                    <div id="addButtonOpI" className={styles.commsOnly}>
                                        <div className={styles.labelA}>Add</div>
                                        <div className={styles.iIcons}>
                                            <div className={styles.uploadButsContainerSecond}>
                                                <div className={styles.subButsContW}>
                                                    <div>
                                                        <TooltipHost content="Add Documents" directionalHint={DirectionalHint.topCenter}>
                                                            <AddURLs
                                                                className={styles.commandButtonLib}
                                                                updateCatsState={(newValue: string) => this.props.updateCatsState(newValue)}
                                                                butName="PDF"
                                                                calloutLabelText="Upload PDFs from your Device"
                                                                cTy="pdf"
                                                                listRefreshRequest={this.props.listRefreshRequest}
                                                                selectedGroup={this.props.selectedGroup}
                                                                callOutVisibility={this.callOutVisibility}
                                                            />
                                                        </TooltipHost>
                                                    </div>
                                                    {this.props.selectedGroup.allowvideofiles == 1 && (
                                                        <TooltipHost content="Add Video Files" directionalHint={DirectionalHint.topCenter}>
                                                            <AddURLs
                                                                className={styles.commandButtonLib}
                                                                updateCatsState={(newValue: string) => this.props.updateCatsState(newValue)}
                                                                butName="Video"
                                                                calloutLabelText="Upload Video Files from your Device"
                                                                cTy="vid"
                                                                listRefreshRequest={this.props.listRefreshRequest}
                                                                selectedGroup={this.props.selectedGroup}
                                                                callOutVisibility={this.callOutVisibility}
                                                            />
                                                        </TooltipHost>
                                                    )}
                                                    {this.props.selectedGroup.allowyoutube == 1 && (
                                                        <div>
                                                            <TooltipHost content="Add YouTube videos" directionalHint={DirectionalHint.topCenter}>
                                                                <AddURLs
                                                                    className={styles.commandButtonLib}
                                                                    updateCatsState={(newValue: string) => this.props.updateCatsState(newValue)}
                                                                    butName="YouTube"
                                                                    calloutLabelText="Enter YouTube URL"
                                                                    cTy="youtube"
                                                                    listRefreshRequest={this.props.listRefreshRequest}
                                                                    selectedGroup={this.props.selectedGroup}
                                                                    callOutVisibility={this.callOutVisibility}
                                                                />
                                                            </TooltipHost>
                                                        </div>
                                                    )}
                                                    {this.props.selectedGroup.allowweb == 1 && (
                                                        <div>
                                                            <TooltipHost content="Add Web Pages" directionalHint={DirectionalHint.topCenter}>
                                                                <AddURLs
                                                                    className={styles.commandButtonLib}
                                                                    updateCatsState={(newValue: string) => this.props.updateCatsState(newValue)}
                                                                    butName="Web"
                                                                    calloutLabelText="Enter Web URL"
                                                                    cTy="web"
                                                                    listRefreshRequest={this.props.listRefreshRequest}
                                                                    selectedGroup={this.props.selectedGroup}
                                                                    callOutVisibility={this.callOutVisibility}
                                                                />
                                                            </TooltipHost>
                                                        </div>
                                                    )}
                                                    {this.props.selectedGroup.allowaudiodocentry == 1 && (
                                                        <div>
                                                            <TooltipHost content="Record Content" directionalHint={DirectionalHint.topCenter}>
                                                                <AddURLs
                                                                    className={styles.commandButtonLib}
                                                                    updateCatsState={(newValue: string) => this.props.updateCatsState(newValue)}
                                                                    butName="Web"
                                                                    calloutLabelText="Record Audio"
                                                                    cTy="mic"
                                                                    listRefreshRequest={this.props.listRefreshRequest}
                                                                    selectedGroup={this.props.selectedGroup}
                                                                    callOutVisibility={this.callOutVisibility}
                                                                />
                                                            </TooltipHost>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.hiddenRefs}>
                                            <div id={`calloutButton_pdf`} className={styles.hiddenRefElement}></div>
                                            <div id={`calloutButton_youtube`} className={styles.hiddenRefElement}></div>
                                            <div id={`calloutButton_vid`} className={styles.hiddenRefElement}></div>
                                            <div id={`calloutButton_web`} className={styles.hiddenRefElement}></div>
                                            <div id={`calloutButton_mic`} className={styles.hiddenRefElement}></div>
                                        </div>
                                    </div>
                                )}

                                <TextField
                                    onChange={this._onChangeText}
                                    className={styles.searchBoxDetailsList}
                                    onRenderPrefix={() => <FiSearch />}
                                    styles={textFieldStyles}
                                />
                                {this.props.selectedGroup.invoicesgroup == 1 && (
                                    <TooltipHost content="Download CSV of all Structured Invoice Data" directionalHint={DirectionalHint.rightTopEdge}>
                                        <div className={styles.exportCsvButton} onClick={() => this.exportCSV()}>
                                            <TbTableDown size={18} color="#2f4858" />
                                        </div>
                                    </TooltipHost>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className={styles.spaceHead}>
                            <button className={styles.libTitle}>{this.props.selectedGroup.selectionText}</button>
                            <div className={styles.rightButtons}>
                                <TextField
                                    onChange={this._onChangeText}
                                    className={styles.searchBoxDetailsList}
                                    onRenderPrefix={() => {
                                        return (
                                            <div style={{ margin: "0px", padding: "opx", width: "5px" }}>
                                                <FiSearch />
                                            </div>
                                        );
                                    }}
                                    styles={textFieldStyles}
                                />
                            </div>
                        </div>
                    )}
                </div>

                {items.length > 0 ? (
                    <div style={{ height: "calc(100vh - 180px)", overflow: "auto" }}>
                        <ThemeProvider theme={detailsListTheme}>
                            <MarqueeSelection selection={this._selection}>
                                <DetailsList
                                    className={styles.customDetailsList}
                                    items={items}
                                    compact={isCompactMode}
                                    columns={columns}
                                    isHeaderVisible={true}
                                    selectionMode={SelectionMode.multiple}
                                    //getKey={this._getKey}
                                    setKey="multiple"
                                    layoutMode={DetailsListLayoutMode.justified}
                                    selection={this._selection}
                                    selectionPreservedOnEmptyClick={true}
                                    onItemInvoked={this.handleDocumentDisplay}
                                    enterModalSelectionOnTouch={true}
                                    ariaLabelForSelectionColumn="Toggle Selection"
                                    ariaLabelForSelectAllCheckbox="Toggle Selection for all items"
                                    checkButtonAriaLabel="Row checkbox"
                                    onItemContextMenu={this.onItemContextMenu}
                                    styles={controlStyles}
                                    onRenderDetailsHeader={(props?: IDetailsHeaderProps) => this.onRenderDetailsHeader(props!)}
                                    onRenderRow={(prps: IDetailsRowProps | undefined): JSX.Element => {
                                        return <DetailsRow {...(prps as IDetailsRowProps)} styles={prps?.styles} />;
                                    }}
                                    onShouldVirtualize={() => items.length > 40}
                                />
                            </MarqueeSelection>
                        </ThemeProvider>
                    </div>
                ) : this.props.fileList == undefined ? (
                    <div className={styles.greetingsDetList}>
                        <ShimmeredDetailsList
                            className={styles.customDetailsListShimmer}
                            enableShimmer={true}
                            items={[]}
                            shimmerLines={7}
                            columns={columns}
                            compact={false}
                            selectionMode={SelectionMode.none}
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={false}
                            selectionPreservedOnEmptyClick={true}
                            enterModalSelectionOnTouch={true}
                        />
                        <div className={styles.noDocsUploadCardsContainerAbs}>
                            <UploadCards onUploadCardClick={this.onUploadCardClick} selectedGroup={this.props.selectedGroup}></UploadCards>
                        </div>
                    </div>
                ) : (
                    <ShimmeredDetailsList
                        className={styles.customDetailsList}
                        enableShimmer={true}
                        items={[]}
                        shimmerLines={3}
                        columns={columns}
                        compact={isCompactMode}
                        selectionMode={SelectionMode.multiple}
                        setKey="multiple"
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        selection={this._selection}
                        selectionPreservedOnEmptyClick={true}
                        onItemInvoked={this.handleDocumentDisplay}
                        enterModalSelectionOnTouch={true}
                        ariaLabelForSelectionColumn="Toggle selection"
                        onRenderDetailsHeader={(props?: IDetailsHeaderProps) => this.onRenderDetailsHeader(props!)}
                    />
                )}
                {this.state.selectionDetails && <div className={styles.selDet}>{this.state.selectionDetails}</div>}
                {this.state.isContextMenuVisible && (
                    <ContextualMenu
                        items={[
                            {
                                key: "Download",
                                text: "Download",
                                onClick: this.onContextMenuItemClick,
                                disabled: false
                            },
                            { key: "Share", text: "Move", onClick: this.onContextMenuItemClick, disabled: this.isItemReady(this.state.targetItem) == false },
                            {
                                key: "Reindex",
                                text: "Reindex",
                                onClick: this.onContextMenuItemClick,
                                disabled: false
                            },
                            ...(this.props.selectedGroup.invoicesgroup === 1 &&
                            this.props.selectedGroup.permissionlevel >= 2 &&
                            (this.state.targetItem?.doc_category == "invoice" || this.state.targetItem?.doc_category == "form")
                                ? [{ key: "Invoice", text: "Data Extraction", onClick: this.onContextMenuItemClick }]
                                : []),
                            ...(this.props.selectedGroup.assistanttype == "cmp" && this.props.selectedGroup.permissionlevel >= 2
                                ? [{ key: "Diff", text: "Compare", onClick: this.onContextMenuItemClick }]
                                : []),
                            {
                                key: "IndexView",
                                text: "View AI Search Entries",
                                onClick: this.onContextMenuItemClick,
                                disabled: this.isItemReady(this.state.targetItem) == false
                            },
                            ...(this.props.selectedGroup.permissionlevel >= 2
                                ? [
                                      {
                                          key: "Delete",
                                          text: "Delete",
                                          onClick: this.onContextMenuItemClick,
                                          itemProps: {
                                              styles: {
                                                  root: {
                                                      color: "#8B0000",
                                                      backgroundColor: "#FFCCCB"
                                                  }
                                              }
                                          }
                                      }
                                  ]
                                : [])
                        ]}
                        target={this.state.contextMenuTarget}
                        onDismiss={() => this.setState({ isContextMenuVisible: false })}
                    />
                )}

                {this.state.isSpaceFilterVisible && (
                    <ContextualMenu
                        items={this.getSpaceFilterChoices()}
                        target={document.getElementById("spaceHeaderFilterIcon")}
                        onDismiss={() => this.setState({ isSpaceFilterVisible: false })}
                    />
                )}

                {/*Share*/}
                <Modal
                    isOpen={this.state.isSecurityModalOpen}
                    onDismiss={this.hideSecurityModal}
                    isBlocking={false}
                    containerClassName={styles.changeSpaceModalMain}
                >
                    <div style={{ margin: "20px" }}>
                        <div className={styles.changeSpaceTextPortion}>
                            <div className={styles.changeSpaceHTitle}>Move</div>
                            <div className={styles.changeSpaceHDetails}>Change the Space for the Selected Item</div>
                        </div>
                        <div className={styles.changeSpaceHDropdownContained}>
                            <Select
                                options={this.props.groupList
                                    .filter(
                                        (option: any) =>
                                            option.key !== "FileSelect" && option.memberlevel >= 2 && option.key !== this.props.selectedGroup.selectionId
                                    )
                                    .map((option: any) => ({ value: option.key, label: option.text }))}
                                onChange={(selectedOption: any) => {
                                    if (selectedOption) {
                                        this.qHandleFileSelectionSpaceChange(selectedOption.value, selectedOption.label);
                                    }
                                }}
                                placeholder="Select an Assistant..."
                                styles={{
                                    menu: provided => ({
                                        ...provided,
                                        maxHeight: 400
                                    }),
                                    control: provided => ({
                                        ...provided,
                                        maxHeight: 50 // Height of the control bar (adjust as needed)
                                    })
                                }}
                                menuPlacement="auto"
                                menuPosition="fixed"
                            />
                        </div>
                    </div>
                </Modal>
                {/*JsonGrid*/}
                <Modal
                    isOpen={this.state.invoiceModalItem == undefined ? false : true}
                    onDismiss={this.hideInvoiceModal}
                    isBlocking={false}
                    containerClassName={styles.invoiceModalWrap}
                >
                    <JsonGrid
                        jsonString={this.state.invoiceModalItem?.invoicedata}
                        itmName={this.state.invoiceModalItem?.name}
                        fid={this.state.invoiceModalItem?.key}
                        loggedInUser={this.props.loggedInUser}
                    />
                </Modal>
                {/*AISearchJsonGrid*/}
                <Modal
                    isOpen={this.state.aiSearchData == undefined ? false : true}
                    onDismiss={this.hideAISearchDataModal}
                    isBlocking={false}
                    containerClassName={styles.aiSearchRIndicesModal}
                >
                    <div className={styles.indicesModalTitle}>{this.state.activeFile?.name}</div>
                    <AISearchJsonGrid value={this.state.aiSearchData?.indx_results} itmTitle={this.state.invoiceModalItem?.name} />
                </Modal>
                {/*invoiceModal*/}
                <Modal
                    isOpen={this.state.summaryModalItem == undefined ? false : true}
                    onDismiss={this.hideSummaryModal}
                    isBlocking={false}
                    containerClassName={styles.invoiceModal}
                >
                    <CollapsibleSections data={this.state.summaryModalItem || []} />
                </Modal>

                <Modal
                    isOpen={this.state.isMicModalVisible}
                    onDismiss={() => this.isMicModalVisible()}
                    isBlocking={false}
                    containerClassName={styles.convoRecModalContainer}
                >
                    <ConversationRecognizer
                        selectedGroup={this.props.selectedGroup}
                        loggedInUser={this.props.loggedInUser}
                        publishTranscriptDraftCallback={(transcriptObject: gtranscriptpublish) =>
                            this.publishTranscriptDraftHandler(transcriptObject, this.props.listRefreshRequest)
                        }
                        activeFile={this.state.activeFile}
                        onSTT={onSTT}
                    />
                </Modal>
            </Fabric>
        );
    }

    public componentDidUpdate(previousProps: any, previousState: IDetailsListDocumentsExampleState) {
        if (previousState.isModalSelection !== this.state.isModalSelection && !this.state.isModalSelection) {
            this._selection.setAllSelected(false);
        }
        if (previousProps.fileList !== this.props.fileList) {
            this._allItems = parseDocuments(this.props.fileList || []);
            this.setState({
                items: this._allItems //this.filterSpace(this.state.spacesFilter)
            });
        }
        if (this.state.highlightItemId !== previousState.highlightItemId && this.state.highlightItemId !== undefined) {
            this.setState({
                items: [...this.state.items] // Create a shallow copy to force re-render
            });
        }
    }
    private _onChangeCompactMode = (ev: MouseEvent<HTMLElement>, checked?: boolean): void => {
        this.setState({ isCompactMode: checked });
    };
    private includesText = (i: any, text?: string): boolean => {
        var vals = Object.keys(i).map(function (key) {
            return i[key];
        });
        //console.log("INCLTXT¦", text, vals.toString());
        return vals.some(
            txt =>
                txt
                    ?.toString()
                    .toLowerCase()
                    .indexOf(text?.toLowerCase()) > -1
        );
    };
    private filter = (text?: string): any[] => {
        return this._allItems.filter(i => this.includesText(i, text)) || this._allItems;
    };
    private filterSpace = (text?: string): any[] => {
        return text
            ? this._allItems.filter(i => i.groupIds?.includes(text ? text : "") || (i.filerole && text == "MyFiles")) || this._allItems
            : this._allItems;
    };
    private _onChangeText = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text?: string): void => {
        let items = this.filter(text);
        this.setState({ items: items });
    };
    private getSpaceFilterChoices = () => {
        const itCh: IContextualMenuItem[] = this.props.groupList
            .filter(option => option.key !== "FileSelect")
            .map(option => ({
                key: option.key.toString(),
                text: option.text,
                onClick: () => this.onSpaceFilterClick(option)
                //subMenuProps: {
                //    items: [] // Assuming no sub-items, adjust as necessary
                //}
            }));

        return itCh;
    };
    private _getSelectionDetails(): string {
        const selectionCount = this._selection?.getSelectedCount();

        switch (selectionCount) {
            case 0:
                return "No Items Selected";
            case 1:
                return "1 Item Selected" /*+ (this._selection.getSelection()[0] as IDocument).name*/;
            case undefined:
                return "No Items Selected";
            default:
                return `${selectionCount} Items Selected`;
        }
    }

    private _applySortOnRefresh = (): void => {
        const { columns, items } = this.state;
        const sortedColumn = columns.find(column => column.isSorted);

        if (sortedColumn) {
            let sortedItems;

            if (sortedColumn.key === "kgnf_valid") {
                sortedItems = _copyAndSortAdv(items, sortedColumn.fieldName!, sortedColumn.isSortedDescending);
            } else if (sortedColumn.key === "kgnf_total" || sortedColumn.key === "kgnf_vat") {
                sortedItems = _copyAndSortAdvTotal(items, sortedColumn.key!, sortedColumn.isSortedDescending);
            } else if (sortedColumn.key == "kgnf_contractor" || sortedColumn.key == "kgnf_customer") {
                sortedItems = _copyAndSortInvStr(items, sortedColumn.key!, sortedColumn.isSortedDescending);
            } else {
                sortedItems = _copyAndSort(items, sortedColumn.fieldName!, sortedColumn.isSortedDescending);
            }

            this.setState({
                items: sortedItems
            });
        }
    };

    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        console.log("col_clck");
        const { columns, items } = this.state;
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];

        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
                this.setState({
                    announcedMessage: `${currColumn.name} is sorted ${currColumn.isSortedDescending ? "descending" : "ascending"}`
                });
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        let newItems;
        if (currColumn.key == "kgnf_valid") {
            console.log("AZ", items, currColumn.fieldName!, currColumn.isSortedDescending);
            newItems = _copyAndSortAdv(items, currColumn.fieldName!, currColumn.isSortedDescending);
        } else if (currColumn.key == "kgnf_total" || currColumn.key == "kgnf_vat") {
            console.log("c", currColumn.key);
            newItems = _copyAndSortAdvTotal(items, currColumn.key!, currColumn.isSortedDescending);
        } else if (currColumn.key == "kgnf_contractor" || currColumn.key == "kgnf_customer") {
            newItems = _copyAndSortInvStr(items, currColumn.key!, currColumn.isSortedDescending);
        } else {
            newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
        }

        this.setState({
            columns: newColumns,
            items: newItems
        });
    };
    private _onChangeModalSelection = (ev: React.MouseEvent<HTMLElement>, checked?: boolean): void => {
        this.setState({ isModalSelection: checked });
    };
    private onDropdownChange = (event: FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number): void => {
        console.log("SLC¦Dropdown", option, index);
    };
    private _getKey(item: any, index?: number): string {
        return item.key;
    }
    private _onItemInvoked(item: any): void {}
}
