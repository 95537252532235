import React, { useState, useEffect } from "react";
import styles from "./RollingStatus.module.css";

interface RollingStatusProps {
    newText: string;
}

const RollingStatus: React.FC<RollingStatusProps> = ({ newText }) => {
    const [text, setText] = useState(newText);
    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        if (newText !== text) {
            setIsAnimating(true);
            const timeout = setTimeout(() => {
                setText(newText);
                setIsAnimating(false);
            }, 300); // Match CSS transition duration
            return () => clearTimeout(timeout);
        }
    }, [newText, text]);

    return (
        <div className={styles.textBox}>
            <div className={`${styles.text} ${isAnimating ? styles.newText : styles.oldText}`}>{text}</div>
        </div>
    );
};

export default RollingStatus;
