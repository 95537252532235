import React from "react";
import { Callout, TextField, Dropdown, PrimaryButton, IDropdownOption, DirectionalHint } from "@fluentui/react";
import { TogglePanelButton } from "../../components/TogglePanelButton";
import { ClearChatButton } from "../../components/ClearChatButton";
import { List24Regular } from "@fluentui/react-icons";

import styles from "./ChatControls.module.css";
interface ChatControlsProps {
    isOpen: boolean;
    onDismiss: () => void;
    ButtonRef: React.RefObject<HTMLElement>;
    toggleLeftPanel: () => void;
    newConverson: () => void;
}

interface NewInviteUser {
    membermail: string;
    memberlevel: number;
}

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const permOptions: IDropdownOption[] = [
    // Add your permission options here
    { key: 1, text: "Admin" },
    { key: 2, text: "Editor" },
    { key: 3, text: "Viewer" }
];

export const ChtControls: React.FC<ChatControlsProps> = ({ isOpen, onDismiss, ButtonRef, toggleLeftPanel, newConverson }) => {
    return (
        <Callout
            gapSpace={0}
            styles={{
                root: {
                    width: 100,
                    height: "auto",
                    backgroundColor: "rgba(255, 255, 255, 0)"
                }
            }}
            target={ButtonRef.current}
            onDismiss={onDismiss}
            setInitialFocus
            directionalHint={DirectionalHint.bottomLeftEdge}
            className={styles.calloutMain}
            hidden={!isOpen}
        >
            <div className={styles.listContainer}>
                <TogglePanelButton className={styles.commandButtonOne} onClick={toggleLeftPanel} text="History" iconType={List24Regular} />
                <ClearChatButton className={styles.commandButtonTwo} onClick={newConverson} /*disabled={!lastQuestionRef.current || isLoading}*/ />
            </div>
        </Callout>
    );
};
export default ChtControls;
