import { useMemo, useEffect } from "react";
import { Stack, IconButton, mergeStyleSets, mergeStyles } from "@fluentui/react";
import DOMPurify from "dompurify";
import styles from "./Answer.module.css";
import { ChatAppResponse } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";
import { FiFileText } from "react-icons/fi";
import { TooltipHost, TooltipDelay, DirectionalHint } from "@fluentui/react";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away.css";
import { gselectedgroup, gfeedback } from "../../interfaces";
import ChartRenderer from "./../ChartRenderer/ChartRenderer";
import { FaQuestionCircle } from "react-icons/fa";
import FeedbackComponent from "../FeedbackComponent/FeedbackComponent";

import { FcDatabase } from "react-icons/fc";
import { BsDatabase } from "react-icons/bs";
interface CustomElement extends HTMLElement {
    listenerAdded?: boolean;
}
const convertMarkdownHeadersToHTML = (markdownText: string) => {
    return markdownText
        .replace(/^####\s+(.*)$/gm, "<h4>$1</h4>")
        .replace(/^###\s+(.*)$/gm, "<h3>$1</h3>")
        .replace(/^##\s+(.*)$/gm, "<h2>$1</h2>")
        .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");
};
type DataPoint = {
    content: string;
    filename: string;
    pages: any[];
    fileid: string;
};
type Caption = {
    content: string;
    filename: string;
    page: string;
    fileid: string;
    chunkid?: number;
};
type CaptionWithoutContent = {
    filename: string;
    page: string;
    fileid: string;
};
interface Props {
    answer: ChatAppResponse;
    index: number;
    isSelected?: boolean;
    isStreaming: boolean;
    onCitationClicked: (filePath: string, integratedCitation: string | null, el: HTMLElement | null, openSBS?: boolean) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    //sendBackCitationsData?: (data_points: string[]) => void;
    showFollowupQuestions?: boolean;
    use_integrated_search: boolean;
    selectedGroup: gselectedgroup;
    isSBS?: boolean;
    clickedCitation: string;
    totalAnswers: number;
    onSendFeedback: (prps: gfeedback) => void;
    isLoading?: boolean;
}
const copyToClipboard = (text: string) => {
    navigator.clipboard
        .writeText(text)
        .then(() => {})
        .catch(err => {});
};
const extractTextDstn = (content: string): Caption | null => {
    content = content.toString();
    const colonIndex = content.indexOf(":");
    if (colonIndex !== -1) {
        content = content.substring(0, colonIndex);
    }
    //const regex = /^(.*?)#page=(\d+)_fid=(\d+)$/;
    const regex = /^(.*?)#page=([^_]+)_fid=(\d+)$/;
    const match = content.match(regex);
    if (match) {
        const [, filename, page, fileid] = match;
        return { content, filename, page, fileid };
    }
    return null;
};
const extractTextCptns = (content: string): Caption | null => {
    content = content.toString();
    const colonIndex = content.indexOf(":");
    let contentText = "";
    if (colonIndex !== -1) {
        contentText = content.substring(colonIndex, content.length);
        content = content.substring(0, colonIndex);
    }
    //const regex = /^(.*?)#page=(\d+)_fid=(\d+)$/;
    const regex = /^(.*?)#page=([^_]+)_fid=(\d+)$/;
    const match = content.match(regex);
    if (match) {
        const [, filename, page, fileid] = match;
        return { content: contentText, filename, page, fileid };
    }
    return null;
};
const extractFromFileLeaf = (content: string): CaptionWithoutContent | null => {
    content = content.toString();
    //const regex = /^(.*?)#page=(\d+)_fid=(\d+)$/;
    const regex = /^(.*?)#page=([^_]+)_fid=(\d+)$/;
    const match = content.match(regex);
    if (match) {
        const [, filename, page, fileid] = match;
        return { filename, page, fileid };
    }
    return null;
};
function simpleSQLFormatter(query: string) {
    // Function to add line breaks and indentation
    const formatSQL = (sql: string) => {
        const keywords = ["SELECT", "FROM", "WHERE", "JOIN", "LEFT JOIN", "RIGHT JOIN", "INNER JOIN", "GROUP BY", "HAVING", "ORDER BY"];
        let formattedQuery = sql.trim();

        // Add line breaks before keywords
        keywords.forEach(keyword => {
            formattedQuery = formattedQuery.replace(new RegExp(keyword, "gi"), "\n" + keyword);
        });

        // Split into lines and add indentation
        return formattedQuery.split("\n").map((line: any, index) => <div key={index}>{line}</div>);
    };

    return (
        <pre
            style={{
                fontFamily: "monospace",
                fontSize: "0.8em",
                whiteSpace: "pre-wrap",
                wordBreak: "break-word"
            }}
        >
            {formatSQL(query)}
        </pre>
    );
}
export const Answer = ({
    answer,
    isSelected,
    isStreaming,
    index,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions,
    use_integrated_search,
    selectedGroup,
    isSBS,
    clickedCitation,
    totalAnswers,
    onSendFeedback,
    isLoading
}: Props) => {
    const followupQuestions = answer.choices[0].context.followup_questions;
    const messageContent = answer.choices[0].message.content;
    const citationData = answer.choices[0].context.data_points_integrated?.citations || [];
    const dataPoints = useMemo(() => {
        let points: Caption[] = [];
        let citD = answer.choices[0].context.data_points_integrated?.citations || [];
        citD?.map((x: any) => {
            points.push({
                content: x.content,
                filename: x.filepath.split("#page=")[0],
                page: `${x.filepath.split("#page=")[1]}`,
                fileid: x.url,
                chunkid: x.chunk_id
            });
        });

        const uniqueDataPoints: { [key: string]: DataPoint } = {};
        points = points.sort((a, b) => (a.chunkid || 0) - (b.chunkid || 0));
        points.forEach(point => {
            if (uniqueDataPoints[point.filename]) {
                uniqueDataPoints[point.filename].pages.push(point);
            } else {
                uniqueDataPoints[point.filename] = { content: point.content, filename: point.filename, pages: [point], fileid: point.fileid };
            }
        });
        return Object.values(uniqueDataPoints);
    }, [answer.choices[0].context.data_points, answer.choices[0].context.data_points_integrated]);

    const parsedAnswer = useMemo(() => parseAnswerToHtml(messageContent, isStreaming, citationData, use_integrated_search, index, clickedCitation), [answer]);
    const chart = useMemo(() => {
        try {
            return answer.choices[0].context.chartjs_json && <ChartRenderer configJson={JSON.stringify(answer.choices[0].context.chartjs_json)} />;
        } catch (e) {
            return null;
        }
    }, [answer.choices[0].context.chartjs_json]);
    const openCitationFromMessage = (el: HTMLElement, openSBS?: boolean) => {
        const citationPath = el.getAttribute("data-citation-link");
        const integratedCitation = el.getAttribute("data-integrated-citation");

        if (citationPath) {
            import.meta.env.DEV === true && console.log("CITATION PATH", citationPath, "¦INTCIT¦", integratedCitation, "¦SBS¦", openSBS);
            onCitationClicked(citationPath, integratedCitation, el, openSBS);
        }
        const tooltip = document.getElementById("tooltip");
        if (tooltip) {
            tooltip.classList.remove("visible");
            setTimeout(() => {
                tooltip.remove();
            }, 100);
        }
    };

    const handleClick = (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        import.meta.env.DEV === true && console.log("¦handleClick¦", target);
        if (target.matches(".citation-link")) {
            console.log("cit_src_01");
            openCitationFromMessage(target);
        } else if (target.matches(".citation-tooltip-link")) {
            const relevantElement = target.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.querySelector("sup") as HTMLElement;
            console.log("cit_src_02");
            openCitationFromMessage(relevantElement);
        } else if (target.matches("span")) {
            console.log("cit_src_03");
            if (target.innerText?.toLowerCase() == "see in document") {
                console.log("See in Document");
                const relevantElement = target.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.querySelector(
                    "sup"
                ) as HTMLElement;
                openCitationFromMessage(relevantElement, false);
            } else if (target.innerText?.toLowerCase() == "explore in side-by-side view") {
                console.log("explore in side-by-side view");
                const relevantElement = target.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.querySelector(
                    "sup"
                ) as HTMLElement;
                openCitationFromMessage(relevantElement, true);
            }
        }
    };
    //const memoizedCitations = useMemo(() => parsedAnswer.citations, [parsedAnswer.citations]);
    //const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);
    const sanitizedAnswerHtml = parsedAnswer.answerHtml;
    useEffect(() => {
        const container = document.getElementById(`answr_cntnt_${index}`) as CustomElement;
        if (container && !container.listenerAdded) {
            container.listenerAdded = true;
            container.addEventListener("click", handleClick);
        }
        tippy("[data-tippy-content]", {
            placement: "auto",
            arrow: true,
            animation: "shift-away",
            theme: "custom",
            allowHTML: true,
            interactive: true,
            duration: [300, 250],
            offset: [0, 10]
        });
    }, [parsedAnswer.citations.length]);
    const isTouchDevice = () => {
        return "ontouchstart" in window || navigator.maxTouchPoints > 0;
    };
    return (
        <Stack id={`answr_${index}`} className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">
            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between" className={styles.topHorizontalStack}>
                    <Stack.Item>
                        <AnswerIcon />
                    </Stack.Item>
                    <Stack.Item className={styles.topRightAnswerWrap}>
                        {!isStreaming && answer?.choices[0]?.context?.sql_query && (
                            <div className={`${index == totalAnswers - 1 ? styles.answerInfoRight : styles.answerInfoTotallyRight}`}>
                                <div
                                    onClick={e => {
                                        copyToClipboard(answer?.choices[0]?.context?.sql_query || "");
                                    }}
                                    className={styles.sqlQueryLabel}
                                >
                                    <TooltipHost
                                        closeDelay={500}
                                        content={
                                            <div>
                                                <div className={styles.sqltooltip}>{simpleSQLFormatter(answer?.choices[0]?.context?.sql_query || "")}</div>
                                                {/* <div className={styles.charttooltip}>{answer.choices[0].context.chartjs_json}</div> */}
                                            </div>
                                        }
                                        directionalHint={DirectionalHint.topCenter}
                                        delay={isTouchDevice() ? TooltipDelay.long : TooltipDelay.zero}
                                    >
                                        <BsDatabase size={15}></BsDatabase>
                                    </TooltipHost>
                                </div>
                            </div>
                        )}
                        {!isStreaming && index == totalAnswers - 1 ? (
                            <div className={styles.feedbackContainer}>
                                <FeedbackComponent onSendFeedback={onSendFeedback} interaction_guid={answer.interaction_guid} pos={answer?.pos} />
                            </div>
                        ) : isStreaming && isLoading == false && index == totalAnswers - 1 ? (
                            <div className={styles.streamingLabel}>
                                <div className={styles.loader}>
                                    <div className={styles.duo + " " + styles.duo1}>
                                        <div className={styles.dot + " " + styles.dota}></div>
                                        <div className={styles.dot + " " + styles.dotb}></div>
                                    </div>
                                    <div className={styles.duo + " " + styles.duo2}>
                                        <div className={styles.dot + " " + styles.dota}></div>
                                        <div className={styles.dot + " " + styles.dotb}></div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </Stack.Item>
                </Stack>
            </Stack.Item>

            {!!dataPoints.length && selectedGroup.inscope === 1 && (
                <Stack.Item>
                    <div className={styles.searchResultsLabel}>
                        <div>AI Search Results:</div>
                        <div className={styles.askMoreIcon}>
                            <TooltipHost
                                content="All Content considered by the Assistant to answer you query. The answer might not use all of these sources."
                                directionalHint={DirectionalHint.topCenter}
                            >
                                <FaQuestionCircle size={10}></FaQuestionCircle>
                            </TooltipHost>
                        </div>
                    </div>
                    <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                        {dataPoints.map((x, i) => (
                            <Stack.Item key={`aisrchres_${x.fileid || "nofleid"}_${i}_${index}`} className={styles.sourceDoc}>
                                <div className={styles.citationIcon}>
                                    <FiFileText size={16} />
                                </div>
                                <div className={styles.citationFileText}>{`${x.filename}`}</div>

                                {x.pages.length > 0 ? (
                                    x.pages.map((page, subindex) => {
                                        const title = `/content/${x.filename}#page=${page.page}_fid=${x.fileid}`;
                                        return (
                                            <a
                                                key={`${"airesitm"}_${x.fileid}${subindex}`}
                                                onClick={e => {
                                                    onCitationClicked(title, null, null);
                                                    e.stopPropagation();
                                                }}
                                            >
                                                <TooltipHost
                                                    className={styles.srchToolDocTooltip}
                                                    content={
                                                        <div className={styles.srchToolMain}>
                                                            <div className={styles.srchToolDocText}>
                                                                <div className={styles.srchToolDocTitle}>{x.filename}</div>
                                                                <div className={styles.srchToolDocDetails}>
                                                                    <div className={styles.srchToolDocPage}> Page Range {page.page.replace("|", "..")}</div>
                                                                    <div className={styles.srchToolDivider}>·</div>
                                                                    <div>Chunk ID {page.chunkid}</div>
                                                                </div>
                                                            </div>
                                                            <div>{page.content}</div>
                                                        </div>
                                                    }
                                                    directionalHint={DirectionalHint.topCenter}
                                                    delay={isTouchDevice() ? TooltipDelay.long : TooltipDelay.zero}
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        return;
                                                    }}
                                                >
                                                    <div className={styles.docSourceCitation} key={`${"airessub"}_${i}_${subindex}`}>
                                                        {page.page.replace("|", "..")}
                                                        {/*    <div className={styles.chunkIdIndicator}>{page.chunkid} </div>*/}
                                                    </div>
                                                </TooltipHost>
                                            </a>
                                        );
                                    })
                                ) : (
                                    <div></div>
                                )}
                            </Stack.Item>
                        ))}
                    </Stack>
                </Stack.Item>
            )}

            <Stack.Item grow>
                <div id={`answr_cntnt_${index}`} className={styles.answerText} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></div>
            </Stack.Item>

            {answer.choices[0]?.context?.no_info_message && (
                <Stack.Item className={styles.externalInfoStackItem}>
                    <div className={styles.citationLearnMore}>External Info & Clarifications:</div>
                    <div className={styles.noInfoMessage}>
                        <div
                            className={styles.answerText}
                            dangerouslySetInnerHTML={{ __html: convertMarkdownHeadersToHTML(answer.choices[0].context.no_info_message) }}
                        ></div>
                    </div>
                </Stack.Item>
            )}

            {!!parsedAnswer.citations.length && !selectedGroup.disablecitationhighlighting && (
                <Stack.Item>
                    <Stack horizontal wrap tokens={{ childrenGap: 5 }} className={styles.citationStack}>
                        <div className={styles.citationsLabel}>
                            <div className={styles.citationLearnMore}>Citations:</div>
                            <div className={styles.askMoreIconCitations}>
                                <TooltipHost
                                    content="Content used by the Assistant to anwser your query. Click to navigate to the Page of the document. For more precise sourcing use inline citations"
                                    directionalHint={DirectionalHint.topCenter}
                                >
                                    <FaQuestionCircle size={10}></FaQuestionCircle>
                                </TooltipHost>
                            </div>
                        </div>
                        {parsedAnswer.citations.map((x, i) => {
                            const path = `/content/${x}`;
                            const citInf = extractTextCptns(x);
                            return (
                                <a
                                    key={i}
                                    id={`cit_answid_${index}_citinx_${i}`}
                                    className={isSBS == true ? styles.citationQSBS : styles.citationQ}
                                    title={x}
                                    onClick={() => onCitationClicked(path, null, null)}
                                >
                                    <div className={styles.citationIcon}>
                                        <FiFileText size={16} />
                                    </div>
                                    {`${++i}. ${citInf?.filename} [${citInf?.page}]`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}

            {!!followupQuestions?.length && showFollowupQuestions && onFollowupQuestionClicked && (
                <Stack.Item className={styles.externalInfoStackItem}>
                    <Stack horizontal wrap className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                        <span className={styles.followupQuestionLearnMore}>Follow-up questions:</span>
                        {followupQuestions.map((x, i) => {
                            return (
                                <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                    {`${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}
            {chart}
        </Stack>
    );
};
