import React, { useState, useEffect, useRef } from "react";
import styles from "./PageSelector.module.css";
interface PageSelectorProps {
    currentPage: number;
    numPages: number;
    handlePageChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}
import Select, { StylesConfig, components, SingleValueProps, ActionMeta } from "react-select";
import { ThemeProvider } from "@fluentui/react";
interface OptionType {
    value: number;
    label: string;
}
import customTheme from "./../../theme/customTheme";
const createSyntheticEvent = (value: number): React.ChangeEvent<HTMLSelectElement> => {
    const event = {
        target: {
            value: String(value)
        }
    } as React.ChangeEvent<HTMLSelectElement>;
    return event;
};

const PageSelector: React.FC<PageSelectorProps> = ({ currentPage, numPages, handlePageChange }) => {
    const [animatedNumber, setAnimatedNumber] = useState(currentPage);
    const animationRef = useRef<number | null>(null);
    const options: OptionType[] = Array.from({ length: numPages || 0 }, (_, i) => ({
        value: i + 1,
        label: String(i + 1)
    }));
    useEffect(() => {
        if (currentPage !== animatedNumber) {
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
            }

            const animate = () => {
                setAnimatedNumber(prev => {
                    const diff = currentPage - prev;
                    const change = Math.sign(diff) * Math.ceil(Math.abs(diff) / 5);
                    const next = prev + change;

                    if (next === currentPage) {
                        return currentPage;
                    }

                    animationRef.current = requestAnimationFrame(animate);
                    return next;
                });
            };

            animationRef.current = requestAnimationFrame(animate);
        }

        return () => {
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
            }
        };
    }, [currentPage, animatedNumber]);
    const customStyles: StylesConfig<OptionType, false> = {
        control: (provided, state) => ({
            ...provided,
            minHeight: "25px",
            maxWidth: "55px",
            borderRadius: "5px",
            borderWidth: "0px",
            backgroundColor: "#C1C8D6",
            color: "#2F4858",
            cursor: "pointer",
            boxShadow: "none", // Remove focus box shadow
            border: "none",
            outline: "none" // Remove focus outline
        }),
        valueContainer: provided => ({
            ...provided,
            padding: "0 5px",
            justifyContent: "center"
        }),
        dropdownIndicator: provided => ({
            ...provided,
            padding: "0 5px",
            marginLeft: "5px",
            color: "#f1f1e6",
            "&:hover": {
                color: "#2f4858"
            }
        }),
        indicatorSeparator: () => ({
            display: "none"
        }),
        menu: provided => ({
            ...provided,
            maxWidth: "55px",
            borderRadius: "5px",
            marginTop: "2px",
            overflow: "hidden"
        }),
        menuList: provided => ({
            ...provided,
            padding: 0
        }),
        option: (provided, state) => ({
            ...provided,
            padding: "5px 10px",
            fontSize: "12px",
            backgroundColor: "#C1C8D6",
            color: "rgb(47, 72, 88)",
            cursor: "pointer",
            "&:hover": {
                backgroundColor: "#F2F3F5"
            }
        }),
        singleValue: provided => ({
            ...provided,
            color: "#2F4858",
            fontSize: "14px"
        })
    };
    return (
        <div
            style={{
                position: "relative",
                textAlign: "center",
                width: "max-content",
                height: "25px",
                margin: "5px 5px 20px 5px",

                right: "5px",
                borderRadius: "5px",
                borderWidth: "0px !important",
                minWidth: "35px",
                maxWidth: "55px",
                backgroundColor: "red"
            }}
        >
            <ThemeProvider theme={customTheme}>
                <Select<OptionType, false>
                    styles={customStyles}
                    value={options.find(option => option.value === currentPage)}
                    onChange={selectedOption => {
                        if (selectedOption) {
                            const syntheticEvent = createSyntheticEvent(selectedOption.value);
                            handlePageChange(syntheticEvent);
                        }
                    }}
                    options={options}
                    isSearchable={false}
                    components={{
                        IndicatorSeparator: () => null
                    }}
                />
                <div
                    className={styles.pageSelectorIndicatorContainer}
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        minWidth: "35px",
                        maxWidth: "55px",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        pointerEvents: "none",
                        zIndex: 1,
                        borderRadius: "5px",
                        borderWidth: "0px !important",
                        backgroundColor: "#C1C8D6"
                    }}
                >
                    <div className={styles.pageSelectorIndicatorSubContainer} style={{ overflow: "hidden", height: "20px", padding: "0" }}>
                        <div
                            className={styles.pageSelectorIndicator}
                            style={{
                                transition: "transform 0.5s ease ",
                                transform: `translateY(-${(currentPage - 1) * 20}px)`,
                                backgroundColor: "#C1C8D6",
                                borderWidth: "0px !important",
                                paddingLeft: "5px",
                                paddingRight: "5px",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                minWidth: "35px",
                                maxWidth: "55px",
                                borderRadius: "5px"
                            }}
                        >
                            {Array.from({ length: numPages }, (_, i) => (
                                <div className={styles.displayPage} key={i}>
                                    {i + 1}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </ThemeProvider>
        </div>
    );
};

export default PageSelector;
