import React, { useState } from "react";
import "./AISearchJsonGrid.css";

interface AISearchJsonGridProps {
    value?: any;
    itmTitle?: string;
}
interface ValueCounts {
    [key: string]: number;
}
const applyHeatmapColors = (): void => {
    // Get all elements with class aisrchval
    const elements: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName("aisrchval") as HTMLCollectionOf<HTMLElement>;

    // Count occurrences of each value
    const valueCounts: ValueCounts = {};
    Array.from(elements).forEach((el: HTMLElement) => {
        const value = el.getAttribute("data-heatmap");
        if (value !== null) {
            valueCounts[value] = (valueCounts[value] || 0) + 1;
        }
    });

    // Find max count for scaling
    const counts: number[] = Object.values(valueCounts);
    const maxCount: number = Math.max(...counts);
    const getHeatmapColor = (count: number, op: number): string => {
        let ratio = count / maxCount;
        //console.log("ratio", count, maxCount, ratio);
        // Apply non-linear scaling to make low values less visible
        // Values below 0.2 (20%) will be very transparent
        // Values above will become more quickly visible
        if (ratio < 0.05) {
            op *= (ratio / 0.2) * 0.3; // Very low opacity for low values
        } else {
            // Exponential scaling for values above 20%
            ratio = Math.pow((ratio - 0.2) / 0.8, 0.5); // Square root for smoother transition
            ratio = 0.2 + ratio * 0.8; // Rescale back to full range
        }

        // Hue goes from 120 (green) to 39 (orange)
        const hue = 120 - ratio * 81;
        const saturation = 80;
        const lightness = 50;

        return `hsla(${hue}, ${saturation}%, ${lightness}%, ${Math.round(op * 10) / 3})`;
    };
    // Apply colors based on frequency
    Array.from(elements).forEach((el: HTMLElement) => {
        const value = el.getAttribute("data-heatmap");
        if (value !== null) {
            const count = valueCounts[value];

            // Calculate opacity based on frequency (0.2 to 1)
            const opacity = 0 + (0.8 * count) / maxCount;

            // Apply background color
            el.style.backgroundColor = getHeatmapColor(count, opacity);
        }
    });
};
const splitAndCombineShortWords = (text: string) => {
    const excludedWords = [
        "the",
        "and",
        "or",
        "a",
        "is",
        "in",
        "of",
        "their",
        "its",
        "same",
        "so",
        "than",
        "too",
        "very",
        "can",
        "will",
        "just",
        "should",
        "ain",
        "aren",
        "couldn",
        "didn",
        "doesn",
        "hadn",
        "hasn",
        "haven",
        "isn",
        "wasn",
        "weren",
        "won",
        "wouldn",
        "was",
        "are",
        "has",
        "for",
        "with",
        "from",
        "that",
        "about",
        "were",
        "after",
        "but",
        "due"
    ];
    const minLength = 2;
    const words = text.split(" ").filter(Boolean);

    const result = [];
    let currentPhrase = "";
    let i = 0;

    while (i < words.length) {
        const currentWord = words[i]?.trim();
        const isExcludedWord = excludedWords.includes(currentWord.toLowerCase());

        // Start a new phrase or add to existing
        if (!currentPhrase) {
            currentPhrase = currentWord;
        } else {
            currentPhrase += " " + currentWord;
        }

        // If we're at the last word
        if (i === words.length - 1) {
            result.push(currentPhrase);
            break;
        }

        // If current phrase is long enough and current word isn't excluded,
        // save it and start new phrase
        if (currentPhrase.replace(/\s+/g, "").length > minLength && !isExcludedWord) {
            result.push(currentPhrase);
            currentPhrase = "";
        }

        i++;
    }

    // Final cleanup
    return result.filter(phrase => phrase.trim() !== "").map(phrase => phrase.trim());
};
const AISearchJsonGrid: React.FC<AISearchJsonGridProps> = ({ value, itmTitle }) => {
    const [gridData, setGridData] = useState(value || {});

    console.log("VV", value);
    let itmOrd = ["circumstances", "topics", "sourcepage", "id"];
    const sortObjectByKeyOrder = (obj: any, keyOrder: string[]) => {
        return Object.fromEntries(
            Object.entries(obj).sort(([a], [b]) => {
                return keyOrder.indexOf(b) - keyOrder.indexOf(a);
            })
        );
    };
    console.log("AIS_GRD¦", gridData);
    if (gridData && gridData.value) {
        setTimeout(() => {
            applyHeatmapColors();
        }, 1000);
    }
    const renderGrid = (data: any, level = 0) => {
        return Object.entries(sortObjectByKeyOrder(data, itmOrd))
            .filter(([key, value]) => key !== "@search.score")
            .map(([key, value]) => (
                <div key={key} className={`grid-row_k level-${level}_k`}>
                    <div
                        className="grid-cell_k key_k"
                        style={{
                            font: "Urbanist, Roboto",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "230px"
                        }}
                    >
                        {key.replace(/_/g, " ").replace(/\b\w/g, char => char.toUpperCase())}
                    </div>
                    <div className="grid-cell_k value_k">
                        {typeof value === "object" && value !== null ? (
                            Array.isArray(value) ? (
                                <div
                                    className="array-container_k"
                                    style={{
                                        font: "Urbanist, Roboto",
                                        paddingTop: "5px",
                                        paddingBottom: "5px"
                                    }}
                                >
                                    {value.map((item, index) => (
                                        <div
                                            key={index}
                                            className="array-item_k"
                                            style={{
                                                font: "Urbanist, Roboto",
                                                marginTop: "8px",
                                                marginBottom: "8px",
                                                borderLeft: "5px solid #f07e11",
                                                borderTop: "1px solid #f07e11"
                                            }}
                                        >
                                            {renderGrid(item, level + 1)}
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                renderGrid(value, level + 1)
                            )
                        ) : (
                            <div>
                                {key === "topics" || key === "circumstances"
                                    ? String(value)
                                          .split(";")
                                          .map((ent, index) => (
                                              <div className="grid-entity-value_k">
                                                  {splitAndCombineShortWords(ent).map((val, index) => (
                                                      <div className="aisrchval" data-heatmap={val}>
                                                          {`${val} `}
                                                      </div>
                                                  ))}
                                              </div>
                                          ))
                                    : String(value)}
                            </div>
                        )}
                    </div>
                </div>
            ));
    };

    return (
        <div
            style={{
                font: "Urbanist, Roboto",
                padding: "5px",
                borderRadius: "5px",
                margin: "5px"
            }}
        >
            <div
                style={{
                    font: "Urbanist, Roboto",
                    fontSize: "18px",
                    fontWeight: "600",
                    padding: "3px",
                    whiteSpace: "normal",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "100%"
                }}
            >
                {itmTitle}
            </div>

            {gridData && (
                <div style={{ font: "Urbanist, Roboto" }} className="json-grid_k">
                    {renderGrid(gridData?.value)}
                </div>
            )}
        </div>
    );
};

export default AISearchJsonGrid;
