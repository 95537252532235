import React, { useState, useRef } from "react";
import { LuThumbsDown, LuThumbsUp } from "react-icons/lu";
import styles from "./FeedbackComponent.module.css";
import { fontFace, mergeStyleSets } from "@fluentui/react/lib/Styling";
import { Callout, TextField, ITextFieldStyles, DirectionalHint, PrimaryButton } from "@fluentui/react";
import { gfeedback } from "../../interfaces";
const calloutStyles = mergeStyleSets({
    container: {
        position: "relative",
        display: "inline-block"
    },
    triggerText: {
        cursor: "pointer"
    },
    callout: {
        position: "absolute",
        top: "100%",
        left: "50%",
        transform: "translateX(-50%)",
        backgroundColor: "#f8f8f8",
        border: "1px solid #ddd",

        borderRadius: "4px",
        boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
        display: "none",
        zIndex: 1,
        ":before": {
            content: '""',
            position: "absolute",
            top: "-10px",
            left: "50%",
            transform: "translateX(-50%)",
            border: "5px solid transparent",
            borderBottomColor: "#f8f8f8"
        }
    },
    hoverTrigger: {
        "&:hover + $callout": {
            display: "block"
        }
    }
});
interface feedbackProps {
    onSendFeedback: (prps: gfeedback) => void;
    interaction_guid: string;
    pos: number | null;
}

const FeedbackComponent: React.FC<feedbackProps> = ({ onSendFeedback, interaction_guid, pos }) => {
    const feedbackRef = useRef(null);
    const [showCallout, setShowCallout] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const [reason, setReason] = useState("");

    const handleThumbsUp = () => {
        const thumbsUpAction = () => {
            onSendFeedback({
                feedback: "",
                pos: 1,
                interaction_guid: interaction_guid
            });
        };
        thumbsUpAction();
    };

    const handleThumbsDown = () => {
        setShowCallout(true);
    };

    const handleSend = () => {
        const sendReason = () => {
            onSendFeedback({
                feedback: reason,
                pos: 0,
                interaction_guid: interaction_guid
            });
        };
        sendReason();
        setShowCallout(false);
        setReason("");
    };
    const textFieldStylesMultiline: Partial<ITextFieldStyles> = {
        wrapper: {
            selectors: {
                label: {
                    fontFamily: "Urbanist"
                }
            }
        },
        root: {
            borderRadius: "8px"
        },
        fieldGroup: {
            width: "400px",
            maxWidth: "800px",
            height: 100,
            borderRadius: "8px",
            selectors: {
                "::after": {
                    borderRadius: "8px ",
                    border: "2px solid #333"
                }
            }
        },
        subComponentStyles: {
            label: {
                root: {
                    fontSize: "12px",
                    fontFamily: "Urbanist"
                }
            }
        }
    };
    return (
        <div className={styles.thumbsContainer}>
            {pos == 1 ? (
                <div className={styles.feedbackThumbsUpGreen}>
                    <LuThumbsUp size={20} />
                </div>
            ) : (
                <div onClick={handleThumbsUp} className={styles.feedbackThumbsUp}>
                    <LuThumbsUp size={20} />
                </div>
            )}
            {pos == 0 ? (
                <div className={styles.feedbackThumbsDownRed} ref={feedbackRef}>
                    <LuThumbsDown size={20} />
                </div>
            ) : (
                <div onClick={handleThumbsDown} className={styles.feedbackThumbsDown} ref={feedbackRef}>
                    <LuThumbsDown size={20} />
                </div>
            )}

            <Callout
                gapSpace={0}
                styles={calloutStyles}
                target={feedbackRef.current}
                onDismiss={() => setShowCallout(false)}
                setInitialFocus
                directionalHint={DirectionalHint.leftTopEdge}
                className={styles.calloutMain}
                hidden={!showCallout}
            >
                <div className={styles.thumbsDownReasonCallout}>
                    <TextField
                        className={styles.spaceTextFields}
                        styles={textFieldStylesMultiline}
                        label="Please help us improve by providing feedback"
                        multiline
                        resizable={false}
                        value={reason}
                        onChange={(_, newValue) => setReason(newValue || "")}
                    />

                    <PrimaryButton
                        text="Save"
                        onClick={() => {
                            handleSend();
                        }}
                        disabled={isSending}
                        className={styles.feedbackSaveButton}
                    />
                </div>
            </Callout>
        </div>
    );
};

export default FeedbackComponent;
