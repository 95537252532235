import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import styles from "./CollapsibleSections.module.css";

interface SectionData {
    section: string;
    summary: string;
}

interface CollapsibleSectionsProps {
    data: SectionData[];
}

const CollapsibleSections: React.FC<CollapsibleSectionsProps> = ({ data }) => {
    const [openSections, setOpenSections] = useState<Record<string, boolean>>({});

    useEffect(() => {
        const initialOpenSections = data.reduce(
            (acc, item) => {
                acc[item.section] = true;
                return acc;
            },
            {} as Record<string, boolean>
        );
        setOpenSections(initialOpenSections);
    }, [data]);

    const toggleSection = (section: string) => {
        setOpenSections(prev => ({
            ...prev,
            [section]: !prev[section]
        }));
    };

    const getFirstLine = (text: string): string => {
        const firstLineMatch = text.match(/^.*$/m);
        return firstLineMatch ? firstLineMatch[0] : "";
    };

    return (
        <div className={styles.collapsibleSections}>
            {data.map((item, index) => (
                <div key={index} className={styles.section}>
                    <button className={styles.sectionHeader} onClick={() => toggleSection(item.section)}>
                        {item.section}
                        <span className={`${styles.arrow} ${openSections[item.section] ? styles.arrowOpen : ""}`}>▼</span>
                    </button>
                    {openSections[item.section] ? (
                        <div className={styles.sectionContent}>
                            <ReactMarkdown remarkPlugins={[remarkGfm]}>{item.summary}</ReactMarkdown>
                        </div>
                    ) : (
                        <div className={styles.sectionPreview}>
                            <ReactMarkdown remarkPlugins={[remarkGfm]}>{getFirstLine(item.summary)}</ReactMarkdown>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default CollapsibleSections;
