import { Delete24Regular, New24Regular, List24Regular, FormNew24Regular } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";
import { FiPlusCircle, FiSettings, FiEdit } from "react-icons/fi";
import styles from "./TogglePanelButton.module.css";
import { TooltipHost, DirectionalHint } from "@fluentui/react";
interface Props {
    className?: string;
    onClick: () => void;
    disabled?: boolean;
    text: string;
    iconType: React.ElementType;
    tooltipText?: string;
}
export const TogglePanelButton = ({ className, disabled, onClick, text, iconType: Icon, tooltipText }: Props) => {
    return (
        <div className={`${styles.container} ${className ?? ""}`}>
            {tooltipText != null ? (
                <TooltipHost content={tooltipText} directionalHint={DirectionalHint.topAutoEdge}>
                    <Button icon={<Icon color="#333333" fontSize="18" />} disabled={disabled} onClick={onClick}>
                        {text}
                    </Button>
                </TooltipHost>
            ) : (
                <Button icon={<Icon color="#333333" fontSize="18" />} disabled={disabled} onClick={onClick}>
                    {text}
                </Button>
            )}
        </div>
    );
};
