import React, { useState, useEffect } from "react";
import { TextField, Label, Stack, IconButton, ITextFieldStyles } from "@fluentui/react";
import styles from "./InstructTagInput.module.css";
import { gcustominvoiceinstructions } from "../../interfaces";

interface InstructTagInputProps {
    onChange: (tag: gcustominvoiceinstructions, isAdd: boolean) => void;
    initialTags?: gcustominvoiceinstructions[];
    field: string;
    groupid: string;
    userid: string;
    contractor_id: string;
}
const textFieldStyles: Partial<ITextFieldStyles> = {
    wrapper: {
        selectors: {
            label: {
                fontFamily: "Urbanist"
            }
        }
    },
    root: {
        borderRadius: "5px"
    },
    fieldGroup: {
        maxWidth: "800px",
        borderRadius: "5px",
        selectors: {
            "::after": {
                borderRadius: "5px ",
                border: "2px solid #404040"
            }
        }
    },
    subComponentStyles: {
        label: {
            root: {
                fontSize: "14px",
                fontFamily: "Urbanist"
            }
        }
    }
};
const InstructTagInput: React.FC<InstructTagInputProps> = ({ onChange, initialTags, field, groupid, userid, contractor_id }) => {
    const [tags, setTags] = useState<gcustominvoiceinstructions[]>(initialTags || []);
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        setTags(initialTags || []);
    }, [initialTags]);

    const handleAddTag = () => {
        if (inputValue.trim()) {
            const newTag = {
                id: 0,
                groupid: groupid,
                contractorid: contractor_id,
                fieldname: field,
                instruction: inputValue.trim(),
                userid: userid
            };
            const newTags = [...(tags || []), newTag];
            setTags(newTags);
            onChange(newTag, true);
            setInputValue("");
        }
    };

    const handleInputChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setInputValue(newValue || "");
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            handleAddTag();
            event.preventDefault();
        }
    };

    const handleRemoveTag = (index: number) => {
        const newTags = tags.filter((_, i) => i !== index);
        const removeTag = tags.filter((_, i) => i == index);
        console.log("Z", removeTag[0]);
        setTags(newTags);
        onChange(removeTag[0], false);
    };

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack horizontal tokens={{ childrenGap: 5 }} wrap className={styles.mainStack}>
                {tags &&
                    tags.length > 0 &&
                    tags?.map((tag, index) => (
                        <Stack horizontal key={index} tokens={{ childrenGap: 5 }} verticalAlign="center" className={styles.tagListStack}>
                            <Stack.Item className={styles.labelAndDelBut}>
                                <Label className={styles.tagLab}>{tag.instruction}</Label>
                                <IconButton
                                    iconProps={{ iconName: "Cancel" }}
                                    title="Remove"
                                    onClick={() => handleRemoveTag(index)}
                                    styles={{
                                        root: {
                                            width: 16, // Set desired width
                                            height: 16, // Set desired height
                                            borderRadius: 3, // Set border radius (e.g., 5px)
                                            marginRight: 5, // Set margin between icon and label (e.g., 5px
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            paddingTop: "1px" // Set padding (e.g., 0px)
                                        },
                                        icon: {
                                            fontSize: 15 // Set icon size (e.g., 16px)
                                        }
                                    }}
                                />
                            </Stack.Item>
                        </Stack>
                    ))}
            </Stack>
            <TextField
                placeholder="Add Instruction and press Enter to save directly"
                value={inputValue}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                className={styles.qTagInput}
                styles={textFieldStyles}
            />
        </Stack>
    );
};

export default InstructTagInput;
