import { Modal, TextField, PrimaryButton } from "@fluentui/react";
import styles from "./InviteModal.module.css"; // Assuming you have a CSS module for this component

interface InviteModalProps {
    isInviteModalOpen: boolean;
    closeInviteModal: () => void;
    inviteUser: () => void;
    setNewInviteUser: (value: any) => void; // Replace 'any' with the type of your state
}

const InviteModal: React.FC<InviteModalProps> = ({ isInviteModalOpen, closeInviteModal, inviteUser, setNewInviteUser }) => {
    return (
        <Modal isOpen={isInviteModalOpen} onDismiss={closeInviteModal} isBlocking={false} containerClassName={styles.spaceEditModal}>
            <div>
                <h3>Invite Another Person</h3>
                <TextField
                    className={styles.spaceTextFields}
                    label="User Email"
                    onChange={(event, newValue) => {
                        //console.log("ES¦CHNG", newValue);
                        newValue
                            ? setNewInviteUser((prevState: { userMail: String; userDisplayName: string }) => ({
                                  ...prevState,
                                  userMail: newValue
                              }))
                            : "";
                    }}
                />
                <TextField
                    className={styles.spaceTextFields}
                    label="User Display Name"
                    onChange={(event, newValue) => {
                        //console.log("ES¦CHNG", newValue);
                        newValue
                            ? setNewInviteUser((prevState: { userMail: String; userDisplayName: string }) => ({
                                  ...prevState,
                                  userDisplayName: newValue
                              }))
                            : "";
                    }}
                />
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <PrimaryButton
                        text="Invite"
                        className={styles.spacePickerSave}
                        onClick={async () => {
                            inviteUser();
                            closeInviteModal();
                        }}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default InviteModal;
