import { Delete24Regular, Edit24Regular } from "@fluentui/react-icons";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit3 } from "react-icons/fi";
import { Button } from "@fluentui/react-components";
import { IButtonStyles } from "@fluentui/react";
import styles from "./ListItemButton.module.css";
import React, { useState } from "react";
import { toast } from "react-toastify";

interface Props {
    className?: string;
    onClick: () => void;
    onDelete: () => void;
    onEdit: (newText: string) => void;
    disabled?: boolean;
    text: string;
}

export const ListItemButton = ({ className, disabled, onClick, onDelete, onEdit, text }: Props) => {
    const [isEditing, setIsEditing] = useState(false);
    const [newText, setNewText] = useState(text);
    const [isDeleting, setIsDeleting] = useState(false);
    const handleEditClick = () => {
        if (isEditing) {
            if (newText !== text && newText != "") {
                onEdit(newText);
            } else {
                if (newText == "") {
                    toast.error("Conversation Name can't be Empty. Changes won't be saved.");
                }
            }
        }
        setIsEditing(!isEditing);
    };

    const handleDeleteClick = () => {
        setIsDeleting(true);
        setNewText("Deleting...");
        onDelete();
    };
    const buttonStyles: IButtonStyles = {
        root: { justifyContent: "flex-start" }
    };
    return (
        <div className={`${styles.container} ${className ?? ""}`}>
            <Button
                style={{ textAlign: "left", justifyContent: "flex-start", width: "100%", margin: "5px", padding: "5px" }}
                disabled={disabled}
                onClick={() => (isEditing == false ? onClick() : null)}
            >
                {isEditing ? (
                    <input
                        style={{ padding: "5px", width: "100%", borderRadius: "5px", borderWidth: "1px", borderColor: "#333" }}
                        type="text"
                        value={newText}
                        onChange={e => setNewText(e.target.value)}
                        onBlur={handleEditClick}
                        autoFocus
                        onKeyDown={e => {
                            if (e.key === "Enter") {
                                handleEditClick();
                            }
                        }}
                    />
                ) : (
                    newText
                )}
            </Button>
            {!isDeleting && <Button className={styles.histEditBut} icon={<FiEdit3 />} onClick={handleEditClick} title="Edit Conversation Name" />}
            {!isDeleting && <Button className={styles.histDeleteBut} icon={<AiOutlineDelete />} onClick={handleDeleteClick} title="Delete Conversation" />}
        </div>
    );
};
