﻿import React, { useState, useRef, useEffect } from "react";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import { Spinner, Dropdown, IDropdownOption, TextField, TooltipHost, DirectionalHint, TooltipDelay } from "@fluentui/react";
import { IoMicOutline, IoMicOffOutline, IoTrashOutline } from "react-icons/io5";
import styles from "./Recognizers.module.css";
import axios from "axios";
import {
    dropdownStyles,
    textFieldStylesMultilineRecognizer,
    textFieldStylesPersonLabelRecognizer,
    titleTextFieldStylesPersonLabelRecognizer
} from "../../styles_glob";
import { MdDeleteOutline } from "react-icons/md";
import { gselectedgroup, gloggedinuser, gtranscriptentry, gtranscriptobject, gtranscriptpublish } from "../../interfaces";
import { saveTranscriptionDraft, getTranscriptionDrafts, deleteTranscriptionDraft } from "../../api";
import { BiRename } from "react-icons/bi";
import { MdPublish } from "react-icons/md";
import { getTranscriptFormattedDate, getNewTranscriptItemKey, prepWavBuffer, handleFocus } from "../../util_glob";
import { transcriptLanguageOptions } from "../../lsts";

interface ConversationRecognizerProps {
    selectedGroup: gselectedgroup;
    loggedInUser: gloggedinuser;
    publishTranscriptDraftCallback: (transcriptObject: gtranscriptpublish) => void;
    activeFile?: any; // Will recieve file if sent from Details List to show specific transcript
    onSTT: (text: string, language: string) => void;
}

const ConversationRecognizer: React.FC<ConversationRecognizerProps> = ({ selectedGroup, loggedInUser, publishTranscriptDraftCallback, activeFile, onSTT }) => {
    const [transcript, setTranscript] = useState<gtranscriptentry[]>(activeFile?.transcription || []); // Array of convo turns
    const [transcriptDraft, setTranscriptDraft] = useState<gtranscriptobject>(); // Current Transcript Draft
    const [transcriptDrafts, setTranscriptDrafts] = useState<gtranscriptobject[]>([]); // Array of Transcript Drafts

    const [transcriptname, setTranscriptname] = useState(activeFile ? activeFile.transcriptname : `New Transcript ${getTranscriptFormattedDate(new Date())}`);
    const [itemKey, setItemKey] = useState<string>(activeFile?.transcriptionkey ? activeFile.transcriptionkey : getNewTranscriptItemKey(new Date()));

    const [recognizing, setRecognizing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isEditingTranscriptName, setIsEditingTranscriptName] = useState(false);
    const [partialResult, setPartialResult] = useState<{ speakerId: string; speakerName: string; text: string } | null>(null);

    const [selectedLanguage, setSelectedLanguage] = useState<string>("en-US");
    const [useWhisper, setUseWhisper] = useState<boolean>(false);

    const speakerMappingRef = useRef<{ [key: string]: string }>({});
    const transcriberRef = useRef<sdk.ConversationTranscriber | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    const isRecognisingRef = useRef(false);
    const audioContextRef = useRef<AudioContext | null>(null);
    const processorRef = useRef<ScriptProcessorNode | null>(null);
    const audioChunksRef = useRef<Float32Array[]>([]);

    useEffect(() => {
        console.log("transcriptDraft", transcriptDraft);
    }, [transcriptDraft]);
    useEffect(() => {
        if (transcriptDrafts && transcriptDrafts.length > 0 && activeFile?.transcriptionkey) setItemKey(activeFile.transcriptionkey);
    }, [transcriptDrafts]);
    useEffect(() => {
        setTranscriptDraftFromArray(transcriptDraft);
    }, [transcript]);
    useEffect(() => {
        loadTranscriptionDrafts();
    }, [loggedInUser]);
    useEffect(() => {
        return () => {
            if (transcriptDraft?.content?.length || 0 > 0) saveTranscriptionDraft(selectedGroup, loggedInUser, transcript, itemKey, transcriptname);

            if (transcriberRef.current) {
                transcriberRef.current.stopTranscribingAsync(
                    () => {
                        transcriberRef?.current?.close();
                        transcriberRef.current = null;
                    },
                    err => console.error("error stopping transcription:", err)
                );
            }
        };
    }, []);

    const startMultiSpeakerRecognition = async (language?: string) => {
        if (transcriberRef.current) {
            stopAudioCapture();
            transcriberRef.current.stopTranscribingAsync(
                () => {
                    transcriberRef.current?.close();
                    transcriberRef.current = null;
                    initializeTranscriber(language || selectedLanguage);
                },
                err => console.error("Error stopping transcription:", err)
            );
        } else {
            initializeTranscriber(language || selectedLanguage);
        }
    };
    const initializeVisualizerAndAudioCapture = async () => {
        try {
            navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
                audioContextRef.current = new AudioContext();
                const sourceNode = audioContextRef.current.createMediaStreamSource(stream);

                if (useWhisper == true) {
                    processorRef.current = audioContextRef.current.createScriptProcessor(4096, 1, 1);
                    processorRef.current.connect(audioContextRef.current.destination);
                    processorRef.current.onaudioprocess = e => {
                        const inputData = e.inputBuffer.getChannelData(0);
                        audioChunksRef.current.push(new Float32Array(inputData));
                    };
                    sourceNode.connect(processorRef.current);
                }

                const analyserNode = audioContextRef.current.createAnalyser();
                analyserNode.fftSize = 64;
                sourceNode.connect(analyserNode);
                const canvas = canvasRef.current;
                if (canvas) {
                    const canvasCtx = canvas.getContext("2d");
                    const bufferLength = analyserNode.frequencyBinCount;
                    const dataArray = new Uint8Array(bufferLength);
                    let barHeights = new Array(bufferLength).fill(0);
                    const drawEqualizer = () => {
                        analyserNode.getByteFrequencyData(dataArray);
                        if (!canvasCtx) return;
                        canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
                        dataArray.slice(0, 10).forEach((value, i) => {
                            const targetHeight = (value / 255) * canvas.height;
                            barHeights[i] = barHeights[i] + (targetHeight - barHeights[i]) * 0.3; // Easing

                            const barWidth = canvas.width / 10;
                            const gradient = canvasCtx.createLinearGradient(0, canvas.height - barHeights[i], 0, canvas.height);

                            gradient.addColorStop(0, "rgba(255, 69, 0, 1)"); // Red-orange/Dark orange
                            gradient.addColorStop(1, "rgba(255, 140, 0, 1)"); // Bright orange

                            canvasCtx.fillStyle = gradient;
                            canvasCtx.fillRect(i * barWidth, canvas.height - barHeights[i], barWidth, barHeights[i]);
                        });
                        if (isRecognisingRef.current) {
                            requestAnimationFrame(drawEqualizer);
                        } else {
                            canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
                            stream.getTracks().forEach(track => track.stop());
                        }
                    };

                    drawEqualizer();
                }
            });
        } catch (error) {
            console.error("error_initializing_visualizer:", error);
        }
    };
    const updateTranscriptionState = (spokenText: string, speakerId: string, speakerName: string, whsprText?: string) => {
        let textToPreserve = whsprText || spokenText;
        setTranscript(prevTranscript => {
            if (prevTranscript.length > 0) {
                const lastEntryIndex = prevTranscript.length - 1;
                const lastEntry = prevTranscript[lastEntryIndex];
                if (lastEntry.speakerId === speakerId) {
                    const updatedEntry = {
                        ...lastEntry,
                        text: lastEntry.text + " " + textToPreserve
                    };
                    if (lastEntry.isEditing) updatedEntry.editText = (lastEntry.editText || lastEntry.text) + " " + textToPreserve;
                    const newTranscript = [...prevTranscript];
                    newTranscript[lastEntryIndex] = updatedEntry;
                    return newTranscript;
                } else {
                    return [...prevTranscript, { speakerId, speakerName, text: textToPreserve, isEditing: false, isRenaming: false }];
                }
            } else {
                return [{ speakerId, speakerName, text: textToPreserve, isEditing: false, isRenaming: false }];
            }
        });
    };
    const initializeTranscriber = (language: string) => {
        setIsLoading(true);
        const k = import.meta.env.VITE_STT_K;
        const r = import.meta.env.VITE_STT_R;
        isRecognisingRef.current = true;

        const speechConfig = sdk.SpeechConfig.fromSubscription(k, r);
        const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
        speechConfig.speechRecognitionLanguage = language;

        const transcriber = new sdk.ConversationTranscriber(speechConfig, audioConfig);
        transcriberRef.current = transcriber;

        transcriber.sessionStarted = () => {
            console.log("stt_session_started");
            setRecognizing(true);
            setIsLoading(false);
        };
        transcriber.transcribing = (s, e) => {
            const speakerId = e.result.speakerId || "Speech";
            const speakerName = speakerMappingRef.current[speakerId] || speakerId;
            const spokenText = e.result.text || "";
            setPartialResult({ speakerId, speakerName, text: spokenText });
        };
        transcriber.transcribed = (s, e) => {
            if (e.result.reason === sdk.ResultReason.RecognizedSpeech) {
                const speakerId = e.result.speakerId || "Speech";
                const speakerName = speakerMappingRef.current[speakerId] || speakerId;
                const spokenText = e.result.text || "";
                if (spokenText) {
                    if (useWhisper == true) {
                        sendAudioToWhisper(audioChunksRef.current, e.result, language)
                            .then(res => {
                                updateTranscriptionState(spokenText, speakerId, speakerName, (res as any).data.text);
                            })
                            .catch(err => {
                                updateTranscriptionState(spokenText, speakerId, speakerName);
                            });
                    } else {
                        updateTranscriptionState(spokenText, speakerId, speakerName);
                    }
                }
            }
            setPartialResult(null);
        };
        transcriber.canceled = (s, e) => {
            console.error(`Recognition canceled: ${e.reason}`);
            setRecognizing(false);
            stopAudioCapture();
            setIsLoading(false);
            transcriber.close();
            transcriberRef.current = null;
        };
        transcriber.sessionStopped = () => {
            setRecognizing(false);
            setIsLoading(false);
            transcriber.close();
            transcriberRef.current = null;
        };
        transcriber.startTranscribingAsync(
            () => console.log("stt_start"),
            err => {
                console.error("error_stt_start", err);
                setIsLoading(false);
            }
        );
        initializeVisualizerAndAudioCapture();
    };
    const stopRecognition = () => {
        setRecognizing(false);
        setIsLoading(false);
        stopAudioCapture();
        isRecognisingRef.current = false;
        if (transcriberRef.current) {
            transcriberRef.current.stopTranscribingAsync(
                () => {
                    console.log("Transcription stopped.");
                    transcriberRef.current?.close();
                    transcriberRef.current = null;
                },
                err => console.error("Error stopping transcription:", err)
            );
        }
        setPartialResult(null);
    };
    const stopAudioCapture = () => {
        audioChunksRef.current = [];
        if (audioContextRef.current) {
            audioContextRef.current.close();
            audioContextRef.current = null;
        }
        if (processorRef.current) {
            processorRef.current.disconnect();
            processorRef.current = null;
        }
    };
    const toggleRecognition = () => {
        if (recognizing) {
            stopRecognition();
        } else {
            startMultiSpeakerRecognition();
        }
    };
    const onLanguageChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (option) {
            setSelectedLanguage(option.key as string);
            startMultiSpeakerRecognition(option.key as string);
        }
    };
    const renameSpeaker = (speakerId: string, newName: string) => {
        speakerMappingRef.current[speakerId] = newName || speakerId;
        setTranscript(prevTranscript => prevTranscript.map(entry => (entry.speakerId === speakerId ? { ...entry, speakerName: newName || speakerId } : entry)));
        setPartialResult(prevPartialResult =>
            prevPartialResult && prevPartialResult.speakerId === speakerId ? { ...prevPartialResult, speakerName: newName || speakerId } : prevPartialResult
        );
    };
    const deleteTurn = (index: number) => {
        setTranscript(prevTranscript => prevTranscript.filter((_, idx) => idx !== index));
    };
    const loadTranscriptionDrafts = async () => {
        const drafts = await getTranscriptionDrafts(selectedGroup, loggedInUser, activeFile?.transcriptionkey || "");
        setTranscriptDrafts(drafts);
    };
    const handlePublishDraft = () => {
        publishTranscriptDraftCallback({
            selectedGroup: selectedGroup,
            loggedInUser: loggedInUser,
            transcript: transcriptDraft?.content || [],
            transcriptKey: transcriptDraft?.key?.toString() || "",
            transcriptName: transcriptname
        });
    };
    const sendAudioToWhisper = async (audioChunks: Float32Array[], recognitionResult: sdk.ConversationTranscriptionResult, language: string) => {
        const e = import.meta.env.VITE_WHIS_E;
        const k = import.meta.env.VITE_WHIS_K;
        const o = import.meta.env.VITE_WHIS_O;

        const wavBuffer = prepWavBuffer(audioChunks, recognitionResult.offset);

        console.log("L", language.split("-")[0]);

        const wavBlobb = new Blob([wavBuffer], { type: "audio/wav" });
        const formData = new FormData();
        formData.append("model", "whisper-1");
        formData.append("language", language.split("-")[0]);
        formData.append("file", wavBlobb, "audio.wav");
        try {
            const response = await axios.post(`${e}`, formData, {
                headers: {
                    Authorization: `Bearer ${k}`,
                    "Content-Type": "multipart/form-data",
                    "OpenAI-Organization": `${o}`
                },
                params: { response_format: "json" }
            });
            return response;
        } catch (error) {
            console.error("Error sending audio to Whisper:", error);
            if (axios.isAxiosError(error) && error.response) {
                console.error("res_data:", error.response.data);
                console.error("res_stts:", error.response.status);
                console.error("res_hdrs:", error.response.headers);
            }
            return error;
        }
    };
    const setTranscriptDraftFromArray = (draft: gtranscriptobject | undefined) => {
        if (draft) {
            let tR = transcriptDrafts.find(draft => draft?.key.toString() == itemKey);
            if (tR) {
                tR.content = transcript;
                setTranscriptDraft(tR);
                saveTranscriptionDraft(selectedGroup, loggedInUser, transcript, tR?.key?.toString() || itemKey, tR?.transcriptname || transcriptname);
            } else {
                setTranscriptDraft({
                    content: transcript,
                    groupid: selectedGroup.selectionId,
                    userid: loggedInUser.userId,
                    key: parseInt(itemKey),
                    transcriptname: transcriptname
                });
                saveTranscriptionDraft(selectedGroup, loggedInUser, transcript, itemKey.toString() || itemKey, transcriptname || transcriptname);
            }
        } else {
            if (transcript && transcript.length > 0) {
                console.log("DR03", itemKey);
                setTranscriptDraft({
                    content: transcript,
                    groupid: selectedGroup.selectionId,
                    userid: loggedInUser.userId,
                    key: parseInt(itemKey),
                    transcriptname: transcriptname
                });
                saveTranscriptionDraft(selectedGroup, loggedInUser, transcript, itemKey.toString() || itemKey, transcriptname || transcriptname);
            } else {
                setTranscriptDraft({
                    content: transcript,
                    groupid: selectedGroup.selectionId,
                    userid: loggedInUser.userId,
                    key: parseInt(itemKey),
                    transcriptname: transcriptname
                });
            }
        }
    };
    return (
        <div className={styles.recMainContainer}>
            <div className={styles.recTitleAndDraftDrop}>
                <div className={styles.recTitleAndButtonsLeft}>
                    {isEditingTranscriptName ? (
                        <TextField
                            style={{ font: "normal normal normal 20px/20px Urbanist,Roboto", width: "420px" }}
                            value={transcriptname}
                            className={styles.recTitleEditTextField}
                            onChange={(e, newValue) => {
                                setTranscriptname(newValue || "");
                                setTranscriptDraft(transcriptDrafts.find(draft => draft?.key.toString() == itemKey));
                            }}
                            onBlur={() => {
                                console.log("transcriptname", transcriptname);
                                saveTranscriptionDraft(
                                    selectedGroup,
                                    loggedInUser,
                                    transcript,
                                    transcriptDraft?.key?.toString() || itemKey,
                                    transcriptname
                                ).then(() => {
                                    loadTranscriptionDrafts();
                                    setIsEditingTranscriptName(false);
                                });
                            }}
                            autoFocus
                            styles={titleTextFieldStylesPersonLabelRecognizer}
                        />
                    ) : (
                        <div className={styles.recTitle}>{transcriptname}</div>
                    )}

                    {activeFile ? (
                        <TooltipHost content="Cannot Rename published files" directionalHint={DirectionalHint.bottomCenter} delay={TooltipDelay.medium}>
                            <div className={styles.recTitleEditIconDisabled}>
                                <BiRename size={25} />
                            </div>
                        </TooltipHost>
                    ) : (
                        <TooltipHost content="Rename Draft" directionalHint={DirectionalHint.bottomCenter} delay={TooltipDelay.zero}>
                            <div className={styles.recTitleEditIcon} onClick={() => setIsEditingTranscriptName(true)}>
                                <BiRename size={25} />
                            </div>
                        </TooltipHost>
                    )}

                    <TooltipHost content="Publish Draft as Document in Content List" directionalHint={DirectionalHint.bottomCenter} delay={TooltipDelay.zero}>
                        {transcriptDraft && (
                            <div className={styles.recTitleEditIcon} onClick={() => handlePublishDraft()}>
                                <MdPublish size={25} />
                            </div>
                        )}
                    </TooltipHost>
                    <TooltipHost content="Delete Draft" directionalHint={DirectionalHint.bottomCenter} delay={TooltipDelay.zero}>
                        {transcriptDraft ? (
                            <div
                                className={styles.recTitleDelIcon}
                                onClick={() => {
                                    deleteTranscriptionDraft(selectedGroup, loggedInUser, transcriptDraft.key?.toString()).then(() => {
                                        loadTranscriptionDrafts();
                                    });
                                }}
                            >
                                <MdDeleteOutline size={26} />
                            </div>
                        ) : (
                            <div className={styles.recTitleDelIconDisabled}>
                                <MdDeleteOutline size={26} />
                            </div>
                        )}
                    </TooltipHost>
                </div>

                {transcriptDrafts && transcriptDrafts.length > 0 && itemKey && !activeFile && (
                    <Dropdown
                        className={styles.recDraftDropdown}
                        placeholder="Select Previous Draft..."
                        onChange={(e, option) => {
                            setItemKey(option?.key?.toString() || "");
                            console.log("Option:", option);
                            transcriptDrafts.forEach(draft => {
                                if (draft.key.toString() == option?.key.toString()) {
                                    console.log("Draft:", draft);
                                    setTranscript(draft.content);
                                    setTranscriptname(draft.transcriptname);
                                }
                            });
                        }}
                        selectedKey={activeFile?.transcriptionkey ? activeFile?.transcriptionkey : itemKey}
                        options={transcriptDrafts.map(draft => ({ key: draft.key.toString(), text: draft.transcriptname.toString() }))}
                        styles={dropdownStyles}
                    />
                )}
            </div>

            <div className={styles.recTurnsContainer}>
                {transcript.map((entry, index) => (
                    <div key={index} style={{ display: "flex", alignItems: "center" }} className={styles.recTurnContainer}>
                        {entry.isRenaming ? (
                            <TextField
                                value={entry.speakerName}
                                onChange={(e, newValue) => {
                                    const newName = newValue || entry.speakerId;
                                    renameSpeaker(entry.speakerId, newName);
                                }}
                                onBlur={() => {
                                    setTranscript(prevTranscript => prevTranscript.map((ent, idx) => (idx === index ? { ...ent, isRenaming: false } : ent)));
                                }}
                                autoFocus
                                styles={textFieldStylesPersonLabelRecognizer}
                            />
                        ) : (
                            <div
                                className={styles.recSpeakerLabel}
                                onClick={() => {
                                    setTranscript(prevTranscript => prevTranscript.map((ent, idx) => (idx === index ? { ...ent, isRenaming: true } : ent)));
                                }}
                            >
                                {entry.speakerName}:
                            </div>
                        )}
                        {entry.isEditing ? (
                            <TextField
                                value={entry.editText !== undefined ? entry.editText : entry.text}
                                styles={{ ...textFieldStylesMultilineRecognizer, root: { flexGrow: 1 } }}
                                autoAdjustHeight
                                onChange={(e, newValue) => {
                                    const newText = newValue || "";
                                    setTranscript(prevTranscript => prevTranscript.map((ent, idx) => (idx === index ? { ...ent, editText: newText } : ent)));
                                }}
                                onFocus={handleFocus}
                                onBlur={() => {
                                    setTranscript(prevTranscript =>
                                        prevTranscript.map((ent, idx) =>
                                            idx === index
                                                ? {
                                                      ...ent,
                                                      isEditing: false,
                                                      text: ent.editText || ent.text,
                                                      editText: undefined
                                                  }
                                                : ent
                                        )
                                    );
                                }}
                                autoFocus
                                multiline
                            />
                        ) : (
                            <div
                                className={styles.recTranscriptText}
                                style={{}}
                                onClick={() => {
                                    setTranscript(prevTranscript =>
                                        prevTranscript.map((ent, idx) => (idx === index ? { ...ent, isEditing: true, editText: ent.text } : ent))
                                    );
                                }}
                            >
                                {entry.text}
                            </div>
                        )}
                        {/* Delete Button */}
                        <div onClick={() => deleteTurn(index)} style={{ cursor: "pointer", marginLeft: "8px" }}>
                            <MdDeleteOutline size={20} />
                        </div>
                    </div>
                ))}
            </div>

            <div className={styles.recControls}>
                <canvas ref={canvasRef} className={styles.recEqCanvas} />
                {partialResult ? (
                    <div className={styles.recTranscribingResult}>
                        <strong>{partialResult.speakerName}:</strong> {partialResult.text}
                    </div>
                ) : !recognizing && !isLoading ? (
                    <div className={styles.recTranscribingPlaceholder}>Start Transcription...</div>
                ) : (
                    <div className={styles.recTranscribingPlaceholder}></div>
                )}
                {isLoading && <Spinner className={styles.recLoadingSpinner} />}

                {recognizing && !isLoading && (
                    <Dropdown
                        selectedKey={selectedLanguage}
                        className={styles.recLanguageDropdown}
                        onChange={onLanguageChange}
                        placeholder="Select a language"
                        options={transcriptLanguageOptions}
                        styles={dropdownStyles}
                    />
                )}

                {!isLoading &&
                    (recognizing ? (
                        <div onClick={toggleRecognition} className={styles.recIconToggle}>
                            <IoMicOffOutline size={30} />
                        </div>
                    ) : (
                        <div onClick={toggleRecognition} className={styles.recIconToggle}>
                            <IoMicOutline size={30} />
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default ConversationRecognizer;
