import React, { useContext, useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
    Checkbox,
    Panel,
    DefaultButton,
    PanelType,
    Modal,
    IconButton,
    ContextualMenu,
    IContextualMenuItem,
    Pivot,
    PivotItem,
    TooltipHost,
    DirectionalHint,
    ThemeProvider
} from "@fluentui/react";

import RModal from "react-modal";

import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";

import { FiUser } from "react-icons/fi";
import { MdArrowBackIos } from "react-icons/md";
import { IoShareSocialOutline } from "react-icons/io5";
import { LuPanelRightClose } from "react-icons/lu";
import { TbGridDots } from "react-icons/tb";
import { HiOutlineViewList } from "react-icons/hi";

import kgnfLogoOnly from "../../assets/kgnfLogoOnly.svg";
import blankLogoOnly from "../../assets/blankLogoOnly.png";
import kognifyBanner from "../../assets/kognifyBannerTextOnly.png";
import kognifyLogo from "../../assets/kognifyLogo.png";

import readNDJSONStream from "ndjson-readablestream";
import { AuthenticationResult } from "@azure/msal-browser";

import styles from "./Chat.module.css";
import {
    chatApi,
    RetrievalMode,
    ChatAppResponse,
    ChatAppResponseOrError,
    ChatAppRequest,
    ResponseMessage,
    ResponseChoice,
    qGetChatLog,
    qHistGetByKey,
    qHistGetRecent,
    qGetSuggestedQuestions,
    qHistGetLatest,
    qCodeChk,
    qUSp,
    qLi,
    qGetTiers,
    qHistGetList,
    qHistAdd,
    qGetCompFlatList,
    qGetGroupsMemberships,
    qGetCompanyUsers,
    qGetAllUsers,
    qGetGroups,
    qGetGroupLastModifiedFileDate,
    qAddMember,
    qHistEditName,
    qHistDelete
} from "../../api";

import { Answer, AnswerError, AnswerLoading, AnswerHi } from "../../components/Answer";
import NoSpacesCard from "../../components/NoSpacesCard/NoSpacesCard";
import { QuestionInput } from "../../components/QuestionInput";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { SettingsButton } from "../../components/SettingsButton";
import SpacePanel from "../../components/SpacePanel/SpacePanel";
import AddSpaceModal from "../../components/AddSpaceModal/AddSpaceModal";
import InviteUserCallout from "../../components/InviteUserCallout/InviteUserCallout";
import { ListItemButton } from "../../components/ListItemButton";
import SpaceEditForm from "../../components/SpaceEditForm/SpaceEditForm";
import AccountInfo from "../../components/AccountInfo/AccountInfo";
import { useMsal } from "./../../MsalContext";
import { loginRequest } from "../../authConfig";
import customTheme from "./../../theme/customTheme";

import { GlobalContext } from "../../GlobalContext";
import CompEditForm from "../../components/CompEditForm/CompEditForm";
import { DetailsListDocumentsExample } from "../../components/detailedList"; // Files List
import XplrrPDF from "../../components/XplrrPDF/XplrrPDF";

import XplrrPDFSideByChat from "../../components/XplrrPDFSideByChat/XplrrPDFSideByChat";
import FieldSelection from "../../components/FieldSelection/FieldSelection";

import UserList from "../../components/UserList/UserList";
import CompList from "../../components/CompList/CompList";
import GroupCardsList from "../../components/GroupCardsList/GroupCardsList";
import SpaceUserListV2 from "../../components/SpaceUserListV2/SpaceUserListV2";
import AnalyticsConvoList from "../../components/AnalyticsConvoList/AnalyticsConvoList";
import XplrrPDFDiff from "../../components/XplrrPDF/XplrrPDFDiff";

import { toast, ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import debounce from "lodash/debounce";
import {
    gfile,
    ggroup,
    gselectedgroup,
    gcompflat,
    ispdfmodalopen,
    gXplrrCitationChangeCallback,
    gChatlogItem,
    gConf,
    gnewgroup,
    gsuggestedquestions,
    gtiers,
    grecentconvosaccrossspaces,
    gfeedback,
    ispdfdiffmodalopen,
    gdiff
} from "../../interfaces";
import {
    defaultPromptTemplateAsInPythonBackend,
    defaultGreetingsMessage,
    msgLmtHit,
    defaultGreetingContent,
    answerCardHTML,
    noContentNoAccessGreetingContent
} from "../../lsts";
import {
    groupListToSelectedGroup,
    handleIsDark,
    getLatestModifiedFile,
    extractPageValue,
    extractFileIDValue,
    getTokenAccountID,
    getTokenMail,
    cleanFileIDString,
    checkWeAreInGeneralPlace
} from "./ChatUtil";
import { encodeHTML, makeValidHtmlId, shuffleArray, getLoc, genNewConvoKey, isElementVisible } from "./../../util_glob";

import { libPivotStyles } from "./../../styles_glob";
import { AnswerDocList } from "../../components/Answer/AnswerDocList";

const Chat = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { msalInstance, getToken } = useMsal();
    const [token, setToken] = useState<AuthenticationResult | null>();
    const {
        user,
        loggedInUser,
        fileList,
        groupList,
        selectedGroup,
        allUsrs,
        compMbrshps,
        activeComp,
        permissionLevels,
        setUser,
        setLoggedInUser,
        setFileList,
        setGroupList,
        setSelectedGroup,
        setCompUsrs,
        setAllUsrs,
        setCompMbrshps,
        setActiveComp,
        setSourceHighlighting
    } = useContext(GlobalContext);

    const [compFlatList, setCompFlatList] = useState<gcompflat[]>([]);
    const [fileCount, setFileCount] = useState<number>(0);

    const [excludeCategory, setExcludeCategory] = useState<string>("");
    const [includeCategory, setIncludeCategory] = useState<string>("");
    const [includeCategoryGroups, setIncludeCategoryGroups] = useState<string>("");

    const [greetingMessageHtml, setGreetingMessageHtml] = useState<string>("");

    const [isLib, setIsLib] = useState<string>("");
    const [lTiers, setLTiers] = useState<gtiers[]>();

    const lastQuestionRef = useRef<string>("");
    const answersLength = useRef<number>(0);
    const clickedCitation = useRef<any>(null);
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const xplrrMsgStrm = useRef<HTMLDivElement | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isStreaming, setIsStreaming] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();
    const [isShareCalloutVisible, setIsShareCalloutVisible] = useState<boolean>(false);
    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);
    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [accMenuItems, setAccMenuItems] = useState<IContextualMenuItem[]>([]);
    const [newSpacePyro, setNewSpacePyro] = useState<string>("");
    const [answers, setAnswers] = useState<[user: string, response: ChatAppResponse][]>([]);
    const [streamedAnswers, setStreamedAnswers] = useState<[user: string, response: ChatAppResponse][]>([]);

    const [xplrrChatVisible, setXplrrChatVisible] = useState<boolean>(false);
    const [isLeftPanelOpen, setIsLeftPanelOpen] = useState(false); // History Panel Open

    const [isSpaceModalOpen, setIsSpaceModalOpen] = useState(false); // New Space Modal Open
    const [isNewSpaceInfoBip, setIsNewSpaceInfoBip] = useState(false); // New Space Modal Open
    const [isSpaceEditModalOpen, setIsSpaceEditModalOpen] = useState(false); // Edit Space Modal Open
    const [isSpaceAnalyticsModalOpen, setIsSpaceAnalyticsModalOpen] = useState(false); // Edit Space Modal Open

    const [activeConvoKey, setActiveConvoKey] = useState<string>(""); // Active Conversation Key [Bigint yyyyMMddHHmmss]
    const [convos, setConvos] = useState<{ key: string; name: string }[]>([]); // Historic Convos

    const [aPanelTimeStamp, setAPanelTimeStamp] = useState(""); // Timestamp for Analysis Panel

    const [suggestedQuestions, setSuggestedQuestions] = useState<gsuggestedquestions[]>();
    const [recentConvosAccrosSpaces, setRecentConvosAccrosSpaces] = useState<grecentconvosaccrossspaces[]>();
    const addUserButtonRef = useRef<HTMLDivElement>(null);

    const [spaceChatLog, setSpaceChatLog] = useState<gChatlogItem[]>([]);

    const [isCancellingMidway, setIsCancellingMidway] = useState<boolean>(false);
    const [loadingChatModeSwitch, setLoadingChatModeSwitch] = useState(false); // Edit Space Modal Open
    const [isIframe, setIsIframe] = useState(false);
    const [shouldPlayAudio, setShouldPlayAudio] = useState<string>("");
    const redeemURLRef = useRef<string | null>(null);
    const redeemURLSecondPathRef = useRef<string | null>(null);
    const [isAccountMenuVisible, setIsAccountMenuVisible] = useState<boolean>(false);
    const [initialLoadComleted, setInitialLoadComleted] = useState<boolean>(false);

    const [isUserListModalOpen, setIsUserListModalOpen] = useState(false);
    const [isCompListModalOpen, setIsCompListModalOpen] = useState(false);

    const [initLoad, setInitLoad] = useState(false);
    const [noMembership, setNoMembership] = useState(false);
    const [noGroupsShared, setNoGroupsShared] = useState(false);

    const [isPDFModalOpen, setIsPDFModalOpen] = useState<ispdfmodalopen>({
        defaultFile: "",
        startPage: "1",
        isOpen: false,
        searchString: "",
        contentlist: [
            {
                fileid: "",
                srcPageNum: 0,
                srcText: "",
                destPageId: 0,
                destPageNum: 0
            }
        ],
        pagemap: {},
        fileId: "",
        refPageNum: "",
        isCitation: false,
        rndo: "",
        activeGroupId: "",
        fileList: [],
        fl: undefined
    });
    const [isPDFDiffModalOpen, setIsPDFDiffModalOpen] = useState<ispdfdiffmodalopen>({
        defaultFile1: "",
        defaultFile2: "",
        isOpen: false,
        searchString: "",
        fileId1: "",
        fileId2: "",
        refPageNum: "",
        isCitation: false,
        rndo: "",
        activeGroupId: "",
        fileList: [],
        fl1: undefined,
        fl2: undefined,
        diff: undefined
    });
    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
    const [isSpacePanelOpen, setIsSpacePanelOpen] = useState(false);
    const [config, setConfig] = useState<gConf>(
        JSON.parse(
            localStorage.getItem("configObj6") ||
                `{
                    "promptTemplate": "Assistant helps find answers to questions about information in the available documents. If there isn't enough information below, ask for more specific information.",
                    "retrieveCount": 5,
                    "retrievalMode": "hybrid",
                    "useSemanticRanker": true,
                    "shouldStream": true,
                    "useSemanticCaptions": false,
                    "searchOnly": false,
                    "useHighlightsAsSource": false,
                    "useSuggestFollowupQuestions": true,
                    "isDark": false,
                    "experimentalFeatures": false,
                    "showDocLibInChatTab": false,
                    "sourceHighlighting":false
                }`
        )
    );

    // Effect⇒[client] (INIT LOAD)
    useEffect(() => {
        if (!msalInstance) return;

        hndlIfrm();
        setInitLoad(true);
        if (initialLoadComleted === false) {
            console.log("KGNF¦INIT¦2111");

            applyLocalConfig();
            qGetUserInfo().then(userObj => {
                if (userObj === null) {
                    uMenuEmpty();
                    setNoMembership(true);
                    if (userObj === null) {
                        uMenuEmpty();
                        setNoMembership(true);
                        fetch(import.meta.env.VITE_EXT_SERV_ANIP)
                            .then(response => response.json())
                            .then(data => {
                                if (data && data.ip && data.ip != "") {
                                    qLi("land", `user_is_null ${data.ip}`, getHeader(), user?.userMail || "no_usermail");
                                } else {
                                    qLi("land", `user_is_null nip`, getHeader(), user?.userMail || "no_usermail");
                                }
                                return;
                            });
                    }
                    return;
                }

                getCompFlatList().then((cFlatList: gcompflat[]) => {
                    setInitialLoadComleted(true);
                    import.meta.env.DEV === true && console.log("UE¦qGtCmpFltLst", cFlatList, location);
                    qLi("land", `init_load`, getHeader(), userObj.userMail || user?.userMail || "no_usermail");
                    handlePathNavigation(cFlatList, userObj);
                });
            });
        }
        window.greetingMessageAddNewSpace = (param: string) => {
            if (isIframe == true) {
                toast.error("This feature is not available in the embedded version of the app.");
            } else {
                qUSp("dataEx", "1", getHeader());
            }
        };
        window.greetingMessageViewDocs = (param: string) => {
            if (isIframe == true) {
                toast.error("This feature is not available in the embedded version of the app.");
            } else {
                var currentPath = window.location.hash.split("/");
                currentPath[currentPath.length - 1] = "library";
                window.location.hash = currentPath.join("/");
                return false;
            }
        };
        getTiers();
    }, [msalInstance]);
    // Effect⇒[activeComp]
    useEffect(() => {
        if (activeComp.companyid !== "") {
            import.meta.env.DEV == true && console.log("active_comp", activeComp);

            uMenu(user, compFlatList, activeComp);
            getGroups().then((groupList: any) => {
                if (user && user.userId && user.userId !== "") {
                    let subpaths;
                    if (redeemURLSecondPathRef.current) {
                        let rU = redeemURLSecondPathRef.current;
                        subpaths = rU.split("/").filter(Boolean);
                        console.log("redeem_subpaths¦", subpaths);
                        redeemURLSecondPathRef.current = null;
                    } else {
                        subpaths = getLoc().split("/").filter(Boolean);
                    }
                    const groupPath = decodeURIComponent(subpaths[1]);
                    if (selectedGroup.selectionId !== groupPath || groupPath == undefined) {
                        if (groupList && groupList.length > 0) {
                            console.log("comp_groups > 0", groupPath);
                            let gr = groupList.filter((group: any) => group.groupid === groupPath)[0];
                            if (gr) {
                                setSelectedGroup(groupListToSelectedGroup(gr));
                            } else {
                                console.log("comp_groups > 0 > no_group");
                                if (groupPath) {
                                    navigate(`/${activeComp.companyid}`);
                                }
                            }
                        } else {
                            setNoGroupsShared(true);
                        }
                    }
                }
            });
            getCompanyUsers();
        }
    }, [activeComp]);
    //Effect⇒[selectedGroup] ON SPACE CHANGE
    useEffect(() => {
        if (selectedGroup.selectionId !== "") {
            setConvos([]);
            setLoadingChatModeSwitch(true);
            newConverson();
            import.meta.env.DEV === true && console.log("ue_selected_group", selectedGroup);
            qGetSpaceFiles(true, true).then((fls: Array<gfile>) => {
                setInitLoad(false);

                document.title = `kgnf ¦ ${selectedGroup.selectionText}`;

                closeSBSWhenScrenTooSmall(true);
                dealWithFilesForPDFViewers(fls);

                if (fls?.length > 50) {
                    localStorage.setItem(`kgnf_chs_${selectedGroup.oid}`, JSON.stringify(fls));
                }
            });
            const subpaths = getLoc().split("/").filter(Boolean);
            if (subpaths?.[3]) {
                histGetByKey(user.userId, subpaths?.[3]).then(() => {
                    setLoadingChatModeSwitch(false);
                });
            } else {
                qHistGetLatest(user.userId, selectedGroup.selectionId, activeComp.companyid, 1, getHeader()).then((lConv: any) => {
                    if (lConv && lConv.length > 0) {
                        console.log("l_conv¦", lConv);
                        setActiveConvoKey(lConv[0].key); // Set the active conversation key to the latest key (Datetime string)
                        const histObj = JSON.parse(lConv[0].content);
                        setAnswersFunction(histObj, true);
                        setStreamedAnswers(histObj);
                    } else {
                        setActiveConvoKey(genNewConvoKey()); // Set the active conversation key to the latest key (Datetime string)
                        setAnswersFunction([], true);
                        setStreamedAnswers([]);
                    }
                    setLoadingChatModeSwitch(false);
                });
            }
            if (selectedGroup?.permissionlevel >= 2) {
                getGroupsMemberships();
            }
        }
    }, [selectedGroup]);
    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading]);
    //Effect ⇒ showDocLibInChatTab
    useEffect(() => {
        if (initLoad == false) {
            if (!activeConvoKey) return;
            setLoadingChatModeSwitch(true);
            const oldConvo = activeConvoKey;
            setConversation(oldConvo);
        }
        setShouldPlayAudio(Math.random().toString(36).substring(2, 6));
    }, [config.showDocLibInChatTab]);
    //Effect ⇒ isLib
    useEffect(() => {
        chatMessageStreamEnd.current?.scrollIntoView({ behavior: "auto" }), [answers];
        setShouldPlayAudio(Math.random().toString(36).substring(2, 6));
    }, [isLib]);
    //Effect ⇒ xplrrChatVisible
    useEffect(() => {
        chatMessageStreamEnd.current?.scrollIntoView({ behavior: "auto" }), [answers];
    }, [xplrrChatVisible]);
    //Effect ⇒ streamedAnswers
    useEffect(() => {
        if (window.globalStreamOneSecondFlag == false || typeof window.globalStreamOneSecondFlag === "undefined") {
            window.globalStreamOneSecondFlag = true;
            if (xplrrMsgStrm.current) {
                const element = xplrrMsgStrm.current;
                // console.log("scrollHeight", element.scrollHeight, xplrrMsgStrm.current.scrollTop + xplrrMsgStrm.current.clientHeight);
                if (xplrrMsgStrm.current.scrollHeight - (xplrrMsgStrm.current.scrollTop + xplrrMsgStrm.current.clientHeight) < 250) {
                    if (chatMessageStreamEnd.current) {
                        smoothScrollIntoView(chatMessageStreamEnd.current, { behavior: "smooth" });
                    }
                }
            }
            const timeoutId = setTimeout(() => {
                window.globalStreamOneSecondFlag = false;
                clearTimeout(timeoutId);
            }, 500);
        }
    }, [streamedAnswers]);
    //Effect ⇒ isStreaming
    useEffect(() => {
        if (isStreaming == false) {
            window.globalAnswerStreamingFirstCitationFlag = false;
        }
    }, [isStreaming]);
    //Effect ⇒ answers, config.showDocLibInChatTab
    useEffect(() => {
        if (xplrrMsgStrm.current) {
            const element = xplrrMsgStrm.current;
            element.scrollTop = element.scrollHeight;
        }
    }, [answers, config.showDocLibInChatTab]);
    //Effect ⇒ location
    useEffect(() => {
        if (initialLoadComleted == true) {
            import.meta.env.DEV === true && console.log("hndl_path_nav_alt_aapproach", compFlatList, location);
            handlePathNavigation(compFlatList, user);
        }
    }, [location]);
    //Effect ⇒ suggestedQuestions
    useEffect(() => {
        setGreetingMessageHtml(createSuggestedQuestionsHTML());
    }, [suggestedQuestions]);
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.altKey && event.key.toLowerCase() === "p") {
                event.preventDefault();
                setIsConfigPanelOpen(!isConfigPanelOpen);
            }
        };
        const handleResize = debounce(() => {
            closeSBSWhenScrenTooSmall();
        }, 500);
        window.addEventListener("resize", handleResize);
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("keydown", handleKeyDown);
            handleResize.cancel();
        };
    }, []);
    // Effect⇒[answers] Save Convo to Local Storage (Convo History) (Indexed Hash Array of Arrays)
    useEffect(() => {
        if (answers && answers.length > 0) {
            console.log("UE¦[answers]¦answers⇒", activeConvoKey, answers);
            if (answersLength.current != answers.length) {
                histAdd(parseInt(activeConvoKey), user.userId, selectedGroup.selectionId, activeComp.companyid, answers[0][0]);
                answersLength.current = answers.length;
            }
            !activeConvoKey && newConverson();
        }
        window.greetingMessageBeginConversation = (param: string) => {
            console.log("Greeting Message Begin Conversation", param);
            if (param != "") {
                makeApiRequest(param);
            } else {
                let dcs = fileList.map((f: gfile) => f.name);
                let shfl = shuffleArray([...dcs]).slice(0, 3);
                makeApiRequest(shfl.join(", "));
            }
        };
    }, [answers]);
    // Effect ⇒ activeConvoKey
    useEffect(() => {
        clickedCitation.current = "";
    }, [activeConvoKey]);
    // Effect⇒[config] UE Save Config to Local Storage
    useEffect(() => {
        //import.meta.env.DEV === true && console.log("UE preserveLocalConfig", config);
        preserveLocalConfig();
        setSourceHighlighting(config.sourceHighlighting);
    }, [config]);
    // Effect fileList
    useEffect(() => {
        window.globalFL = fileList;
        if (fileList?.length > 0) {
            openFileHotlink();
        }
    }, [fileList]);

    // Navigation Set Location
    const setChatTab = () => {
        navigate(`${activeComp.companyid}/${selectedGroup.selectionId}/chat`);
    };
    const setLibTab = () => {
        navigate(`/${activeComp.companyid}/${selectedGroup.selectionId}/library`);
    };
    const navChatLib = (loc: string, groupid: string) => {
        if (loc == "chat") {
            navigate(`/${activeComp.companyid}/${groupid}/chat`);
        } else {
            navigate(`/${activeComp.companyid}/${groupid}/library`);
        }
    };
    const setGrpTab = () => {
        setIsSpacePanelOpen(false);
        navigate(`${activeComp.companyid}`);
    };

    // Handle Path - Handle Nav - Path Navigation Handler
    const handlePathNavigation = async (cFlatList: gcompflat[], userObj: any) => {
        let subpaths = getLoc().split("/").filter(Boolean);
        import.meta.env.DEV === true && console.log("loc¦", location, "subpaths¦", subpaths);
        if (redeemURLRef.current) {
            import.meta.env.DEV === true && console.log("rdeem", redeemURLRef.current);

            let rU = redeemURLRef.current;
            subpaths = rU.split("/").filter(Boolean);
            window.history.replaceState({}, "", `${window.location.origin}/#${rU}`);
            redeemURLRef.current = null;
        }
        subpaths.length > 0 && pathGetValidComp(cFlatList, subpaths);
        // Root Ex. kognify.ai
        if (subpaths.length == 0) {
            // Handle Company
            const homeComps = cFlatList.filter((comp: any) => comp.companyid == userObj?.companyid) || [];
            if (homeComps.length > 0) {
                navigate(`/${homeComps[0].companyid}`);
            } else if (cFlatList.length > 0) {
                navigate(`/${cFlatList[0].companyid}`);
            } else {
                setNoMembership(true);
                uMenuEmpty();
                return;
            }
        } else if (subpaths.length === 1) {
            // Root Ex. kognify.ai/AB
            setIsLib("grp");
            closeSBS();
            // Change Company if not the same Current
            if (activeComp.companyid !== subpaths[0]) {
                if (cFlatList.length > 0) {
                    const comp = cFlatList.filter((comp: any) => comp.companyid === subpaths[0])?.[0] || null;
                    if (comp) {
                        setActiveComp(comp);
                    } else {
                        navigate("/");
                    }
                }
            }
        } else if (subpaths.length >= 2) {
            // Root Ex. kognify.ai/AB/grp...
            let groupPath = decodeURIComponent(subpaths[1]);
            let isUserMenu = groupPath == "acc" || groupPath == "adm";
            if (activeComp.companyid !== subpaths[0]) {
                closeSBS();
                if (userObj && userObj.userId && userObj.userId !== "") {
                    applyLocalConfig();
                    if (cFlatList.length > 0) {
                        const comp = cFlatList.filter((comp: any) => comp.companyid === subpaths[0])?.[0] || null;
                        if (comp) {
                            setActiveComp(comp);
                            if (groupPath == "acc") {
                                await getTiers();
                                setIsLib("acc");
                            } else if (groupPath == "adm") {
                                await getAllUsers();
                                setIsLib("adm");
                            } else {
                                setIsLib("grp");
                            }
                        }
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                    }
                }
            } else {
                // Company Same as Current
                if (selectedGroup.selectionId !== groupPath) {
                    closeSBS();
                    if (groupList?.length > 0) {
                        let gr = groupList.filter((group: any) => group.groupid === groupPath)?.[0] || null;
                        if (gr != null) {
                            // Set Group to Found Group
                            setSelectedGroup(groupListToSelectedGroup(gr));
                        } else if (groupPath == "acc") {
                            await getTiers();
                            setIsLib("acc");
                        } else if (groupPath == "adm") {
                            await getAllUsers();
                            setIsLib("adm");
                        } else {
                            // Reload. No Access to Group, but has access to other Groups
                            setSelectedGroup(groupListToSelectedGroup());
                            window.location.href = "/";
                            window.location.reload();
                            return;
                        }
                    } else {
                        // No Groups Available
                        if (groupPath == "acc") {
                            await getTiers();
                            setIsLib("acc");
                        } else if (groupPath == "adm") {
                            await getAllUsers();
                            setIsLib("adm");
                        } else {
                            setSelectedGroup(groupListToSelectedGroup());
                            window.location.href = "/";
                            window.location.reload();
                            return;
                        }
                    }
                } else {
                    if (subpaths?.[3]) {
                        histGetByKey(user.userId, subpaths?.[3]).then(() => {
                            setLoadingChatModeSwitch(false);
                        });
                    } else {
                        setIsLoading(false);
                    }
                }
            }

            if (subpaths?.[2]) {
                if (subpaths[2] === "chat") {
                    if (subpaths?.[3]) {
                        console.log("NAV¦CHT", subpaths[3]);
                        setActiveConvoKey(subpaths[3]);
                    }
                    setIsLib("chat");
                } else if (subpaths[2] === "library") {
                    setIsLib("lib");
                } else if (subpaths[2] === "explorer") {
                    setIsLib("xplr");
                }
            } else if (isUserMenu == false) {
                setIsLib("grp");
            }
        }
    };

    // Open History Panel
    const toggleLeftPanel = () => {
        histGetList(user.userId, selectedGroup.selectionId, activeComp.companyid, 5);
        setIsLeftPanelOpen(!isLeftPanelOpen);
    };
    // Open Spaces Panel
    const toggleSpacePanel = () => {
        histGetRecent(user.userId, activeComp.companyid);
        setIsSpacePanelOpen(!isSpacePanelOpen);
    };
    // Not Sure what this is
    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveAnalysisPanelTab(tab);
        }
        setSelectedAnswer(index);
    };
    // Library Space Analytics
    const openSpacesAnalyticsPanel = () => {
        getChatLog();
        setIsSpaceAnalyticsModalOpen(true);
    };
    // Manage Include Category (Legacy for file scoped chatting)
    const updateCatsState = (newVal: string) => {
        // After file is Uploaded. Toast Notification
        import.meta.env.DEV === true && console.log("UPD_CATS_STATE");
        if (newVal !== "") {
            if (newVal.includes("¦Unsuported URL ⇛ Only Youtube Supported for Now")) {
            } else {
                let subpaths = window.location.hash.split("/").filter(Boolean);
                if (subpaths.length > 2) {
                    if (decodeURIComponent(subpaths[2]) == selectedGroup.selectionId) {
                        qGetSpaceFiles().then((fls: Array<gfile>) => {
                            setInitLoad(false);
                            closeSBSWhenScrenTooSmall(true);
                            if (fls && fls.length > 0) {
                                const dFl = fls[0];
                                const fileStrng = `${dFl.name}_page=${1}_fid=${dFl.fileid}`;
                                setIsPDFModalOpen({
                                    defaultFile: fileStrng,
                                    startPage: "1",
                                    isOpen: false,
                                    searchString: "",
                                    contentlist: dFl?.contentlist || [],
                                    pagemap: dFl?.pagemap || {},
                                    fileId: dFl.fileid,
                                    refPageNum: "1",
                                    isCitation: false,
                                    rndo: Math.random().toString(36).substring(2, 6),
                                    activeGroupId: selectedGroup.selectionId,
                                    fileList: fls,
                                    fl: dFl
                                });
                            } else {
                                setIsPDFModalOpen({
                                    defaultFile: "",
                                    startPage: "1",
                                    isOpen: false,
                                    searchString: "",
                                    contentlist: [],
                                    pagemap: {},
                                    fileId: "",
                                    refPageNum: "1",
                                    isCitation: false,
                                    rndo: Math.random().toString(36).substring(2, 6),
                                    activeGroupId: selectedGroup.selectionId,
                                    fileList: fls,
                                    fl: undefined
                                });
                            }
                        });
                    }
                }
            }
        }
    };
    // Refresh Filelist
    const listRefreshRequest = () => {
        let subpaths = window.location.hash.split("/").filter(Boolean);
        if (subpaths.length > 2) {
            if (decodeURIComponent(subpaths[2]) == selectedGroup.selectionId) qGetSpaceFiles();
        }
    };
    const pathGetValidComp = (cFlatList: gcompflat[], subpaths: string[]) => {
        if (cFlatList.length > 0) {
            const comp = cFlatList.filter((comp: any) => comp.companyid === subpaths[0])[0];
            if (comp) {
                import.meta.env.DEV === true && console.log("company_valid¦", comp);
            } else {
                navigate("/");
            }
        }
    };
    const hndlIfrm = () => {
        if (window.location.href?.indexOf("ifrm=true") > 0 == true) {
            console.log("us_iframe");
            setIsIframe(true);
        } else {
            setIsIframe(false);
        }
    };
    const getTokenDyn = async () => {
        const utok = localStorage.getItem("msalAuth4Tok");
        if (utok) {
            const utokcache: AuthenticationResult = JSON.parse(utok);
            setToken(utokcache);
            return utokcache;
        }
        if (!msalInstance) return null;
        if (token) return token;

        let tkn = await getToken(loginRequest.scopes);
        setToken(tkn);
        return tkn;
    };
    const redeemCode = async (userMail: string) => {
        let params = new URLSearchParams(window.location.search);
        let rdmCd = params.get("redeemCode");
        let convCode = params.get("convCode") || undefined;
        let mE = params.get("mE") || "0";
        let anon = params.get("A") || "0";
        let sub = params.get("sub") || "";

        if (rdmCd !== null) {
            params.delete("redeemCode");
            params.delete("spt");
            params.delete("A");
            params.delete("convCode");
            params.delete("mE");
            params.delete("sub");

            const newUrl = window.location.pathname + (params.toString() ? "?" + params.toString() : "");
            if (userMail !== "") {
                let userCheck = await qCodeChk(rdmCd, userMail, mE, convCode);
                if (userCheck.cmp) {
                    if (!groupList.some((g: ggroup) => g.groupid == rdmCd)) {
                        let navU = convCode ? `/${userCheck.cmp}/${rdmCd}/chat/${convCode}` : `/${userCheck.cmp}/${rdmCd}/chat`;
                        redeemURLRef.current = navU;
                        redeemURLSecondPathRef.current = navU;
                    }
                } else {
                    window.history.replaceState({}, "", newUrl);
                }
            } else {
                window.history.replaceState({}, "", newUrl);
            }
        }
        return rdmCd;
    };
    const getHeader = (token?: any) => {
        if (token) {
            return {
                ...(loggedInUser?.token ? { Authorization: `Bearer ${token.accessToken}` } : {}),
                "X-MS-CLIENT-PRINCIPAL-NAME": getTokenMail(token),
                "X-MS-CLIENT-PRINCIPAL-ID": getTokenAccountID(token),
                "Content-Type": "application/json"
            };
        } else {
            return {
                ...(loggedInUser?.token ? { Authorization: `Bearer ${loggedInUser.token}` } : {}),
                "X-MS-CLIENT-PRINCIPAL-NAME": loggedInUser?.mail ? loggedInUser.mail : "",
                "X-MS-CLIENT-PRINCIPAL-ID": loggedInUser?.userId ? loggedInUser.userId : "",
                "Content-Type": "application/json"
            };
        }
    };
    const openFileHotlink = () => {
        let subpaths = location.pathname.split("/").filter(Boolean);
        if (subpaths.length > 3 && subpaths?.[2] === "library" && subpaths?.[3]) {
            const item = fileList.find(f => f.fileid == subpaths?.[3]);
            if (!item) return;
            setTimeout(() => {
                onShowCitation(`${item.name}#page=1_fid=${subpaths?.[3]}`, null, 0, undefined);
            }, 1300);
        }
    };
    const dealWithFilesForPDFViewers = (fls: Array<gfile>) => {
        if (fls && fls.length > 0) {
            const dFl = fls[0];
            const fileStrng = `${dFl.name}_page=${1}_fid=${dFl.fileid}`;
            setIsPDFModalOpen({
                defaultFile: fileStrng,
                startPage: "1",
                isOpen: false,
                searchString: "",
                contentlist: dFl?.contentlist || [],
                pagemap: dFl?.pagemap || {},
                fileId: dFl.fileid,
                refPageNum: "1",
                isCitation: false,
                rndo: Math.random().toString(36).substring(2, 6),
                activeGroupId: selectedGroup.selectionId,
                fileList: fls,
                fl: dFl
            });
        } else {
            closeSBSWhenNoFiles();
            setIsPDFModalOpen({
                defaultFile: "",
                startPage: "1",
                isOpen: false,
                searchString: "",
                contentlist: [],
                pagemap: {},
                fileId: "",
                refPageNum: "1",
                isCitation: false,
                rndo: Math.random().toString(36).substring(2, 6),
                activeGroupId: selectedGroup.selectionId,
                fileList: fls,
                fl: undefined
            });
        }
    };
    const closeSBSWhenScrenTooSmall = (dontSendToast?: boolean) => {
        if (window.innerWidth < 768) {
            if (config.showDocLibInChatTab == true) {
                if (dontSendToast != true)
                    toast.warning("Side-by-Side View for Chat and Documents is currently not supported on screens with less than 768px of width");

                setConfig(prevConfig => ({
                    ...prevConfig,
                    showDocLibInChatTab: false
                }));

                return;
            }
        }
    };
    const closeSBSWhenNoFiles = () => {
        if (config.showDocLibInChatTab == true) {
            setConfig(prevConfig => ({
                ...prevConfig,
                showDocLibInChatTab: false
            }));
        }
        return;
    };
    const closeSBS = () => {
        if (config.showDocLibInChatTab == true) {
            setConfig(prevConfig => ({
                ...prevConfig,
                showDocLibInChatTab: false
            }));
        }
    };
    const uMenuEmpty = () => {
        const newAccountMenuItems = [
            {
                key: "Logout",
                text: "Logout",
                onClick: () => {
                    if (msalInstance) {
                        msalInstance
                            .logoutPopup({
                                postLogoutRedirectUri: "/", // redirects the top level app after logout
                                account: msalInstance.getActiveAccount(),
                                onRedirectNavigate: url => {
                                    return true;
                                }
                            })
                            .then(() => {
                                localStorage.removeItem("msalAuth4");
                                localStorage.removeItem("msalAuth4Tok");
                                if (isIframe == true) {
                                    window.location.reload();
                                } else {
                                    window.location.href = window.location.href;
                                }
                            });
                    }
                },
                iconProps: { iconName: "SignOut" }
            }
        ];
        setAccMenuItems(newAccountMenuItems);
    };
    const uMenu = (userObj: any, cFlatList: gcompflat[], homeComp: any) => {
        const newAccountMenuItems = [
            {
                key: "User",
                disabled: true,
                text: userObj.userMail,
                iconProps: { iconName: "Contact" }
            },
            {
                key: "DarkMode",
                disabled: false,
                text: "User Settings",

                iconProps: { iconName: "Settings" },
                onClick: () => {
                    setIsConfigPanelOpen(true);
                }
            },
            {
                key: "Logout",
                text: userObj.userMail.indexOf("quest_") > -1 ? "Create Account" : "Logout",
                onClick: () => {
                    if (msalInstance) {
                        msalInstance
                            .logoutPopup({
                                postLogoutRedirectUri: "/", // redirects the top level app after logout
                                account: msalInstance.getActiveAccount(),
                                onRedirectNavigate: url => {
                                    return true;
                                }
                            })
                            .then(() => {
                                localStorage.removeItem("msalAuth4");
                                localStorage.removeItem("msalAuth4Tok");
                                if (isIframe == true) {
                                    window.location.reload();
                                } else {
                                    window.location.href = window.location.href;
                                }
                            });
                    }
                },
                iconProps: { iconName: "SignOut" }
            }
        ];
        try {
            if (cFlatList && cFlatList.length > 0 && cFlatList.some(a => a.permissionlevel >= 3 && a.companyid === homeComp.companyid) && isIframe != true) {
                newAccountMenuItems.splice(1, 0, {
                    key: "ManageUsers",
                    text: "Administrative",
                    onClick: () => {
                        openUserList();
                    },
                    iconProps: { iconName: "Admin" }
                });
            }
            if (userObj.userMail.indexOf("quest_") == -1 && isIframe != true && activeComp.licensingkind == 0) {
                newAccountMenuItems.splice(1, 0, {
                    key: "ManageAccount",
                    text: "Manage Account",
                    onClick: () => {
                        //qGetGroupsAdmin();
                        //setIsLib("acc");
                        navigate(`${activeComp.companyid}/acc`);
                    },
                    iconProps: { iconName: "AccountManagement" }
                });
            }
            if (cFlatList && cFlatList.length > 1 && isIframe != true) {
                newAccountMenuItems.splice(1, 0, {
                    key: "ManageCompanies",
                    text: homeComp.companyname,
                    onClick: () => {
                        setIsCompListModalOpen(true);
                    },

                    iconProps: { iconName: "Group" }
                });
            }
        } catch (err) {
            console.log("UMENU¦Error", err);
        }

        setAccMenuItems(newAccountMenuItems);
    };
    const openUserList = () => {
        getAllUsers().then((users: any) => {
            navigate(`${activeComp.companyid}/adm`);
        });
    };
    const applyLocalConfig = () => {
        const configObj = localStorage.getItem("configObj6");
        if (configObj) {
            let configParsed = JSON.parse(configObj);
            configParsed.showDocLibInChatTab = false;
            handleIsDark(configParsed.isDark);
            setConfig(configParsed);
            closeSBSWhenScrenTooSmall();
        } else {
            let configDefault = JSON.stringify(config);
            localStorage.setItem("configObj6", configDefault);
            closeSBSWhenScrenTooSmall();
        }
    };
    const preserveLocalConfig = () => {
        let configString = JSON.stringify(config);
        localStorage.setItem("configObj6", configString);
    };
    const setAnswersFunction = (histObj: any, setAnswersLength?: boolean) => {
        if (setAnswersLength == true) answersLength.current = (histObj ?? []).length;
        setAnswers(histObj);
    };
    const histGetByKey = async (userid: string, key: string) => {
        try {
            let keyConvo = await qHistGetByKey(userid, key, getHeader());
            const histObj = JSON.parse(keyConvo[0].content);
            setActiveConvoKey(keyConvo[0].key); // Set the active conversation key to the latest key (Datetime string)
            setAnswersFunction(histObj, true);
            setStreamedAnswers(histObj);
            setLoadingChatModeSwitch(false);
        } catch {
            setLoadingChatModeSwitch(false);
        }
    };
    const histGetRecent = async (userid: string, company: string) => {
        try {
            let recentConvosUserWide = await qHistGetRecent(userid, company, getHeader());
            setRecentConvosAccrosSpaces(recentConvosUserWide);
        } catch (err) {
            console.log("Failed to Get Recent Convos For User", err);
        }
    };
    const getSuggestedQuestions = async (fLst: gfile[]) => {
        try {
            if (fLst.length == 0) return setSuggestedQuestions([]);
            let suggestedQuestions = await qGetSuggestedQuestions(fLst, getHeader());
            setSuggestedQuestions(suggestedQuestions);
        } catch {}
    };
    const getTiers = async () => {
        let tiers = await qGetTiers(getHeader());
        setLTiers(tiers);
    };
    const histGetList = async (userid: string, groupid: string, companyid: string, topn: number) => {
        let convos = await qHistGetList(userid, groupid, companyid, topn, getHeader());
        setConvos(convos);
    };
    const histAdd = async (key: number, userid: string, groupid: string, companyid: string, name: string, aa?: any) => {
        qHistAdd(key, userid, groupid, companyid, name, getHeader(), aa || answers);
    };
    const newConverson = () => {
        setActiveConvoKey(genNewConvoKey());
        setAnswersFunction([], true);
        setStreamedAnswers([]);
        setError(undefined);
    };
    const setConversation = (dateString: string) => {
        setLoadingChatModeSwitch(true);
        initialLoadComleted == true && newConverson();
        histGetByKey(user.userId, dateString).then(() => {
            setLoadingChatModeSwitch(false);
        });
        setActiveConvoKey(dateString);
        setIsLeftPanelOpen(false);
    };
    //  qFuncs ¦ compList ⇒ Get List of Companies
    const getCompFlatList = async () => {
        const token = await getTokenDyn();
        if (!token) return [];
        let compList = await qGetCompFlatList(getHeader(token));
        setCompFlatList(compList);
        return compList;
    };
    // CompMbrshps ⇒ Space Memberships to Show in Space Edit (qGetGroupsMemberships, qGetCompanyUsers) [Per Comp]
    const getGroupsMemberships = async () => {
        let mbrshpsResp = await qGetGroupsMemberships(activeComp.companyid, getHeader());
        mbrshpsResp ? setCompMbrshps(mbrshpsResp) : setCompMbrshps([]);
        return mbrshpsResp;
    };
    // CompUsrs ⇒ User Picker Suggestions  [Per Comp]
    const getCompanyUsers = async () => {
        let compUsers = await qGetCompanyUsers(activeComp.companyid, getHeader());
        setCompUsrs(compUsers);
        return compUsers;
    };
    // AllUsrs ⇒ Company Users List (Owner Panel)[Gets all For User - Filters down t Comp Levl in List]
    const getAllUsers = async () => {
        const token = await getTokenDyn();
        let allUsers = await qGetAllUsers(getHeader(token));
        setAllUsrs(allUsers);
        return allUsers;
    };
    // groupList ⇒ Spaces List + Permissions Level [Per Comp]
    const getGroups = async (cmp?: string) => {
        let grpsresp = await qGetGroups(cmp ?? activeComp.companyid, getHeader());
        if (grpsresp) setGroupList(grpsresp);
        return grpsresp;
    };
    // qGetUSRINF ⇒ User + Default Company
    const qGetUserInfo = async () => {
        const token = await getTokenDyn();
        if (!token) return null;
        let redeemResult = await redeemCode(getTokenMail(token));

        setLoggedInUser({
            userId: getTokenAccountID(token),
            mail: getTokenMail(token),
            token: token.accessToken
        });
        const response = await fetch("/qGetUserInfo", {
            headers: {
                ...getHeader(token),
                ...{ kgnfhsh: window.location.hash?.replace(/[^a-zA-Z0-9]/g, " ") }
            }
        });
        if (!response.ok) console.log("USR_INF_ERR", response);
        let userRes = await response.json();
        ``;

        setUser({
            userDisplayName: userRes.userDisplayName,
            userId: userRes.userId,
            userMail: userRes.userMail,
            companyid: userRes.companyid,
            name: (token.idTokenClaims as any).name,
            tier: userRes.tier,
            subscriptionid: userRes.subscriptionid,
            status: userRes.status
        });
        return userRes;
    };

    // Send Chat Request
    const handleAsyncRequest = async (question: string, answers: [string, ChatAppResponse][], setAnswers: Function, responseBody: ReadableStream<any>) => {
        let answer: string = "";
        let askResponse: ChatAppResponse = {} as ChatAppResponse;
        window.globalIsCancellingMidway = false;
        const updateState = (newContent: string) => {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    answer += newContent;
                    const latestResponse: ChatAppResponse = {
                        ...askResponse,
                        choices: [{ ...askResponse.choices[0], message: { content: answer, role: askResponse.choices[0].message.role } }]
                    };
                    setStreamedAnswers([...answers, [question, latestResponse]]);
                    resolve(null);
                }, 10);
            });
        };
        try {
            setIsStreaming(true);
            for await (const event of readNDJSONStream(responseBody)) {
                if ((window.globalIsCancellingMidway as any) == true) {
                    console.log("¦CANCELLING_MIDWAY_01¦");
                    break;
                }
                if (event?.choices?.[0]?.context?.data_points_integrated?.citations) {
                    event["choices"][0]["message"] = event["choices"][0]["delta"];
                    askResponse = event;
                } else if (event?.choices?.[0]?.delta?.content) {
                    setIsLoading(false);
                    await updateState(event["choices"][0]["delta"]["content"]);
                } else if (event?.choices?.[0]?.context) {
                    try {
                        askResponse.choices[0].context = { ...askResponse.choices[0].context, ...event["choices"][0]["context"] };
                    } catch (e) {}
                } else if (event["error"]) {
                    if ((window.globalIsCancellingMidway as any) == true) {
                        console.log("¦cancelling_midway_01¦");
                    } else {
                        throw Error(event["error"]);
                    }
                }
            }
        } catch (e) {
            console.error("An_Error_Occurred⇒", e);
        } finally {
        }
        const fullResponse: ChatAppResponse = {
            ...askResponse,
            choices: [{ ...askResponse.choices[0], message: { content: answer, role: askResponse.choices[0].message.role } }]
        };

        setIsStreaming(false);
        return fullResponse;
    };
    // Send Chat Request
    const makeApiRequest = async (question: string) => {
        if ((selectedGroup.premiumby ?? "") == "" && selectedGroup.dailymsgs > (lTiers?.at(0)?.msgsplmt || 0)) {
            toast.warning(msgLmtHit);
            return;
        }
        if (isStreaming) return;

        lastQuestionRef.current = question;

        error && setError(undefined);

        setIsLoading(true);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        const dynFiltering = determineCategoryAndGroups();

        const ovrrds = {
            prompt_template: selectedGroup.custaiinst,
            exclude_category: excludeCategory.length === 0 ? undefined : excludeCategory,
            include_category: dynFiltering.category.length === 0 ? undefined : dynFiltering.category,
            top: selectedGroup.retrievecount,
            retrieval_mode: config.retrievalMode as RetrievalMode,
            semantic_ranker: config.useSemanticRanker,
            semantic_captions: config.useSemanticCaptions,
            highlights_as_source: config.useHighlightsAsSource,
            suggest_followup_questions: selectedGroup.questionsuggestions == 1 ? true : undefined,
            use_oid_security_filter: false,
            use_groups_security_filter: false,
            user_id: dynFiltering.groups,
            inscope: selectedGroup.inscope,
            temperature: selectedGroup.temperature,
            responsetokenlimit: selectedGroup.responsetokenlimit,
            disablecitationhighlighting: selectedGroup.disablecitationhighlighting,
            strictness: selectedGroup.strictness,
            companyid: activeComp.companyid,
            oid: selectedGroup.oid,
            invoicesgroup: selectedGroup.invoicesgroup,
            uid: user.userId
        };
        console.log("ovrds", ovrrds);
        try {
            const messages: ResponseMessage[] = answers.flatMap(a => [
                { content: a[0], role: "user" },
                { content: a[1].choices[0].message.content, role: "assistant" }
            ]);

            const request: ChatAppRequest = {
                messages: [...messages, { content: question, role: "user" }],
                stream: config.shouldStream,
                use_integrated_search: selectedGroup.isintegrateddata == 1 ? true : false,
                context: {
                    overrides: ovrrds
                },
                session_state: answers.length ? answers[answers.length - 1][1].choices[0].session_state : null
            };
            let response;
            if (xplrrMsgStrm.current) {
                const element = xplrrMsgStrm.current;
                element.scrollTop = element.scrollHeight;
            }

            response = await chatApi(request, loggedInUser);

            if (!response.body) throw Error("No response body");

            if (config.shouldStream) {
                // HANDLE STRAMED RESPONSE
                const parsedResponse: ChatAppResponse = await handleAsyncRequest(question, answers, setAnswers, response.body);

                parsedResponse.pos = null;
                parsedResponse.use_integrated_search = true;
                setAnswersFunction([...answers, [question, parsedResponse]], false);
            } else {
                const ResponseContext = await response.json();
                const joinedAnswers = ResponseContext.data_points
                    .map((a: string) => {
                        const [documentName, content] = a.split(/:(.+)/);
                        return `[${documentName}]<div>${content}</div>`;
                    })
                    .join("");

                const emptyResponseChoice: ResponseChoice = {
                    index: 0,
                    message: { content: joinedAnswers, role: "assistant" },
                    context: {
                        thoughts: "",
                        data_points: ResponseContext.data_points_captions,
                        data_points_captions: ResponseContext.data_points_captions,
                        data_points_integrated: ResponseContext.data_points_integrated,
                        followup_questions: [],
                        response_tokens: 0,
                        prompt_tokens: 0,
                        message_type: "",
                        sql_query: "",
                        chartjs_json: "",
                        sql_result: "",
                        no_info_message: {}
                    },
                    session_state: answers.length ? answers[answers.length - 1][1].choices[0].session_state : null
                };

                const emptyChatAppResponse: ChatAppResponse = {
                    choices: [emptyResponseChoice],
                    datecode: new Date().toISOString(),
                    interaction_guid: "",
                    pos: null
                };

                const parsedResponse: ChatAppResponseOrError = emptyChatAppResponse;
                if (response.status > 299 || !response.ok) {
                    throw Error(parsedResponse.error || "Unknown error");
                }
                setAnswersFunction([...answers, [question, parsedResponse as ChatAppResponse]], false);
            }
        } catch (e) {
            setError(e);
            setIsStreaming(false);
        } finally {
            setIsLoading(false);
        }
    };
    // Before chat Req Determine Overrides for Groups and Users
    const determineCategoryAndGroups = () => {
        let cat = "";
        let groups = "";
        if (selectedGroup.selectionId == "FileSelect") {
            cat = includeCategory; // Set File Filter to Selected Files
            groups = includeCategoryGroups; // Set Group Filter to Selected File's Groups
        } else {
            cat = "";
            groups = selectedGroup.selectionId;
        }
        return { category: cat, groups: groups };
    };

    const getGroupLastModifiedFileDate = async (groupid: string) => {
        let modresp = await qGetGroupLastModifiedFileDate(groupid, getHeader());
        return modresp;
    };
    const qGetSpaceFiles = async (preWipe?: boolean, pullCacheFirst?: boolean) => {
        if (pullCacheFirst == true) {
            console.log("init_load_true");
            let flCache = JSON.parse(localStorage.getItem(`kgnf_chs_${selectedGroup.oid}`) ?? "[]");
            if (flCache.length > 0) {
                setFileCount(flCache?.length || 0);
                setFileList(flCache);
                setInitLoad(false);
                let latestModified = getLatestModifiedFile(flCache)?.modified;
                if (latestModified) {
                    getGroupLastModifiedFileDate(selectedGroup.selectionId).then(lastMod => {
                        if (lastMod.length > 0 && lastMod[0]?.updated && latestModified && lastMod[0]?.updated == latestModified) {
                            console.log("no_updates");
                        } else {
                            qGetSpaceFiles();
                        }
                    });
                } else {
                    qGetSpaceFiles();
                }
                getSuggestedQuestions(flCache);
                return flCache;
            }
        }
        if (preWipe == true) {
            setFileList([]);
        }
        const params = new URLSearchParams({
            groupid: selectedGroup.selectionId,
            isinvoicegroup: selectedGroup.invoicesgroup == 1 ? "true" : "false",
            companyid: activeComp.companyid
        });

        const url = "/qGetSpaceFiles" + `?${params.toString()}`;
        const response = await fetch(url, {
            headers: getHeader()
        });
        if (!response.ok) {
            throw Error("QGETFLS¦Failed to Get User Files");
            setInitLoad(false);
        }
        let fileListResp = await response.json();
        console.log("FLS¦setFleLst", fileListResp);
        setFileCount(fileListResp?.length || 0);
        setFileList(fileListResp);
        setInitLoad(false);
        getSuggestedQuestions(fileListResp);
        return fileListResp;
    };
    const getChatLog = async () => {
        let sChatLog: gChatlogItem[] = await qGetChatLog(selectedGroup.selectionId, getHeader());
        setSpaceChatLog(sChatLog);
        return sChatLog;
    };
    const getLegacySearchString = (pageRef: string, index: number) => {
        let searchString;
        try {
            searchString = streamedAnswers?.[index]?.[1]["choices"][0]["context"]["data_points"].find(el => el.startsWith(pageRef));
        } catch {
            let directSearchRes = answers?.[index]?.[1]["choices"][0]["context"]["data_points"] as any;
            searchString = directSearchRes.data_points.find((el: string) => el.startsWith(pageRef));
        }
        searchString = searchString?.replace(pageRef, "").substring(1)?.trim();
        return searchString;
    };
    const onShowCitation = (fileP: string, integratedCitation: string | null, index: number, openSBS?: boolean) => {
        if (!fileP) return;

        fileP = cleanFileIDString(fileP);
        let startPage = extractPageValue(fileP);
        let fid = extractFileIDValue(fileP);
        let pageRef = fileP.replace("/content/", "");

        let srchstr = integratedCitation == null ? getLegacySearchString(pageRef, index) : integratedCitation;
        let isOpenBool = config.showDocLibInChatTab == true ? false : true;

        let citFl = window.globalFL.filter((file: gfile) => file.fileid == fid);

        closeSBSWhenScrenTooSmall(true);
        if (citFl.length == 0) {
            toast.warn("File Not Found in Library");
        } else {
            setIsPDFModalOpen({
                defaultFile: fileP.replace("/content/", "").replace("#fid", "_fid").replace("#page", "_page"),
                startPage: startPage,
                isOpen: true,
                searchString: srchstr ? srchstr : "",
                contentlist: citFl[0].contentlist || [],
                pagemap: citFl[0].pagemap || {},
                fileId: fid,
                refPageNum: startPage,
                isCitation: true,
                rndo: "",
                activeGroupId: selectedGroup.selectionId,
                fileList: window.globalFL,
                fl: citFl[0]
            });
        }
    };
    const onCompareClicked = (file1: string, file2: string, diff: gdiff[]) => {
        if (!file1 || !file2) return;

        file1 = cleanFileIDString(file1);
        file2 = cleanFileIDString(file2);
        let fid1 = extractFileIDValue(file1);
        let fid2 = extractFileIDValue(file2);
        let citFl1 = window.globalFL.filter((file: gfile) => file.fileid == fid1);
        let citFl2 = window.globalFL.filter((file: gfile) => file.fileid == fid2);

        closeSBSWhenScrenTooSmall(true);

        let startPage = extractPageValue(file1);

        if (citFl1?.length == 0 || citFl1?.length == 0) {
            toast.warn("File Not Found in Library");
        } else {
            setIsPDFDiffModalOpen({
                defaultFile1: file1.replace("/content/", "").replace("#fid", "_fid").replace("#page", "_page"),
                defaultFile2: file2.replace("/content/", "").replace("#fid", "_fid").replace("#page", "_page"),
                isOpen: true,
                searchString: "",
                fileId1: fid1,
                fileId2: fid2,
                refPageNum: startPage,
                isCitation: false,
                rndo: Math.random().toString(36).substring(2, 6),
                activeGroupId: selectedGroup.selectionId,
                fileList: window.globalFL,
                fl1: citFl1[0],
                fl2: citFl2[0],
                diff: diff
            });
        }
    };
    const onShowCitationXplrr = (fileP: string, integratedCitation: string | null, index: number, openSBS?: boolean) => {
        setXplrrChatVisible(false);
        if (!fileP || isPDFModalOpen.isOpen === true) return;

        let startPage = extractPageValue(fileP);
        let fleid = extractFileIDValue(fileP);
        let pageRef = fileP.replace("/content/", "");

        let srchstr;
        if (integratedCitation == null) {
            srchstr = getLegacySearchString(pageRef, index);
        } else {
            srchstr = integratedCitation;
        }
        let citFl = window.globalFL.filter((file: gfile) => file.fileid == fleid);
        if (citFl.length == 0) {
            toast.warn("File Not Found in Library");
        } else {
            setIsPDFModalOpen({
                defaultFile: fileP.replace("/content/", "").replace("#fid", "_fid").replace("#page", "_page"),
                startPage: startPage,
                isOpen: false,
                searchString: srchstr ? srchstr : "",
                contentlist: citFl[0].contentlist || [],
                pagemap: citFl[0].pagemap || {},
                fileId: fleid,
                refPageNum: startPage,
                isCitation: true,
                rndo: Math.random().toString(36).substring(2, 6),
                activeGroupId: selectedGroup.selectionId,
                fileList: window.globalFL,
                fl: citFl[0]
            });
        }
    };
    const manageCitationOpen = (filePath: string, integratedCitation: string | null, index: number, el: HTMLElement | null, openSBS?: boolean) => {
        if (clickedCitation) {
            removeCitationActive();
            applyCitationActive(el?.id);
        }
        if (openSBS == true) {
            if (config.showDocLibInChatTab == false) {
                if (window.innerWidth < 768) {
                    onShowCitation(filePath, integratedCitation, index, openSBS);
                } else {
                    setConfig(prevConfig => ({
                        ...prevConfig,
                        showDocLibInChatTab: true
                    }));
                    setTimeout(() => {
                        onShowCitationXplrr(filePath, integratedCitation, index, openSBS);
                        if (clickedCitation?.current) {
                            setTimeout(() => {
                                applyCitationActive(clickedCitation.current);
                            }, 1000);
                        }
                    }, 50);
                }
            } else {
                onShowCitationXplrr(filePath, integratedCitation, index, openSBS);
            }
        } else if (openSBS == false) {
            if (config.showDocLibInChatTab == true) {
                setConfig(prevConfig => ({
                    ...prevConfig,
                    showDocLibInChatTab: false
                }));
                setTimeout(() => {
                    onShowCitation(filePath, integratedCitation, index, openSBS);
                    if (clickedCitation?.current) {
                        setTimeout(() => {
                            applyCitationActive(clickedCitation.current);
                        }, 1000);
                    }
                }, 50);
            } else {
                onShowCitation(filePath, integratedCitation, index, openSBS);
            }
        } else {
            if (config.showDocLibInChatTab == true) {
                onShowCitationXplrr(filePath, integratedCitation, index, openSBS);
            } else {
                onShowCitation(filePath, integratedCitation, index, openSBS);
            }
        }
    };
    // Callback Func to Handle Space Update for Files
    const handleSelectionChange = (newSelection: { name: string; groupIds: string; groupNames: string }[]) => {
        console.log("handleSelectionChange");
        setIncludeCategory(newSelection.map(item => item.name).join(";"));
        setIncludeCategoryGroups([...new Set(newSelection.map(item => item.groupIds))].join(";")); // Get Unique Group IDs for Selected Files
        //qGetFiles();
    };

    const handleSpaceChange = (selectionId: string, selectionText: string, permissionlevel: number, filecount: number, description: string, aCK?: string) => {
        setAnswersFunction([], true);
        setStreamedAnswers([]);
        aCK ? navigate(`/${activeComp.companyid}/${selectionId}/chat/${aCK}`) : navigate(`/${activeComp.companyid}/${selectionId}/chat`);
    };
    const addSpace = async (nGroup: gnewgroup) => {
        if (nGroup.id == "") return null;

        let owners = [{ userid: user.userId, username: user.userDisplayName, level: 3, mail: user.userMail, isnew: false }];

        const groupname = nGroup.groupname;
        const groupid = nGroup.id;
        const groupdescription = nGroup.description;
        const appid = nGroup.appid;
        const scrt = nGroup.scrt;
        const specializedSettings = nGroup.specializedSettings;
        if (!groupname) {
            setIsSpaceEditModalOpen(false);
            return null;
        }
        fetch("/qAddGroup", {
            method: "POST",
            headers: getHeader(),
            body: JSON.stringify({
                groupid: groupid,
                groupname: groupname,
                companyid: activeComp.companyid,
                description: groupdescription,
                sharewithcode: 0,
                custaiinst: defaultPromptTemplateAsInPythonBackend,
                custgreetingmessage: defaultGreetingsMessage,
                isintegrateddata: true,
                appid: appid,
                scrt: scrt,
                isnew: true,
                ...specializedSettings
            })
        })
            .then(Response => {
                console.log("Space Added");
            })
            .catch(error => {
                console.error("Error:", error);
            });

        fetch("/qAddMembership", {
            method: "POST",
            headers: getHeader(),
            body: JSON.stringify({
                usersArr: owners,
                groupId: groupid,
                companyid: activeComp.companyid
            })
        })
            .then(Response => {
                getGroups().then((grpsresp: any) => {
                    getGroupsMemberships();
                    setNoGroupsShared(false);
                    toast.success("New Space Created");
                    setTimeout(() => {
                        let pageElement = document.querySelector(`#${makeValidHtmlId(groupid)}`);
                        if (pageElement) {
                            smoothScrollIntoView(pageElement, { behavior: "smooth" });
                            setNewSpacePyro(nGroup.id);
                            setTimeout(() => {
                                setNewSpacePyro("");
                            }, 5000);
                        }
                    }, 1);
                });
            })
            .catch(error => {
                console.error("error:", error);
            });
        setIsSpaceModalOpen(false);
        return null;
    };
    const fileSpaceChangeTrigger = (timestamp: string) => {
        qGetSpaceFiles();
    };
    const userMngmntUpdate = (timestamp: string) => {
        getCompanyUsers().then(() => {
            getGroupsMemberships().then(() => {
                getCompFlatList().then(() => {
                    getAllUsers();
                });
            });
        });
    };
    const handleCompChange = (activeComp: gcompflat) => {
        navigate(`/${activeComp.companyid}`);
        setIsCompListModalOpen(false);
    };
    const getOFromList = () => {
        return selectedGroup.permissionlevel;
    };
    const handleGroupListClick = (group: { groupid: string; groupname: string; permissionlevel: number; filecount: number }) => {
        navigate(`/${activeComp.companyid}/${group.groupid}/chat`);
    };
    const handleLeftSpacePanelClick = (item: any, aCK?: string) => {
        setIsSpacePanelOpen(false);
        if (aCK) {
            setLoadingChatModeSwitch(true);
            if (config.showDocLibInChatTab == true) {
                setConfig(prevConfig => ({
                    ...prevConfig,
                    showDocLibInChatTab: false
                }));
            }
        }
        handleSpaceChange(item.groupid, item.groupname, item.permissionlevel, item.filecount, item.description, aCK);
    };
    // This is user click not citation change
    const xplrrCitationChangeCallback = (callBackProps: gXplrrCitationChangeCallback) => {
        const fileFullPath: string = fileList.filter((file: gfile) => file.fileid == callBackProps.fileid)[0].full_path;
        const citationFullPath = fileFullPath.split("/content/").slice(-1) + "#page=" + callBackProps.destPageNum + "_fid=" + callBackProps.fileid;
        setIsPDFModalOpen(prevConfig => ({
            ...prevConfig,
            isOpen: false,
            defaultFile: citationFullPath,
            startPage: callBackProps.destPageNum,
            searchString: "",
            fileId: callBackProps.fileid,
            isCitation: callBackProps.isCitation
        }));
    };

    const toggleChatWithDocs = () => {
        if (window.innerWidth < 768) {
            toast.warning("Side-by-Side View for Chat and Documents is currently not supported on screens with less than 768px of width");
            return;
        }
        let valNow = true;
        if (config.showDocLibInChatTab == true) valNow = false;
        setConfig(prevConfig => ({
            ...prevConfig,
            showDocLibInChatTab: !!valNow
        }));
    };
    const handleIsCancellingMidway = () => {
        setIsCancellingMidway(true);
        window.globalIsCancellingMidway = true;
    };
    const integratedSearchCheck = (use_integrated_search: boolean | undefined) => {
        return use_integrated_search == true && config.searchOnly != true;
    };
    const integratedSearchCheckForStreaming = (use_integrated_search: boolean | undefined) => {
        return selectedGroup.isintegrateddata == 1;
    };
    const onEditSpaceSave = () => {
        getGroups().then((grpsresp: any) => {
            const gr = grpsresp.filter((group: any) => group.groupid === selectedGroup.selectionId)[0];

            setSelectedGroup(groupListToSelectedGroup(gr));
            toast.success("Space Configuration Saved");
        });
    };
    const onGroupDel = () => {
        toast.info("Space Deletion in Progress..");
        navigate(`/${activeComp.companyid}`);
    };
    const handlePivotItemClick = (item: any) => {
        console.log("PVT", item);
        if (item.props.itemKey == "back") {
            setChatTab();
            return;
        }
    };
    const handleAdmPivotItemClick = (item: any) => {
        if (item.props.itemKey == "back") {
            setGrpTab();
            return;
        }
    };
    const inviteUser = async (usr: string) => {
        const response = qAddMember(usr, selectedGroup.selectionId, activeComp.companyid, getHeader());
        userMngmntUpdate(new Date().toISOString());
        setIsShareCalloutVisible(!isShareCalloutVisible);
        toast.success("User Added to Space");
    };
    const onNewGroupClick = () => {
        setIsSpaceModalOpen(true);
        setIsNewSpaceInfoBip(false);
    };
    const histItemHandleEdit = (key: string, newText: string) => {
        const toastId = toast("Changing Conversation Name...", { autoClose: false });
        qHistEditName(key, user.userId, newText, getHeader()).then(() => {
            toast.update(toastId, { render: "Conversation Name Changed", autoClose: 1000 });
        });
    };
    const histItemHandleDelete = (key: string) => {
        const toastId = toast("Deleting Conversation...", { autoClose: false });
        qHistDelete(key, user.userId, getHeader()).then(Response => {
            histGetList(user.userId, selectedGroup.selectionId, activeComp.companyid, 5);
            toast.update(toastId, { render: "Conversation Deleted", autoClose: 1000 });
        });
    };
    const onXplrrPDFDismiss = () => {
        window.globalBase64String = "";
        setShouldPlayAudio(Math.random().toString(36).substring(2, 6));
        setIsPDFModalOpen(prevConfig => ({
            ...prevConfig,
            isOpen: false,
            rndo: ""
        }));
    };
    const onXplrrDiffPDFDismiss = () => {
        window.globalBase64String = "";
        setShouldPlayAudio(Math.random().toString(36).substring(2, 6));
        setIsPDFDiffModalOpen(prevConfig => ({
            ...prevConfig,
            isOpen: false,
            rndo: ""
        }));
    };
    const removeCitationActive = () => {
        let prevEl = document.getElementById(clickedCitation.current || "placeholder_id");
        prevEl?.classList.remove("activeCitFlash");
    };
    const applyCitationActive = (elId: string | undefined) => {
        let obtainCitationElement = document.getElementById(elId || "placeholder_id");
        obtainCitationElement?.classList.add("activeCitFlash");
        clickedCitation.current = obtainCitationElement?.id;
    };
    const onDataHighlight = (searchString: any) => {
        setIsPDFModalOpen(prevConfig => ({
            ...prevConfig,
            searchString: searchString,
            rndo: Math.random().toString(36).substring(2, 6)
        }));
    };
    const onSendFeedback = (prps: gfeedback) => {
        fetch("/qFeedback", {
            method: "POST",
            headers: getHeader(),
            body: JSON.stringify(prps)
        }).then(Response => {
            toast.success("Feedback Sent");
        });
        setAnswers(prevAnswers =>
            prevAnswers.map(([user, response]) =>
                response.interaction_guid === prps.interaction_guid ? [user, { ...response, pos: prps.pos }] : [user, response]
            )
        );
        let answrs = answers.map(([user, response]) =>
            response.interaction_guid === prps.interaction_guid ? [user, { ...response, pos: prps.pos }] : [user, response]
        );
        setTimeout(() => {
            histAdd(parseInt(activeConvoKey), user.userId, selectedGroup.selectionId, activeComp.companyid, answers[0][0], answrs);
        }, 2000);
    };
    const createSuggestedQuestionsHTML = () => {
        let defaultContent = selectedGroup.filecount > 0 ? "" : selectedGroup.permissionlevel >= 2 ? defaultGreetingContent : noContentNoAccessGreetingContent;
        const allQuestions = (suggestedQuestions || [])
            .map((q: any) => q.suggestedquestions)
            .join(";")
            .split(";")
            .filter((question: string) => question !== "" && question != null && question !== undefined);
        if (allQuestions && allQuestions.length > 0 && selectedGroup?.showexamplequestionsonstart == 1) {
            let selectedQuestions = shuffleArray([...allQuestions]).slice(0, 3);
            if (selectedGroup.manualquestionsuggestions != "") {
                selectedQuestions = [...selectedGroup.manualquestionsuggestions.split(";"), ...selectedQuestions];
            }
            const html = selectedQuestions.map(question => answerCardHTML.replace(/{kgnf_replace}/g, encodeHTML(question)));
            return `<div horizontal wrap tokens=5 class='answerCardContainer'>${html.join("")}</div>`;
        } else if (selectedGroup.manualquestionsuggestions != "") {
            const html = selectedGroup.manualquestionsuggestions.split(";").map(question => answerCardHTML.replace(/{kgnf_replace}/g, encodeHTML(question)));
            return `<div horizontal wrap tokens=5 class='answerCardContainer'>${html.join("")}</div>`;
        } else {
            return defaultContent;
        }
    };
    return (
        <ThemeProvider theme={customTheme}>
            <div className={styles.container} id={"rro"}>
                <ToastContainer position="bottom-left" pauseOnHover transition={Bounce} style={{ zIndex: 99999999 }} />
                {/* HEADER */}
                {isIframe != true ? (
                    <div className={styles.headerMain}>
                        <div className={styles.headerSubMain}>
                            <div className={styles.commandsContainer}>
                                <div className={styles.gridDots} onClick={toggleSpacePanel}>
                                    <HiOutlineViewList size={25} />
                                </div>

                                {/* BANNER */}
                                <h2 className={styles.leftLogo} onClick={setGrpTab}>
                                    {activeComp.companyid == "AB" ? (
                                        <img src={kognifyBanner} height="38px" className={`${styles.logoImg} ${config.isDark ? styles.logoDark : ""}`}></img>
                                    ) : activeComp.companyid != "AB" && isLib != "grp" ? (
                                        <div className={styles.homeIconGridDots}>
                                            <TbGridDots />
                                        </div>
                                    ) : null}
                                </h2>
                                {/* LEFT HEADER BUTTONS */}
                                {checkWeAreInGeneralPlace(isLib) == false ? (
                                    <div>
                                        <div className={activeComp.companyid == "AB" ? styles.vertLineHeader : styles.vertLineHeaderWhitelabel} />
                                        <div className={activeComp.companyid == "AB" ? styles.headSpaceContainer : styles.headSpaceContainerWhiteLabel}>
                                            <div
                                                className={`${config.showDocLibInChatTab == false ? styles.headSpaceName : styles.headSpaceNameSBS}`}
                                                onClick={setChatTab}
                                            >
                                                <span>{selectedGroup.selectionText} </span>
                                            </div>
                                            <div className={styles.headSpaceButts}>
                                                <SettingsButton
                                                    className={`${styles.settingsButton} ${isLib == "chat" ? styles.settingsButtonActive : ""}`}
                                                    onClick={setChatTab}
                                                    buttonText="Chat"
                                                    iconId="PiChatTeardropDotsBold"
                                                />
                                                <SettingsButton
                                                    className={`${styles.settingsButton} ${isLib == "lib" ? styles.settingsButtonActive : ""}`}
                                                    onClick={setLibTab}
                                                    buttonText={activeComp.permissionlevel > 1 ? "Manage" : "Content"}
                                                    iconId="TbSettings2"
                                                />
                                                {/*SHARE BUTTON*/}
                                                <div ref={addUserButtonRef}></div>
                                                {selectedGroup.permissionlevel >= 2 ? (
                                                    <div className={styles.headSpaceButsShare} onClick={() => setIsShareCalloutVisible(!isShareCalloutVisible)}>
                                                        <IoShareSocialOutline />
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className={styles.headMenu}></div>
                                    </div>
                                ) : null}
                                {/* SPLIT CHAT-PDF */}
                                <div className={styles.commandsC} id="addSpcBttnID">
                                    {checkWeAreInGeneralPlace(isLib) == false &&
                                        isElementVisible("#dLM") == false &&
                                        (config.showDocLibInChatTab == true) == true && (
                                            <TooltipHost content="Close Side-by-Side View" directionalHint={DirectionalHint.topCenter}>
                                                <IconButton onClick={toggleChatWithDocs} className={styles.SBSIconWrap}>
                                                    <div className={styles.closeSBSPanelIcon}>
                                                        <LuPanelRightClose size={22}></LuPanelRightClose>
                                                    </div>
                                                </IconButton>
                                            </TooltipHost>
                                        )}
                                </div>
                                {/* USER ACCOUNT */}
                                <div id="userPhoto" onClick={() => setIsAccountMenuVisible(true)} className={styles.iconCircle}>
                                    <div className={`${styles.iconCircleIcon} ${isAccountMenuVisible == true ? styles.iconCircleIconActive : ""}`}>
                                        <FiUser size={16} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                {isAccountMenuVisible && (
                    <ContextualMenu items={accMenuItems} target={document.getElementById("userPhoto")} onDismiss={() => setIsAccountMenuVisible(false)} />
                )}

                <div className={styles.chatRoot}>
                    {isIframe == true && <div className={styles.blurBack}></div>}
                    <div className={styles.chatContainer}>
                        {isIframe == true && (
                            <div className={styles.iframeAccountOptions}>
                                {activeComp.companyid == "AB" ? (
                                    <img
                                        onClick={() => {
                                            window.open(window.location.href.replace("ifrm=true", "").replace("&A=1", ""), "_blank", "noopener,noreferrer");
                                        }}
                                        src={kognifyLogo}
                                        height="30px"
                                        className={`${styles.logoImgIframe} ${config.isDark ? styles.logoDarkIframe : ""}`}
                                    ></img>
                                ) : activeComp.companyid != "AB" && isLib != "grp" ? (
                                    <div className={`${styles.linkImgIframe} ${config.isDark ? styles.linkDarkIframe : ""}`}>
                                        <img src={kognifyLogo} height="30px" />
                                    </div>
                                ) : null}

                                <div id="userPhoto" onClick={() => setIsAccountMenuVisible(true)} className={styles.iconCircleIframe}>
                                    <div className={`${styles.iconCircleIcon} ${isAccountMenuVisible == true ? styles.iconCircleIconActive : ""}`}>
                                        <FiUser size={16} />
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* Manage (Library and Settings Pivot) */}
                        {isLib === "lib" && initLoad == false ? (
                            <div className={styles.chatEmptyState}>
                                <Pivot onLinkClick={handlePivotItemClick} styles={libPivotStyles} defaultSelectedKey="Sources">
                                    <PivotItem
                                        headerText=""
                                        itemKey="back"
                                        onRenderItemLink={() => (
                                            <div className={styles.backPivotItemIconCont}>
                                                <MdArrowBackIos size={20} />
                                            </div>
                                        )}
                                    />

                                    <PivotItem headerText="Sources" itemKey="Sources">
                                        <div className={styles.detailsListWrapper}>
                                            {/*LIBRARY*/}
                                            <DetailsListDocumentsExample
                                                fileList={fileCount > 0 ? fileList : undefined}
                                                selectedGroup={selectedGroup}
                                                selectionPass={includeCategory}
                                                onSelectionChange={handleSelectionChange}
                                                groupList={groupList.map(
                                                    (item: { groupid: string; groupname: string; userid: string; permissionlevel: number }) => ({
                                                        key: item.groupid,
                                                        text: item.groupname,
                                                        memberlevel: item.permissionlevel
                                                    })
                                                )}
                                                updateCatsState={updateCatsState}
                                                fileSpaceChangeTrigger={fileSpaceChangeTrigger}
                                                getOFromListVal={getOFromList()}
                                                openSpacesEditPanel={() => setIsSpaceEditModalOpen(true)}
                                                openSpacesAnalyticsPanel={openSpacesAnalyticsPanel}
                                                user={user}
                                                loggedInUser={loggedInUser}
                                                onDelFile={() => qGetSpaceFiles()}
                                                onCitationClicked={(filePath: string, integratedCitation: string | null) =>
                                                    onShowCitation(filePath, integratedCitation, 0)
                                                }
                                                onCompareClicked={onCompareClicked}
                                                companyId={activeComp.companyid}
                                                listRefreshRequest={listRefreshRequest}
                                            />
                                        </div>
                                    </PivotItem>
                                    {selectedGroup.permissionlevel > 2 ? (
                                        <PivotItem headerText="Security">
                                            <div className={styles.settingsPivot}>
                                                <SpaceUserListV2
                                                    users={compMbrshps}
                                                    activeComp={activeComp.companyid}
                                                    spaceSecurityUpdate={userMngmntUpdate}
                                                    companies={compFlatList}
                                                />
                                            </div>
                                        </PivotItem>
                                    ) : null}
                                    {selectedGroup.permissionlevel > 2 ? (
                                        // SETTINGS
                                        <PivotItem headerText="Settings">
                                            <div className={styles.setPivWrap}>
                                                <div className={styles.settingsPivot}>
                                                    <SpaceEditForm
                                                        selectedGroup={selectedGroup}
                                                        onEditSpaceSave={onEditSpaceSave}
                                                        defaultPromptTemplateAsInPythonBackend={defaultPromptTemplateAsInPythonBackend}
                                                        defaultGreetingsMessage={defaultGreetingsMessage}
                                                        onGroupDel={onGroupDel}
                                                    />
                                                </div>
                                            </div>
                                        </PivotItem>
                                    ) : null}

                                    {selectedGroup.permissionlevel > 2 && selectedGroup.invoicesgroup ? (
                                        <PivotItem headerText="Extraction Settings">
                                            <div className={styles.setPivWrap}>
                                                <div className={styles.settingsPivot}>
                                                    <FieldSelection
                                                        selectedGroup={selectedGroup}
                                                        loggedInUser={loggedInUser}
                                                        extractionfieldlist={
                                                            selectedGroup?.extractionfieldlist ? JSON.parse(selectedGroup?.extractionfieldlist) : {}
                                                        }
                                                        onEditSpaceSave={onEditSpaceSave}
                                                    />
                                                </div>
                                            </div>
                                        </PivotItem>
                                    ) : null}

                                    {selectedGroup.permissionlevel > 2 ? (
                                        <PivotItem headerText="Analytics">
                                            <div className={styles.anltxc}>
                                                <AnalyticsConvoList keyA={selectedGroup.selectionId} />
                                            </div>
                                        </PivotItem>
                                    ) : null}
                                </Pivot>
                            </div>
                        ) : // Chat
                        isLib === "chat" && config.showDocLibInChatTab == false && initLoad == false ? (
                            <div className={styles.ChatOnlyWrap}>
                                <div className={styles.chatFullScreenUnderWrap}>
                                    <div className={styles.chatFullScreenMessageStream} ref={xplrrMsgStrm}>
                                        <div className={styles.chatMessageStreamInner}>
                                            {isStreaming &&
                                                streamedAnswers.map((streamedAnswer, index) => (
                                                    <div key={index}>
                                                        <UserChatMessage message={streamedAnswer[0]} />
                                                        <div className={styles.chatMessageGpt}>
                                                            {/*// DEFAULT CHAT*/}
                                                            <Answer
                                                                isStreaming={true}
                                                                key={index}
                                                                index={index}
                                                                answer={streamedAnswer[1]}
                                                                isSelected={false}
                                                                onCitationClicked={(
                                                                    filePath: string,
                                                                    integratedCitation: string | null,
                                                                    el: HTMLElement | null,
                                                                    openSBS?: boolean
                                                                ) => {
                                                                    manageCitationOpen(filePath, integratedCitation, index, el, openSBS);
                                                                }}
                                                                onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                                onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                                                onFollowupQuestionClicked={q => makeApiRequest(q)}
                                                                showFollowupQuestions={config.useSuggestFollowupQuestions && answers.length - 1 === index}
                                                                use_integrated_search={integratedSearchCheckForStreaming(
                                                                    streamedAnswer[1].use_integrated_search
                                                                )}
                                                                selectedGroup={selectedGroup}
                                                                isSBS={false}
                                                                clickedCitation={clickedCitation.current || "placeholder_cit_id"}
                                                                totalAnswers={streamedAnswers?.length ?? 0}
                                                                onSendFeedback={onSendFeedback}
                                                                isLoading={isLoading}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            {!isStreaming &&
                                                answers.map((answer, index) => (
                                                    <div key={index}>
                                                        <UserChatMessage message={answer[0]} />
                                                        {/*// DEFAULT CHAT NO STREAMING*/}
                                                        <div className={styles.chatMessageGpt}>
                                                            <Answer
                                                                isStreaming={false}
                                                                key={index}
                                                                index={index}
                                                                answer={answer[1]}
                                                                isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                                                onCitationClicked={(
                                                                    filePath: string,
                                                                    integratedCitation: string | null,
                                                                    el: HTMLElement | null,
                                                                    openSBS?: boolean
                                                                ) => {
                                                                    manageCitationOpen(filePath, integratedCitation, index, el, openSBS);
                                                                }}
                                                                onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                                onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                                                onFollowupQuestionClicked={q => makeApiRequest(q)}
                                                                showFollowupQuestions={config.useSuggestFollowupQuestions && answers.length - 1 === index}
                                                                use_integrated_search={integratedSearchCheck(answer[1].use_integrated_search)}
                                                                selectedGroup={selectedGroup}
                                                                isSBS={false}
                                                                clickedCitation={clickedCitation.current || "placeholder_cit_id"}
                                                                totalAnswers={streamedAnswers?.length ?? 0}
                                                                onSendFeedback={onSendFeedback}
                                                                isLoading={isLoading}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}

                                            {isLoading && (
                                                <>
                                                    <UserChatMessage message={lastQuestionRef.current} />
                                                    <div className={styles.chatMessageGptMinWidth}>
                                                        <AnswerLoading />
                                                    </div>
                                                </>
                                            )}
                                            {error ? (
                                                <>
                                                    <UserChatMessage message={lastQuestionRef.current} />
                                                    <div className={styles.chatMessageGptMinWidth}>
                                                        <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                                    </div>
                                                </>
                                            ) : null}
                                            {!isStreaming && !isLoading && !error && answers.length == 0 && loadingChatModeSwitch == false ? (
                                                <div className={styles.chatMessageGptMinWidthHI}>
                                                    <AnswerHi
                                                        content={
                                                            selectedGroup.custgreetingmessage
                                                                ? `${selectedGroup.custgreetingmessage} \n ${greetingMessageHtml}`
                                                                : `${defaultGreetingsMessage} \n ${greetingMessageHtml}`
                                                        }
                                                        selectedGroup={selectedGroup}
                                                    />
                                                </div>
                                            ) : loadingChatModeSwitch == true ? (
                                                <div className={styles.chatMessageGptMinWidthHI}>
                                                    <div className={styles.typingDots}>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            ) : null}
                                            <div ref={chatMessageStreamEnd} />
                                        </div>
                                    </div>
                                    {/*
                                    <SpeechRecognizer
                                        onRecognized={onRecognized}
                                        naturalConvoEnabledCallback={naturalConvoEnabledCallback}
                                        subscriptionKey="4HxZzJlQRl8fed767TNUDIS5GZjKhIMqqA0pAn1zYm0YLhPPOohxJQQJ99AKAC5RqLJXJ3w3AAAYACOGVviN"
                                        region="westeurope"
                                        possibleLanguages={["en-US", "bg-BG"]}
                                    />
                                    */}

                                    <div className={styles.chatInput}>
                                        <div>
                                            <QuestionInput
                                                clearOnSend
                                                placeholder={window.innerWidth < 400 ? "Start..." : "Start a conversation..."}
                                                disabled={isLoading}
                                                onSend={question => makeApiRequest(question)}
                                                updateCatsState={updateCatsState}
                                                isCancellingMidway={handleIsCancellingMidway}
                                                isStreaming={isStreaming}
                                                permissionLevel={selectedGroup.permissionlevel}
                                                toggleLeftPanel={toggleLeftPanel}
                                                newConverson={newConverson}
                                                listRefreshRequest={listRefreshRequest}
                                                selectedGroup={selectedGroup}
                                                lTiers={lTiers || []}
                                                msgLmtHit={msgLmtHit}
                                            />
                                        </div>

                                        <div className={styles.underInput}>
                                            <div className={styles.chatInfo}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : // Chat + PDF Split View
                        isLib === "chat" && config.showDocLibInChatTab == true && initLoad == false ? (
                            <div className={styles.SBSWrap}>
                                {/*UI CHAT DOC SIDE BY SIDE*/}

                                <div className={styles.sideBySideUnderWrapSBS}>
                                    <div ref={xplrrMsgStrm} className={styles.chatMessageStreamSBS}>
                                        <div className={styles.chatMessageStreamInnerSBS}>
                                            {isStreaming &&
                                                streamedAnswers.map((streamedAnswer, index) => (
                                                    <div key={index}>
                                                        <UserChatMessage message={streamedAnswer[0]} />
                                                        <div className={styles.chatMessageGpt}>
                                                            {/* // SIDEBYSIDE CHAT */}
                                                            <Answer
                                                                isStreaming={true}
                                                                key={index}
                                                                index={index}
                                                                answer={streamedAnswer[1]}
                                                                isSelected={false}
                                                                onCitationClicked={(
                                                                    filePath: string,
                                                                    integratedCitation: string | null,
                                                                    el: HTMLElement | null,
                                                                    openSBS?: boolean
                                                                ) => {
                                                                    manageCitationOpen(filePath, integratedCitation, index, el, openSBS);
                                                                }}
                                                                onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                                onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                                                onFollowupQuestionClicked={q => makeApiRequest(q)}
                                                                showFollowupQuestions={config.useSuggestFollowupQuestions && answers.length - 1 === index}
                                                                use_integrated_search={integratedSearchCheckForStreaming(
                                                                    streamedAnswer[1].use_integrated_search
                                                                )}
                                                                selectedGroup={selectedGroup}
                                                                isSBS={true}
                                                                clickedCitation={clickedCitation.current || "placeholder_cit_id"}
                                                                totalAnswers={streamedAnswers?.length ?? 0}
                                                                onSendFeedback={onSendFeedback}
                                                                isLoading={isLoading}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            {!isStreaming &&
                                                answers.map((answer, index) => (
                                                    <div key={index}>
                                                        <UserChatMessage message={answer[0]} />
                                                        <div className={styles.chatMessageGpt}>
                                                            <Answer
                                                                isStreaming={false}
                                                                key={index}
                                                                index={index}
                                                                answer={answer[1]}
                                                                isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                                                onCitationClicked={(
                                                                    filePath: string,
                                                                    integratedCitation: string | null,
                                                                    el: HTMLElement | null,
                                                                    openSBS?: boolean
                                                                ) => {
                                                                    manageCitationOpen(filePath, integratedCitation, index, el, openSBS);
                                                                }}
                                                                onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                                onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                                                onFollowupQuestionClicked={q => makeApiRequest(q)}
                                                                showFollowupQuestions={config.useSuggestFollowupQuestions && answers.length - 1 === index}
                                                                use_integrated_search={integratedSearchCheck(answer[1].use_integrated_search)}
                                                                selectedGroup={selectedGroup}
                                                                isSBS={true}
                                                                clickedCitation={clickedCitation.current || "placeholder_cit_id"}
                                                                totalAnswers={streamedAnswers?.length ?? 0}
                                                                onSendFeedback={onSendFeedback}
                                                                isLoading={isLoading}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            {isLoading && (
                                                <>
                                                    <UserChatMessage message={lastQuestionRef.current} />
                                                    <div className={styles.chatMessageGptMinWidth}>
                                                        <AnswerLoading />
                                                    </div>
                                                </>
                                            )}
                                            {error ? (
                                                <>
                                                    <UserChatMessage message={lastQuestionRef.current} />
                                                    <div className={styles.chatMessageGptMinWidth}>
                                                        <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                                    </div>
                                                </>
                                            ) : null}

                                            {!isStreaming && !isLoading && !error && answers.length == 0 && loadingChatModeSwitch == false ? (
                                                <>
                                                    <div className={styles.chatMessageGptMinWidthHI}>
                                                        <AnswerHi
                                                            content={
                                                                selectedGroup.custgreetingmessage
                                                                    ? `${selectedGroup.custgreetingmessage} \n ${greetingMessageHtml}`
                                                                    : `${defaultGreetingsMessage} \n ${greetingMessageHtml}`
                                                            }
                                                            selectedGroup={selectedGroup}
                                                        />
                                                    </div>
                                                </>
                                            ) : loadingChatModeSwitch == true ? (
                                                <div className={styles.chatMessageGptMinWidthHI}>
                                                    <div className={styles.typingDots}>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            ) : null}

                                            <div ref={chatMessageStreamEnd} />
                                        </div>
                                    </div>
                                    <div className={styles.chatInputSBS}>
                                        <div>
                                            <QuestionInput
                                                clearOnSend
                                                placeholder={window.innerWidth < 400 ? "Start..." : "Start a conversation..."}
                                                disabled={isLoading}
                                                onSend={question => makeApiRequest(question)}
                                                updateCatsState={updateCatsState}
                                                isCancellingMidway={handleIsCancellingMidway}
                                                isStreaming={isStreaming}
                                                permissionLevel={selectedGroup.permissionlevel}
                                                toggleLeftPanel={toggleLeftPanel}
                                                newConverson={newConverson}
                                                listRefreshRequest={listRefreshRequest}
                                                selectedGroup={selectedGroup}
                                                lTiers={lTiers || []}
                                                msgLmtHit={msgLmtHit}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.xplrrPDFZ_SBS}>
                                    <XplrrPDFSideByChat
                                        startPage={isPDFModalOpen.startPage}
                                        citationFileId={isPDFModalOpen.defaultFile}
                                        xplrrCitationChangeCallback={xplrrCitationChangeCallback}
                                        fileId={isPDFModalOpen.fileId}
                                        refPageNum={isPDFModalOpen.refPageNum}
                                        isCitation={isPDFModalOpen.isCitation}
                                        rndo={isPDFModalOpen.rndo}
                                        searchString={isPDFModalOpen.searchString}
                                        activeGroupState={isPDFModalOpen.activeGroupId}
                                        fileList={fileList}
                                        shouldPlayAudio={shouldPlayAudio}
                                    />
                                </div>
                            </div>
                        ) : // Home Page
                        isLib === "grp" ? (
                            <div className={styles.grpScrn}>
                                <div className={styles.spaceCards}>
                                    {noGroupsShared == true ? (
                                        <div>
                                            <div className={styles.spaceSplashGreetings}>
                                                <div className={styles.hi}>Hi{user.name ? `, ${user.name}` : ""}!</div>
                                                <div className={styles.hiAskAnything}>It seems there are no Assistants shared with you.</div>
                                            </div>
                                            <div>
                                                <NoSpacesCard onCreateClicked={() => setIsSpaceModalOpen(true)} showCreateSpacePrompt={true} />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={styles.groupCardsListOutsideWrap}>
                                            <div className={styles.spaceSplashGreetings}>
                                                <img
                                                    src={activeComp.companyid == "AB" ? kgnfLogoOnly : blankLogoOnly}
                                                    className={styles.kgnfLogoCenter}
                                                    height="58px"
                                                ></img>
                                                {activeComp.companyid == "SE" ? (
                                                    <div className={styles.hiAskAnything}>Your SolarEdge Assistants</div>
                                                ) : (
                                                    <div className={styles.hiAskAnything}>Your Assistants</div>
                                                )}
                                            </div>
                                            <GroupCardsList
                                                key={selectedGroup.oid}
                                                grL={
                                                    groupList && groupList.length > 0
                                                        ? groupList.map(group => ({
                                                              selectedGroup: groupListToSelectedGroup(group)({} as gselectedgroup),
                                                              handleGroupListClick: (groupid: string, groupname: string, permissionlevel: number) => {
                                                                  handleGroupListClick({
                                                                      groupid: groupid,
                                                                      groupname: groupname,
                                                                      permissionlevel: permissionlevel,
                                                                      filecount: group.filecount
                                                                  });
                                                              }
                                                          }))
                                                        : []
                                                }
                                                onNewGroupClick={onNewGroupClick}
                                                compLvl={activeComp.permissionlevel}
                                                permissionLevelsMap={permissionLevels}
                                                newSpacePyro={newSpacePyro}
                                                user={user}
                                                activeComp={activeComp}
                                                navChatLib={navChatLib}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : // Account Settings
                        isLib === "acc" ? (
                            <div>
                                <div className={styles.accountInfoWrap}>
                                    <AccountInfo
                                        key={groupList?.length}
                                        groups={groupList}
                                        user={user}
                                        lTiers={lTiers || []}
                                        activeComp={activeComp}
                                        selectedGroup={selectedGroup}
                                    />
                                </div>
                            </div>
                        ) : // Administrative
                        isLib === "adm" ? (
                            /* Administrative Menu */
                            <div>
                                <Pivot
                                    onLinkClick={handleAdmPivotItemClick}
                                    className={styles.spaceSecurityPivot}
                                    styles={{
                                        root: {
                                            width: "100%",
                                            minWidth: "100%"
                                        },
                                        itemContainer: {
                                            width: "100%"
                                        }
                                    }}
                                    defaultSelectedKey="usersAdm"
                                >
                                    <PivotItem
                                        headerText=""
                                        itemKey="back"
                                        onRenderItemLink={() => (
                                            <div className={styles.backPivotItemIconCont}>
                                                <MdArrowBackIos size={20} />
                                            </div>
                                        )}
                                    />
                                    <PivotItem headerText="Users" itemKey="usersAdm" className={styles.settingsPivot}>
                                        <UserList
                                            key={allUsrs.length}
                                            users={allUsrs}
                                            activeComp={activeComp}
                                            userMngmntUpdate={userMngmntUpdate}
                                            companies={compFlatList}
                                            loggedInUser={loggedInUser}
                                        />
                                    </PivotItem>
                                    <PivotItem headerText="Companies" itemKey="compAdm">
                                        <div className={styles.settingsPivot}>
                                            <CompList
                                                comps={compFlatList}
                                                newActiveComp={handleCompChange}
                                                activeComp={activeComp}
                                                loggedInUser={loggedInUser}
                                            />
                                        </div>
                                    </PivotItem>
                                    <PivotItem headerText="Company Administration" itemKey="compSett">
                                        <div>
                                            <h3 className={styles.modalTitle}>{activeComp.companyname}</h3>
                                            <CompEditForm activeComp={activeComp} selectedGroup={selectedGroup} />
                                        </div>
                                    </PivotItem>
                                </Pivot>
                            </div>
                        ) : noMembership == true ? (
                            <div>
                                <NoSpacesCard onCreateClicked={() => setIsSpaceModalOpen(true)} showCreateSpacePrompt={false} />
                            </div>
                        ) : (
                            <div className={styles.loadingIndicatorCenterScreen}>
                                <div className={styles.typingDots}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* USER SETTINGS FROM ACCOUNT MENU */}
                    <Panel
                        headerText="Settings"
                        isOpen={isConfigPanelOpen}
                        isLightDismiss={true}
                        isBlocking={false}
                        onDismiss={() => setIsConfigPanelOpen(false)}
                        closeButtonAriaLabel="Close"
                        onRenderFooterContent={() => <DefaultButton onClick={() => setIsConfigPanelOpen(false)}>Close</DefaultButton>}
                        isFooterAtBottom={true}
                    >
                        <Checkbox
                            className={styles.chatSettingsSeparator}
                            checked={config.experimentalFeatures}
                            label="Beta Features"
                            onChange={(_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                setConfig(prevConfig => ({
                                    ...prevConfig,
                                    experimentalFeatures: !!checked
                                }));
                            }}
                        />
                        <div className={styles.betaFeaturesDetails}>Show New Features</div>

                        <Checkbox
                            className={styles.chatSettingsSeparator}
                            checked={config.isDark}
                            label="Dark Mode"
                            onChange={(_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                handleIsDark(!!checked);
                                setConfig(prevConfig => ({
                                    ...prevConfig,
                                    isDark: !!checked
                                }));
                            }}
                        />
                        <div className={styles.betaFeaturesDetails}>Apply Dark Theme</div>
                        <div className={styles.versionLabel}>
                            <div>{import.meta.env.VITE_V_TIMESTAMP}</div>
                        </div>
                    </Panel>
                    {/* LEGACY ANALYSIS PANEL (NOT IN USE ANYMORE) */}
                    {answers.length > 0 && activeAnalysisPanelTab && isLib === "chat" && (
                        <AnalysisPanel
                            timestamp={aPanelTimeStamp}
                            className={styles.chatAnalysisPanel}
                            activeCitation={activeCitation}
                            onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                            citationHeight="810px"
                            answer={answers?.[selectedAnswer]?.[1]}
                            activeTab={activeAnalysisPanelTab}
                        />
                    )}
                </div>

                {/*HISTORY LEFT PANEL*/}
                <Panel
                    isOpen={isLeftPanelOpen}
                    onDismiss={toggleLeftPanel}
                    isLightDismiss={true}
                    closeButtonAriaLabel="Close"
                    headerText="Conversations"
                    type={PanelType.customNear}
                    customWidth="400px"
                >
                    <div className={styles.convoListBox}>
                        {convos.map(item => (
                            <ListItemButton
                                key={item.key}
                                onClick={() => {
                                    if (config.showDocLibInChatTab == true) {
                                        setConfig(prevConfig => ({
                                            ...prevConfig,
                                            showDocLibInChatTab: false
                                        }));
                                    }
                                    setConversation(item.key);
                                }}
                                onDelete={() => histItemHandleDelete(item.key)}
                                onEdit={newText => histItemHandleEdit(item.key, newText)}
                                text={item.name}
                                className={`${styles.conversationsList} ${activeConvoKey === item.key ? styles.listItemBold : ""}`}
                                disabled={isLoading || isStreaming}
                            />
                        ))}
                    </div>
                </Panel>

                {/*SPACES LEFT PANEL*/}
                <SpacePanel
                    isSpacePanelOpen={isSpacePanelOpen}
                    toggleSpacePanel={toggleSpacePanel}
                    groupList={groupList?.sort((a, b) => {
                        if (a.oid < b.oid) {
                            return 1;
                        }
                        if (a.oid > b.oid) {
                            return -1;
                        }
                        return 0;
                    })}
                    selectedGroup={selectedGroup}
                    setGrpTab={setGrpTab}
                    handleLeftSpacePanelClick={(item: any, aCK?: string) => {
                        handleLeftSpacePanelClick(item, aCK);
                    }}
                    recentConvosAccrossSpaces={recentConvosAccrosSpaces || []}
                    activeConvoKey={activeConvoKey}
                />

                {/* GLOBAL User List Modal*/}
                <Modal
                    isOpen={isUserListModalOpen}
                    onDismiss={() => {
                        setIsUserListModalOpen(false);
                    }}
                    isBlocking={false}
                    containerClassName={styles.glUserLostModal}
                >
                    <h3 className={styles.modalTitle}>Global Users List</h3>
                    <UserList
                        users={allUsrs}
                        activeComp={activeComp}
                        userMngmntUpdate={userMngmntUpdate}
                        companies={compFlatList}
                        loggedInUser={loggedInUser}
                    />{" "}
                </Modal>

                {/* Companies Dropdown*/}
                <Modal
                    isOpen={isCompListModalOpen}
                    onDismiss={() => setIsCompListModalOpen(false)}
                    isBlocking={false}
                    containerClassName={styles.spaceAddModal}
                >
                    <CompList comps={compFlatList} newActiveComp={handleCompChange} activeComp={activeComp} loggedInUser={loggedInUser} />
                </Modal>

                {/* ADD NEW SPACE MODAL*/}
                <AddSpaceModal
                    isSpaceModalOpen={isSpaceModalOpen}
                    closeSpacesPanel={() => setIsSpaceModalOpen(false)}
                    isNewSpaceInfoBip={isNewSpaceInfoBip}
                    user={user}
                    addSpace={addSpace}
                />
                {/*SPACE ANALYTICS*/}
                <Modal
                    isOpen={isSpaceAnalyticsModalOpen}
                    onDismiss={() => {
                        setIsSpaceAnalyticsModalOpen(false);
                    }}
                    isBlocking={false}
                    containerClassName={styles.spaceAnalyticsModal}
                >
                    <h3 className={styles.modalTitle}>Space Analytics</h3> {/*ANALYTICS Space Modal*/}
                    <AnalyticsConvoList keyA={selectedGroup.selectionId} />
                </Modal>

                {/*PDF MODAL*/}
                <RModal
                    isOpen={isPDFModalOpen.isOpen}
                    onRequestClose={onXplrrPDFDismiss}
                    shouldCloseOnOverlayClick={true}
                    closeTimeoutMS={200}
                    className={{
                        base: styles.modalContent,
                        afterOpen: styles.modalContentAfterOpen,
                        beforeClose: styles.modalContentBeforeClose
                    }}
                    overlayClassName={styles.modalOverlay}
                    shouldFocusAfterRender={true}
                    appElement={document.getElementById("rro")!}
                >
                    <XplrrPDF
                        startPage={isPDFModalOpen.startPage}
                        citationFileId={isPDFModalOpen.defaultFile}
                        xplrrCitationChangeCallback={xplrrCitationChangeCallback}
                        fileId={isPDFModalOpen.fileId}
                        refPageNum={isPDFModalOpen.refPageNum}
                        isCitation={isPDFModalOpen.isCitation}
                        rndo={isPDFModalOpen.rndo}
                        searchString={isPDFModalOpen.searchString}
                        activeGroupState={isPDFModalOpen.activeGroupId}
                        fileList={fileList}
                        shouldPlayAudio={shouldPlayAudio}
                        isIframe={isIframe}
                        onXplrrPDFDismiss={onXplrrPDFDismiss}
                        onDataHighlight={onDataHighlight}
                        isLib={isLib}
                        loggedInUser={loggedInUser}
                        onInvValueUpdate={listRefreshRequest}
                    />
                </RModal>
                {/*DIFF PDF MODAL*/}
                <RModal
                    isOpen={isPDFDiffModalOpen.isOpen}
                    onRequestClose={onXplrrDiffPDFDismiss}
                    shouldCloseOnOverlayClick={true}
                    closeTimeoutMS={200}
                    className={{
                        base: styles.modalContent,
                        afterOpen: styles.modalContentAfterOpen,
                        beforeClose: styles.modalContentBeforeClose
                    }}
                    overlayClassName={styles.modalOverlay}
                    shouldFocusAfterRender={true}
                >
                    <XplrrPDFDiff
                        fileItem1={isPDFDiffModalOpen.fl1}
                        fileItem2={isPDFDiffModalOpen.fl2}
                        selectedGroup={selectedGroup}
                        rndo={isPDFDiffModalOpen.rndo}
                        diff={isPDFDiffModalOpen.diff}
                    />
                </RModal>
                {/* INVITE USER */}
                {isShareCalloutVisible && (
                    <div className={styles.inviteCallout}>
                        <InviteUserCallout
                            isOpen={isShareCalloutVisible}
                            onDismiss={() => setIsShareCalloutVisible(!isShareCalloutVisible)}
                            addUserButtonRef={addUserButtonRef}
                            inviteUser={inviteUser}
                            selectedGroup={selectedGroup}
                            activeConvoKey={activeConvoKey}
                        />
                    </div>
                )}
            </div>
        </ThemeProvider>
    );
};

export default Chat;
