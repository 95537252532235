import React, { useEffect, useState } from "react";
import "./NoSpacesCard.css";

interface Props {
    onCreateClicked: () => void;
    showCreateSpacePrompt: boolean;
}

const NoSpacesCard = ({ onCreateClicked, showCreateSpacePrompt }: Props) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(true);
    }, []);

    return (
        <div className={`no-spaces-card ${show ? "show" : ""}`}>
            <div className={`no-spaces-title`}>No Shared Assistants</div>
            <p>You have no assistants shared with you at the moment.</p>
            <button className={`createSpaceButton`} onClick={onCreateClicked}>
                Create an Assistant
            </button>
        </div>
    );
};

export default NoSpacesCard;
