import React, { useState } from "react";
import "./ButtonHold.css";
import { BsSend } from "react-icons/bs";
import { BsSendCheck } from "react-icons/bs";
import { RiSendPlaneLine } from "react-icons/ri";

interface ButtonHoldProps {
    confirmed: boolean;
    confirm: () => void;
}

const ButtonHold = ({ confirmed, confirm }: ButtonHoldProps) => {
    const [confirmedState, setConfirmedState] = useState<boolean>(confirmed);

    const duration = 1600;

    const success = (button: any) => {
        button.classList.add("success");
        setConfirmedState(true);
        confirm();
    };

    const handleMouseDown = (ev: any, button: any) => {
        if (button && !button.classList.contains("process")) {
            button.classList.add("process");
            button.timeout = setTimeout(() => success(button), duration);
        }
    };

    const handleMouseUp = (button: any) => {
        if (button) {
            button.classList.remove("process");
            clearTimeout(button.timeout);
        }
    };

    return (
        <button
            className={`button-hold ${confirmed ? "success" : ""}`}
            onMouseDown={e => handleMouseDown(e, e.currentTarget)}
            onTouchStart={e => handleMouseDown(e, e.currentTarget)}
            onKeyDown={e => {
                if (e.key === " " && !e.currentTarget.classList.contains("process")) {
                    handleMouseDown(e, e.currentTarget);
                }
            }}
            onMouseUp={e => handleMouseUp(e.currentTarget)}
            onMouseOut={e => handleMouseUp(e.currentTarget)}
            onTouchEnd={e => handleMouseUp(e.currentTarget)}
            onKeyUp={e => {
                if (e.key === " ") handleMouseUp(e.currentTarget);
            }}
        >
            <div>
                <svg className="icon" viewBox="0 0 16 16">
                    <RiSendPlaneLine size={16} />
                </svg>
                <svg className="progress" viewBox="0 0 32 32">
                    <circle r="8" cx="16" cy="16" />
                </svg>
                <svg className="tick" viewBox="0 0 24 24">
                    <polyline points="18,7 11,16 6,12" />
                </svg>
            </div>
            <span className={`${confirmedState == true ? "confTextTrue" : "confTextFalse"}`}>{confirmedState == false ? "Confirm" : "Confirmed"}</span>
        </button>
    );
};

export default ButtonHold;
