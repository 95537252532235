import {
    gfile,
    ggroup,
    gselectedgroup,
    gcompflat,
    ispdfmodalopen,
    gXplrrCitationChangeCallback,
    gChatlogItem,
    gConf,
    gnewgroup,
    gsuggestedquestions,
    gtiers,
    grecentconvosaccrossspaces,
    gfeedback,
    ispdfdiffmodalopen,
    gdiff
} from "../../interfaces";
import * as DarkReader from "darkreader";

export const groupListToSelectedGroup = (group?: ggroup) => (selectedgroup: gselectedgroup) => {
    return {
        selectionId: group ? group.groupid || "" : "",
        selectionText: group ? group.groupname || "" : "",
        permissionlevel: group ? group.permissionlevel || 0 : 0,
        description: group ? group.description || "" : "",
        sharewithcode: group ? group.sharewithcode || false : false,
        filecount: group ? group.filecount || 0 : 0,
        custaiinst: group ? group.custaiinst || "" : "",
        custgreetingmessage: group ? group.custgreetingmessage || "" : "",
        isintegrateddata: group ? group.isintegrateddata || 0 : 0,
        allowyoutube: group ? group.allowyoutube ?? 1 : 1,
        allowweb: group ? group.allowweb ?? 1 : 1,
        enabledocaudio: group ? group.enabledocaudio ?? 1 : 1,
        retrievecount: group ? group.retrievecount ?? 5 : 5,
        sectionoverlap: group ? group.sectionoverlap ?? 200 : 200,
        maxsectionlength: group ? group.maxsectionlength ?? 3000 : 3000,
        sentencesearchlimit: group ? group.sentencesearchlimit ?? 200 : 200,
        inscope: group ? group.inscope ?? 1 : 1,
        temperature: group ? group.temperature ?? 0.7 : 0.7,
        responsetokenlimit: group ? group.responsetokenlimit ?? 3024 : 3024,
        disablecitationhighlighting: group ? group.disablecitationhighlighting ?? 0 : 0,
        strictness: group ? group.strictness ?? 3 : 3,
        invoicesgroup: group ? group.invoicesgroup ?? 0 : 0,
        questionsuggestions: group ? group.questionsuggestions ?? 0 : 0,
        nosidebysideview: group ? group.nosidebysideview ?? 0 : 0,
        isowner: group ? group.isowner ?? 0 : 0,
        dailymsgs: group ? group.dailymsgs ?? 0 : 0,
        premiumby: group ? group.premiumby ?? "" : "",
        oid: group ? group.oid ?? 0 : 0,
        tier: group ? group.tier ?? 0 : 0,
        premwhen: group ? group.premwhen ?? 0 : 0,
        companyid: group ? group.companyid ?? "" : "",
        apikey: group ? group.apikey ?? "" : "",
        producecontentlist: group ? group.producecontentlist ?? 0 : 0,
        producequestions: group ? group.producequestions ?? 0 : 0,
        assistanttype: group ? group.assistanttype ?? "anya" : "anya",
        showexamplequestionsonstart: group ? group.showexamplequestionsonstart ?? 0 : 0,
        headerimageurl: group ? group.headerimageurl ?? "" : "",
        manualquestionsuggestions: group ? group.manualquestionsuggestions ?? "" : "",
        usedocintel: group ? group.usedocintel ?? 0 : 0,
        extractionfieldlist: group ? group.extractionfieldlist ?? "" : "",
        allowaudiodocentry: group ? group.allowaudiodocentry ?? 0 : 0,
        forceocr: group ? group.forceocr ?? 0 : 0,
        allowvideofiles: group ? group.allowvideofiles ?? 0 : 0
    };
};
export const getTokenMail = (token: any) => {
    return token.idTokenClaims?.emails?.length > 0 ? token.idTokenClaims?.emails[0] : token.idTokenClaims?.email;
};
// Dark Mode
export const handleIsDark = (isDark: boolean) => {
    if (isDark == true) {
        DarkReader.enable({
            mode: 1,
            brightness: 100,
            contrast: 100,
            grayscale: 0,
            sepia: 0,
            useFont: false,
            fontFamily: "Urbanist, Roboto",
            textStroke: 0,
            darkSchemeBackgroundColor: "#252525",
            darkSchemeTextColor: "#e8e6e3",
            lightSchemeBackgroundColor: "#dcdad7",
            lightSchemeTextColor: "#181a1b",
            scrollbarColor: "auto",
            selectionColor: "auto",
            styleSystemControls: true
        });
    } else {
        DarkReader.disable();
    }
};
// File Cache Related
export const getLatestModifiedFile = (flCache?: gfile[]) => {
    return flCache?.reduce((latest, current) => {
        const currentDate = new Date(current.modified);
        return currentDate > new Date(latest.modified) ? current : latest;
    });
};
// Citations Related
export const extractPageValue = (fileCitationPath: string) => {
    const match = fileCitationPath.match(/page=(\d+)/);
    return match ? match[1] : "";
};
// Citations Related
export const extractFileIDValue = (fP: string) => {
    let fid = "";
    const fidSplit = fP ? fP.split("_fid=") : [];
    if (fidSplit.length > 1) {
        return fidSplit[1].trim();
    } else {
        return "";
    }
};
// Initial Load Related
export const getTokenAccountID = (token: any) => {
    return token.uniqueId || token.localAccountId || token.oid;
};
export const cleanFileIDString = (fileP: string) => {
    // ViomeRecommendations_F2849CD32684.pdf#page=6_fid=379, ViomeRecommendations_F2849CD32684.pdf_page=31_fid=379
    return fileP.replace("#", "_");
};
export const checkWeAreInGeneralPlace = (isLib: string) => {
    return isLib == "grp" || isLib == "acc" || isLib == "adm";
};
