import React from "react";
import { DocumentCard } from "@fluentui/react/lib/DocumentCard";
import { Stack } from "@fluentui/react/lib/Stack";
import { Shimmer } from "@fluentui/react/lib/Shimmer";

// Define the interface for props if needed
interface ShimmerDocumentCardProps {
    // You can define props here if needed
}

const ShimmerDocumentCard: React.FC<ShimmerDocumentCardProps> = () => {
    return (
        <DocumentCard
            styles={{
                root: {
                    width: 300,
                    marginBottom: 0,
                    marginTop: 20,
                    borderRadius: 10,
                    padding: "10px 20px 20px 20px",
                    position: "relative",
                    backgroundColor: "rgba(193, 200, 214,0.16)",
                    selectors: {
                        ":hover": {
                            transform: "translateY(-5px)",
                            transition: "transform 0.2s ease-in-out"
                        },
                        ":hover:after": {
                            border: "none"
                        }
                    }
                }
            }}
        >
            <Stack tokens={{ childrenGap: 10 }}>
                <Shimmer
                    isDataLoaded={false}
                    styles={{
                        root: {
                            marginTop: "10px",
                            textAlign: "left",
                            paddingBottom: 5,
                            font: "normal normal normal 12px/19px Urbanist, Roboto",
                            color: "#797C95"
                        }
                    }}
                />
                <Shimmer
                    isDataLoaded={false}
                    styles={{
                        root: {
                            textAlign: "left",
                            paddingBottom: 5,
                            font: "normal normal normal 16px/19px Urbanist, Roboto",
                            color: "#797C95"
                        }
                    }}
                />
                <Shimmer
                    isDataLoaded={false}
                    styles={{
                        root: {
                            textAlign: "left",
                            marginBottom: "0px",
                            font: "normal normal normal 16px/19px Urbanist, Roboto",
                            color: "#797C95",
                            width: "87%"
                        }
                    }}
                />
            </Stack>
        </DocumentCard>
    );
};

export default ShimmerDocumentCard;
