import { useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { gChatlogItem } from "../../interfaces";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
type ChartAnalyticsBarProps = {
    data: gChatlogItem[];
};
interface UserData {
    [usermail: string]: number;
}
interface UserCounts {
    [key: string]: UserData;
}
interface Dataset {
    label: string;
    data: number[];
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
}
const ChartAnalyticsBar = ({ data }: ChartAnalyticsBarProps) => {
    const { chartData } = useMemo(() => {
        const userCounts: UserCounts = {};

        data.forEach(item => {
            if (!userCounts[item.key]) {
                userCounts[item.key] = {};
            }
            userCounts[item.key][item.usermail] = (userCounts[item.key][item.usermail] || 0) + 1;
        });

        const labels = Object.keys(userCounts);
        const datasets = Object.entries(userCounts).reduce<Dataset[]>((acc, [key, countByUser]) => {
            Object.entries(countByUser).forEach(([usermail, count]) => {
                let dataset = acc.find(ds => ds.label === usermail);
                if (!dataset) {
                    dataset = {
                        label: usermail,
                        data: new Array(labels.length).fill(0),
                        backgroundColor: `hsla(${Math.random() * 360}, 70%, 70%, 0.2)`,
                        borderColor: `hsla(${Math.random() * 360}, 70%, 70%, 1)`,
                        borderWidth: 1
                    };
                    acc.push(dataset);
                }
                dataset.data[labels.indexOf(key)] = count;
            });
            return acc;
        }, []);

        return { chartData: { labels, datasets } };
    }, [data]);

    const options = {
        scales: {
            x: { stacked: true },
            y: { stacked: true }
        }
    };

    return chartData.labels.length ? <Bar data={chartData} options={options} /> : null;
};
export default ChartAnalyticsBar;
