import React from "react";
import { useRef, useState, useEffect } from "react";
import { DetailsList, IColumn, DetailsListLayoutMode, ConstrainMode } from "@fluentui/react/lib/DetailsList";
import styles from "./UserList.module.css";
import { PrimaryButton, Dropdown, IDropdown, IDropdownStyles, IStyle } from "@fluentui/react";
import InviteModal from "../InviteModal/InviteModal";

import { loginRequest } from "../../authConfig";
import { gactivecomp, gallusrs, gloggedinuser } from "../../interfaces";

interface IUserListProps {
    users: gallusrs[];
    userMngmntUpdate: (timestamp: string) => void;
    activeComp: gactivecomp;
    companies: { companyid: string; companyname: string; permissionlevel: number }[];
    loggedInUser: gloggedinuser;
}

const UserList: React.FC<IUserListProps> = ({ users, userMngmntUpdate, activeComp, companies, loggedInUser }) => {
    const [newInviteUser, setNewInviteUser] = useState<{ userMail: string; userDisplayName: string }>({ userMail: "", userDisplayName: "" });
    const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
    const [activeCompState, setActiveCompState] = useState(activeComp.companyid);
    const [usersState, setUsersState] = useState(users);

    useEffect(() => {
        setUsersState(users);
    }, [users]);

    const permMap: Record<number, string> = {
        0: "No Access",
        1: "Viewer",
        2: "Member",
        3: "Owner"
    };
    const licMap: Record<number, string> = {
        1: "Free",
        2: "Professional",
        3: "Enterpise"
    };
    const dropdownStyles: Partial<IDropdownStyles> = {
        root: {
            padding: "0",
            margin: "0",
            height: "20px",
            marginTop: "-5px",
            borderRadius: "5px",
            selectors: {
                ":focus": {
                    borderRadius: "5px"
                },
                ":hover": {
                    borderRadius: "5px"
                },
                ":active": {
                    borderRadius: "5px"
                }
            }
        },
        label: {
            fontFamily: "Urbanist",
            fontSize: "7px",
            borderRadius: "5px"
        },
        title: {
            fontFamily: "Urbanist",
            borderRadius: "5px",
            fontSize: "12px",
            height: "30px"
        },

        dropdown: {
            borderRadius: "5px",
            selectors: {
                ":focus": {
                    borderRadius: "5px !important",
                    borderWidth: "2px !important",
                    borderColor: "#333 !important"
                },
                ":hover": {
                    borderRadius: "5px !important",
                    borderWidth: "2px !important",
                    borderColor: "#333 !important"
                },
                ":active": {
                    borderRadius: "5px !important",
                    borderWidth: "2px !important",
                    borderColor: "#333 !important"
                },
                ":after": {
                    borderRadius: "5px !important",
                    borderWidth: "2px !important",
                    borderColor: "#333 !important"
                },
                ":before": {
                    borderRadius: "5px !important",
                    borderWidth: "2px !important",
                    borderColor: "#333 !important"
                }
            }
        },
        dropdownItem: {
            fontFamily: "Urbanist",
            borderRadius: "5px",
            fontSize: "12px",
            height: "30px"
        },

        dropdownItemSelected: {
            fontFamily: "Urbanist",
            borderRadius: "5px",
            fontSize: "12px",
            height: "30px"
        },
        caretDown: {
            borderRadius: "5px"
        }
    };

    const columns: IColumn[] = [
        { key: "column1", name: "Mail", fieldName: "usermail", minWidth: 100, maxWidth: 200, isResizable: true },
        { key: "column2", name: "Username", fieldName: "username", minWidth: 100, maxWidth: 200, isResizable: true },
        { key: "column3", name: "Company", fieldName: "companyid", minWidth: 100, maxWidth: 200, isResizable: true },

        {
            key: "column5",
            name: "Permission",
            fieldName: "permissionlevel",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender: (item: gallusrs) => {
                const [isDropdownVisible, setIsDropdownVisible] = useState(false);
                const dropdownRef = useRef<IDropdown | null>(null);
                const setADV = (vsbl: boolean) => {
                    console.log("PRMS4", activeComp.permissionlevel);
                    if (activeComp.permissionlevel >= 3) {
                        setIsDropdownVisible(vsbl);
                    }
                };
                useEffect(() => {
                    if (isDropdownVisible && dropdownRef.current) {
                        dropdownRef.current.focus(); // Focus Dropdown so onBLur works
                    }
                }, [isDropdownVisible]);
                return isDropdownVisible ? (
                    <Dropdown
                        styles={dropdownStyles}
                        componentRef={dropdownRef}
                        selectedKey={item.permissionlevel}
                        options={[
                            { key: 1, text: "Viewer" },
                            { key: 2, text: "Member" },
                            { key: 3, text: "Owner" }
                        ]}
                        onBlur={() => {
                            //console.log("dismissed");
                            setIsDropdownVisible(false);
                        }}
                        onChange={(event, option) => {
                            updatePermissionLevel(item.userid, item.companyid, option!.key as number);
                            setIsDropdownVisible(false);
                        }}
                    />
                ) : (
                    <div onClick={() => setADV(true)}>
                        <span
                            className={`${
                                (item.permissionlevel ?? 0) == 0
                                    ? styles.permDropNoAccess
                                    : item.permissionlevel == 1
                                    ? styles.permDropViewer
                                    : item.permissionlevel == 2
                                    ? styles.permDropMember
                                    : styles.permDropOwner
                            }`}
                        >
                            {permMap[item.permissionlevel ?? 0]}
                        </span>
                    </div>
                );
            }
        },
        {
            key: "column6",
            name: "License",
            fieldName: "tier",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender: (item: gallusrs) => {
                const [isDropdownVisible, setIsDropdownVisible] = useState(false);
                const dropdownRef = useRef<IDropdown | null>(null);
                const setADV = (vsbl: boolean) => {
                    console.log("PRMS4", activeComp.permissionlevel);
                    if (activeComp.permissionlevel > 3) {
                        console.log("PRMS4", activeComp.permissionlevel);
                        setIsDropdownVisible(vsbl);
                    }
                };
                useEffect(() => {
                    if (isDropdownVisible && dropdownRef.current) {
                        dropdownRef.current.focus(); // Focus Dropdown so onBLur works
                    }
                }, [isDropdownVisible]);
                return isDropdownVisible ? (
                    <Dropdown
                        styles={dropdownStyles}
                        componentRef={dropdownRef}
                        selectedKey={item.tier ?? 1}
                        options={[
                            { key: 1, text: "Free" },
                            { key: 2, text: "Professional" },
                            { key: 3, text: "Enterpise" }
                        ]}
                        onBlur={() => {
                            //console.log("dismissed");
                            setIsDropdownVisible(false);
                        }}
                        onChange={(event, option) => {
                            updateTier(item.userid, item.companyid, option!.key as number);
                            setIsDropdownVisible(false);
                        }}
                    />
                ) : (
                    <div onClick={() => setADV(true)}>
                        <span
                            className={`${
                                (item.tier ?? 1) == 1 ? styles.permDropViewer : (item.tier ?? 1) == 2 ? styles.permDropMember : styles.permDropOwner
                            }`}
                        >
                            {licMap[item.tier ?? 1]}
                        </span>
                    </div>
                );
            }
        }
    ];
    const getHeader = () => {
        return {
            ...(loggedInUser?.token ? { Authorization: `Bearer ${loggedInUser.token}` } : {}),
            "X-MS-CLIENT-PRINCIPAL-NAME": loggedInUser?.mail ? loggedInUser.mail : "",
            "X-MS-CLIENT-PRINCIPAL-ID": loggedInUser?.userId ? loggedInUser.userId : "",
            "Content-Type": "application/json"
        };
    };
    const openInviteModal = () => {
        setNewInviteUser({ userMail: "", userDisplayName: "" });
        setIsInviteModalOpen(true);
    };
    const closeInviteModal = () => {
        setIsInviteModalOpen(false);
    };
    const inviteUser = async () => {
        fetch("/addUser", {
            // @UI Add User (Invite User)
            method: "POST",
            headers: getHeader(),
            body: JSON.stringify({ guest_email: newInviteUser.userMail, display_name: newInviteUser.userDisplayName, comp: activeComp.companyid })
        }).then(Response => {
            userMngmntUpdate(new Date().toISOString());
        });

        return "";
    };
    const updatePermissionLevel = async (userid: string, companyid: string, permissionlevel: number) => {
        fetch("/qUpdateUserPermissionLevel", {
            // @UI Update User
            method: "POST",
            headers: getHeader(),
            body: JSON.stringify({ userid: userid, companyid: companyid, permissionlevel: permissionlevel })
        }).then(Response => {
            setUsersState(usersState.map(a => (a.userid === userid ? { ...a, permissionlevel: permissionlevel } : a)));
            //userMngmntUpdate(new Date().toISOString());
        });
        setUsersState(usersState.map(a => (a.userid === userid ? { ...a, permissionlevel: permissionlevel } : a)));
    };
    const updateTier = async (userid: string, companyid: string, tier: number) => {
        fetch("/qUpdateUserPermissionLevel", {
            // @UI Update User
            method: "POST",
            headers: getHeader(),
            body: JSON.stringify({ userid: userid, companyid: companyid, tier: tier })
        }).then(Response => {
            setUsersState(usersState.map(a => (a.userid === userid ? { ...a, tier: tier } : a)));
        });
        setUsersState(usersState.map(a => (a.userid === userid ? { ...a, tier: tier } : a)));
    };
    const onCompDropdownChange = (event: React.FormEvent<HTMLDivElement>, item: any) => {
        setActiveCompState(item.key as string);
    };
    const getUsers = usersState; //.filter(a => a.companyid === activeCompState);

    const controlStyles = {
        root: {
            paddingTop: "0px !important",
            overflowY: "scroll"
        },
        commandButton: {
            marginRight: "20px",
            marginBottom: "20px"
        },
        headerWrapper: {
            position: "sticky",
            top: 0,
            zIndex: 1,
            borderRadius: "5px 5px 0 0"
        } as IStyle,
        detailsHeader: { padding: "0px" }
    };
    return (
        <div className={styles.userListWrap}>
            <div className={styles.userListHead}>
                {/*
                <div>
                    <div className={styles.container}>
                        <Dropdown
                            className={styles.dropd}
                            label="Company"
                            selectedKey={activeCompState}
                            onChange={onCompDropdownChange}
                            options={companies.map((a, i) => {
                                return { key: a.companyid, text: a.companyname };
                            })}
                        />
                    </div>
                </div>
                */}

                {/*
                    <PrimaryButton
                        text="Add User"
                        iconProps={{ iconName: "AddFriend" }}
                        onClick={() => {
                            setIsInviteModalOpen(true);
                            // Add your onClick logic here
                        }}
                    />
                */}
            </div>
            <div style={{ height: "calc(100vh - 180px)", overflow: "auto" }}>
                <DetailsList
                    className={styles.glUserListList}
                    items={usersState}
                    columns={columns}
                    setKey="set"
                    layoutMode={DetailsListLayoutMode.justified} // Adjust layout mode as needed
                    constrainMode={ConstrainMode.horizontalConstrained}
                    selectionPreservedOnEmptyClick={true}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    styles={controlStyles}
                    onShouldVirtualize={() => usersState?.length > 80}
                />
            </div>
            <InviteModal
                isInviteModalOpen={isInviteModalOpen}
                closeInviteModal={closeInviteModal}
                inviteUser={inviteUser}
                setNewInviteUser={setNewInviteUser}
            />
        </div>
    );
};

export default UserList;
