import { New24Regular } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";
import { TooltipHost, DirectionalHint } from "@fluentui/react";
import styles from "./ClearChatButton.module.css";

interface Props {
    className?: string;
    onClick: () => void;
    disabled?: boolean;
}
export const ClearChatButton = ({ className, disabled, onClick }: Props) => {
    return (
        <div className={`${styles.container} ${className ?? ""}`}>
            <TooltipHost content="Start new Conversation" directionalHint={DirectionalHint.topAutoEdge}>
                <Button className={styles.buttonClear} icon={<New24Regular />} disabled={disabled} onClick={onClick}></Button>
            </TooltipHost>
        </div>
    );
};
