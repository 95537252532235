import React, { useContext } from "react";
import { useState } from "react";
import {
    Stack,
    Text,
    List,
    DefaultButton,
    ChoiceGroup,
    IChoiceGroupOption,
    ProgressIndicator,
    DocumentCard,
    DocumentCardTitle,
    DocumentCardDetails,
    IStackTokens,
    mergeStyles,
    Toggle,
    IToggleProps,
    TooltipHost,
    DirectionalHint,
    Modal
} from "@fluentui/react";
import { loginRequest } from "../../authConfig";
import styles from "./AccountInfo.module.css";
import { ggroup, guser, gtiers, gactivecomp, gselectedgroup } from "../../interfaces";
import { MdOutlineWorkspacePremium } from "react-icons/md";
import { toast } from "react-toastify";
import ProductPage from "../ProductPage/ProductPage";
import { GlobalContext } from "../../GlobalContext";

const stackTokens: IStackTokens = {
    childrenGap: 20
};

interface ISpace {
    groups: ggroup[];
    user: guser;
    lTiers: gtiers[];
    activeComp: gactivecomp;
    selectedGroup: gselectedgroup;
}
const tooltipStyles = {
    styles: {
        root: {
            borderRadius: "5px"
        },
        beakCurtain: {
            borderRadius: "5px"
        },
        calloutMain: {
            borderRadius: "5px"
        }
    }
};
const AccountInfo: React.FC<ISpace> = ({ groups, user, lTiers, activeComp, selectedGroup }) => {
    const [groupsState, setGroupsState] = useState(groups);
    const [isLicModalOpen, setIsLicModalOpen] = useState(false);
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
    const { loggedInUser } = useContext(GlobalContext);
    const [currentPlanState, setCurrentPlanState] = useState(lTiers[user.tier ?? 0]);
    const [premiumSpacesLimit, setPremiumSpacesLimit] = useState(lTiers.filter(tier => tier.tier == user.tier)[0]?.spslmt);

    console.log("USR¦AA", user);

    const getHeader = () => {
        return {
            ...(loggedInUser?.token ? { Authorization: `Bearer ${loggedInUser.token}` } : {}),
            "X-MS-CLIENT-PRINCIPAL-NAME": loggedInUser?.mail ? loggedInUser.mail : "",
            "X-MS-CLIENT-PRINCIPAL-ID": loggedInUser?.userId ? loggedInUser.userId : "",
            "Content-Type": "application/json"
        };
    };

    const accountPerks = [
        { text: "Unlimited Users", tier: 0 },
        { text: `${lTiers[0].demosps} Demo Spaces`, tier: 0 },
        { text: `${lTiers[0].msgsplmt} messages per Day per Space`, tier: 0 },
        { text: `Up to ${lTiers[0].docsplmt} Documents per Space`, tier: 0 },

        { text: "Unlimited Users", tier: 1 },
        { text: `${lTiers[1].spslmt} Premium Spaces`, tier: 1 },
        { text: "Unlimited Messages", tier: 1 },
        { text: "Up to 1GB per Space", tier: 1 },

        { text: "Unlimited Users", tier: 2 },
        { text: "3 Premium Spaces", tier: 2 },
        { text: "Unlimited Messages", tier: 2 },
        { text: "Up to 1GB per Space", tier: 2 }
    ];

    const onRenderCell = (item?: string, index?: number): JSX.Element => {
        return <Text key={index}>• {item}</Text>;
    };
    const onRenderCellProduct = (item?: string, index?: number): JSX.Element => {
        return (
            <div className={styles.productFeatureItem} key={index}>
                • {item}
            </div>
        );
    };

    const updateSpaceLic = async (userid: string, spaceid: string, prmtr: string) => {
        fetch("/qUpdateUserPermissionLevel", {
            method: "POST",
            headers: getHeader(),
            body: JSON.stringify({ userid: userid, groupid: spaceid, prmtr: prmtr })
        }).then(Response => {
            setGroupsState(groupsState.map(a => (a.groupid === spaceid ? { ...a, premiumby: prmtr } : a)));
        });
        setGroupsState(groupsState.map(a => (a.groupid === spaceid ? { ...a, premiumby: prmtr } : a)));
    };
    const joinWaitlist = async (reqtype: string) => {
        fetch("/qJoinWaitlist", {
            // @UI Update User
            method: "POST",
            headers: getHeader(),
            body: JSON.stringify({ userid: user.userId, mail: user.userMail, reqtype: reqtype })
        }).then(Response => {
            if (reqtype == "trial") {
                toast.success("We recieved your request for Trial, We will contact you soon");
            } else {
                toast.success("We Added you to Waitlist for Premium Plans");
            }
        });
    };
    const handleChange = (checked: boolean | undefined, spaceid: string, premiumby: string) => {
        updateSpaceLic(user.userId, spaceid, checked ? user.userId : "");
    };
    const getLic = async () => {
        fetch("/qGetLicense", {
            method: "POST",
            headers: getHeader(),
            body: JSON.stringify({ userid: user.userId })
        }).then(Response => {
            toast.success("We recieved your request for Professional License, We will contact you soon");
        });
    };
    return (
        <div style={{ position: "relative", overflow: "hidden" }}>
            <Modal
                isOpen={isLicModalOpen}
                onDismiss={() => {
                    setIsLicModalOpen(false);
                }}
                isBlocking={false}
                containerClassName={styles.produltModalContainer}
            >
                <Stack
                    className={styles.prodModalCard}
                    style={{
                        width: "100%",
                        maxWidth: "1000px",
                        margin: "auto",
                        flex: "1"
                    }}
                >
                    <Text variant="xxLarge" className={styles.header}>
                        Profesional Plan
                    </Text>
                    <Stack className={styles.section}>
                        <Text variant="large">Account Mail: {user.userMail}</Text>
                        <Text variant="large">Current Plan: {currentPlanState.name}</Text>
                    </Stack>

                    <Stack className={styles.productSection}>
                        <Text variant="large">Plan Features:</Text>
                        <List items={accountPerks.filter(perk => perk.tier == 2).map(perk => perk.text)} onRenderCell={onRenderCellProduct} />
                    </Stack>
                </Stack>
                <div className={styles.productStrpCard}>
                    <ProductPage
                        tier={lTiers[2]}
                        user={user}
                        activeComp={activeComp}
                        selectedGroup={selectedGroup}
                        buText="Checkout"
                        endPoint="/qStrChkoutSesh"
                        isAdd={true}
                    ></ProductPage>
                </div>
            </Modal>
            <Modal
                isOpen={isCancelModalOpen}
                onDismiss={() => {
                    setIsCancelModalOpen(false);
                }}
                isBlocking={false}
                containerClassName={styles.produltModalContainer}
            >
                <Stack
                    className={styles.prodModalCard}
                    style={{
                        width: "100%",
                        maxWidth: "1000px",
                        margin: "auto",
                        flex: "1"
                    }}
                >
                    <Text variant="xxLarge" className={styles.header}>
                        CANCEL PLAN
                    </Text>
                    <Stack className={styles.section}>
                        <Text variant="large">Account Mail: {user.userMail}</Text>
                        <Text variant="large">Current Plan: {currentPlanState.name}</Text>
                    </Stack>

                    <Stack className={styles.productSection}>
                        <Text variant="large">Plan Features:</Text>
                        <List items={accountPerks.filter(perk => perk.tier == 2).map(perk => perk.text)} onRenderCell={onRenderCellProduct} />
                    </Stack>
                </Stack>
                <div className={styles.productStrpCard}>
                    <ProductPage
                        tier={lTiers[2]}
                        user={user}
                        activeComp={activeComp}
                        selectedGroup={selectedGroup}
                        buText="Cancel"
                        endPoint="/qStrCnclSesh"
                        isAdd={false}
                    ></ProductPage>
                </div>
            </Modal>
            <Stack className={styles.container}>
                <Stack horizontal tokens={stackTokens} styles={{ root: { flexWrap: "nowrap", width: "100%" } }}>
                    <Stack
                        className={styles.card}
                        style={{
                            position: "fixed",
                            top: "85px",

                            maxWidth: "390px"
                        }}
                    >
                        <Text variant="xxLarge" className={styles.header}>
                            Account Information
                        </Text>
                        <Stack className={styles.section}>
                            <Text variant="large">Account Mail: {user.userMail}</Text>
                            <Text variant="large">Current Plan: {currentPlanState.name}</Text>
                        </Stack>

                        <Stack className={styles.section}>
                            <Text variant="large">Plan Features:</Text>
                            <List items={accountPerks.filter(perk => perk.tier == (user.tier ?? 0)).map(perk => perk.text)} onRenderCell={onRenderCell} />
                        </Stack>

                        <Stack className={styles.section}>
                            Premium Space Licenses: {groupsState.filter(grp => grp.premiumby == user.userId).length} Used out of {premiumSpacesLimit}
                            <ProgressIndicator
                                percentComplete={groupsState.filter(grp => grp.premiumby == user.userId).length / premiumSpacesLimit}
                                barHeight={5}
                                styles={{
                                    root: {
                                        margin: "5px"
                                    },

                                    progressBar: {
                                        backgroundColor: "green"
                                    }
                                }}
                            />
                        </Stack>
                        {user.tier < 2 ? (
                            <DefaultButton
                                styles={{
                                    root: {
                                        width: "100%",
                                        marginTop: "10px",
                                        borderRadius: "8px",
                                        padding: "8px",
                                        height: "auto"
                                    }
                                }}
                                text="Get Professional"
                                onClick={() => {
                                    setIsLicModalOpen(true);
                                }}
                            />
                        ) : (
                            <DefaultButton
                                styles={{
                                    root: {
                                        width: "100%",
                                        marginTop: "10px",
                                        borderRadius: "8px",
                                        padding: "8px",
                                        height: "auto"
                                    }
                                }}
                                text="Cancel"
                                onClick={() => {
                                    setIsCancelModalOpen(true);
                                }}
                            />
                        )}
                    </Stack>

                    <Stack
                        className={styles.card}
                        style={{
                            marginLeft: "400px",
                            width: "100%"
                        }}
                    >
                        <Text variant="xLarge" className={styles.header}>
                            My Spaces
                        </Text>
                        {groupsState
                            .filter(gr => gr.permissionlevel >= 3)
                            .map((space, index) => (
                                <DocumentCard key={index} className={styles.documentCard}>
                                    <Stack tokens={{ childrenGap: 2 }} className={styles.titleRow}>
                                        <div className={styles.titleGroupName}>{space.groupname}</div>

                                        <TooltipHost
                                            content="Enable Premium Features for this Space (requires a Trial or a License)"
                                            directionalHint={DirectionalHint.bottomRightEdge}
                                            calloutProps={tooltipStyles}
                                        >
                                            <div className={styles.premToggleIcon}>
                                                <MdOutlineWorkspacePremium size={19} />
                                            </div>

                                            <Toggle
                                                inlineLabel
                                                disabled={
                                                    ((space.premiumby ?? "") == "" &&
                                                        groupsState.filter(grp => grp.premiumby == user.userId).length >= premiumSpacesLimit) ||
                                                    ((space.premiumby ?? "") != "" && space.premiumby != user.userId)
                                                }
                                                checked={(space.premiumby ?? "") != ""}
                                                styles={{
                                                    root: {
                                                        marginLeft: "auto"
                                                    }
                                                }}
                                                onChange={(_event, checked) => {
                                                    handleChange(checked, space.groupid, space.premiumby);
                                                }}
                                            />
                                        </TooltipHost>
                                    </Stack>
                                    <DocumentCardDetails>
                                        <Stack tokens={{ childrenGap: 10 }} className={styles.spaceDetails}>
                                            <Text>
                                                Daily Messages: {space.dailymsgs ?? 0} / {currentPlanState.msgsplmt}
                                            </Text>
                                            <ProgressIndicator
                                                percentComplete={(space.dailymsgs ?? 0) / 50}
                                                barHeight={5}
                                                styles={{
                                                    root: {
                                                        margin: "5px"
                                                    },

                                                    progressBar: {
                                                        backgroundColor: "green"
                                                    }
                                                }}
                                            />
                                        </Stack>
                                    </DocumentCardDetails>
                                </DocumentCard>
                            ))}
                    </Stack>
                </Stack>
            </Stack>
        </div>
    );
};

export default AccountInfo;
