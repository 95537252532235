import React, { useContext } from "react";
import { useRef, useState, useEffect } from "react";
import { DetailsList, IColumn, DetailsListLayoutMode } from "@fluentui/react/lib/DetailsList";

import styles from "./SpaceUserListV2.module.css";
import {
    PrimaryButton,
    Dropdown,
    IDropdown,
    Callout,
    TextField,
    DirectionalHint,
    IDropdownOption,
    IconButton,
    IDropdownStyles,
    IStyle,
    IDetailsHeaderProps,
    DetailsHeader
} from "@fluentui/react";
import { useMsal } from "./../../MsalContext";

import { gcompmbrshps, guseraddition } from "../../interfaces";
import { GlobalContext } from "../../GlobalContext"; // Tests¦Context Accross Routes (ex. Chat/Search)
import { toast } from "react-toastify";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { dropdownStylesSpaceUserListV2, dropdownStylesInvUser, textFieldStylesSpaceUserInvListV2 } from "../../styles_glob";

interface IUserListProps {
    users: gcompmbrshps[];
    spaceSecurityUpdate: (timestamp: string) => void;
    activeComp: string;
    companies: { companyid: string; companyname: string; permissionlevel: number }[];
}
interface extendedUser extends gcompmbrshps {
    isRemoving?: boolean;
}
const SpaceUserListV2: React.FC<IUserListProps> = ({ users, spaceSecurityUpdate, activeComp, companies }) => {
    const { loggedInUser, user, selectedGroup } = useContext(GlobalContext);
    const [newInviteUser, setNewInviteUser] = useState<guseraddition>({ membermail: "", memberdisplayname: "", membercompany: "", memberlevel: 1 });
    const [isCalloutVisible, setIsCalloutVisible] = useState(false);
    const [activeCompState, setActiveCompState] = useState(activeComp);
    const [usersState, setUsersState] = useState<extendedUser[]>(users);
    const addUserButtonRef = useRef<HTMLElement>(null);
    const [isLoadingAPI, setIsLoadingAPI] = useState(false);
    const permMap: Record<number, string> = {
        1: "Viewer",
        2: "Member",
        3: "Owner"
    };

    const drpOptions: IDropdownOption[] = [
        { key: 1, text: "Viewer" },
        { key: 2, text: "Member" },
        { key: 3, text: "Owner" }
    ];

    useEffect(() => {
        //const usrs = users
        //    .filter(item => item.groupid === selectedGroup.selectionId)
        //    .map(item => {
        //        return item;
        //    });
        setUsersState(users);
    }, [users]);

    const columns: IColumn[] = [
        { key: "column1", name: "Mail", fieldName: "membermail", minWidth: 100, maxWidth: 500, isResizable: true },
        { key: "column3", name: "Group", fieldName: "groupname", minWidth: 100, maxWidth: 300, isResizable: true },
        {
            key: "column5",
            name: "Permission",
            fieldName: "memberlevel",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender: (item: gcompmbrshps) => {
                const [isDropdownVisible, setIsDropdownVisible] = useState(false);
                const dropdownRef = useRef<IDropdown | null>(null);
                useEffect(() => {
                    if (isDropdownVisible && dropdownRef.current) {
                        dropdownRef.current.focus(); // Focus Dropdown so onBLur works
                    }
                }, [isDropdownVisible]);
                return isDropdownVisible ? (
                    <Dropdown
                        className={styles.permDrpdwn}
                        styles={dropdownStylesSpaceUserListV2}
                        componentRef={dropdownRef}
                        selectedKey={item.memberlevel}
                        options={[
                            { key: 1, text: "Viewer" },
                            { key: 2, text: "Member" },
                            { key: 3, text: "Owner" }
                        ]}
                        onBlur={() => {
                            //console.log("dismissed");
                            setIsDropdownVisible(false);
                        }}
                        onChange={(event, option) => {
                            updatePermissionLevel(item.memberid, item.groupid, option!.key as number, item.membermail, false);
                            setIsDropdownVisible(false);
                        }}
                    />
                ) : (
                    <div onClick={() => setIsDropdownVisible(true)}>{permMap[item.memberlevel]}</div>
                );
            }
        },
        {
            key: "isInv",
            name: "Type",
            fieldName: "isinvite",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            data: "string",
            onRender: (item: gcompmbrshps) => {
                return (
                    <div>
                        {item.isinvite == 1 ? (
                            <span style={{ padding: "5px", margin: "2px", backgroundColor: "#eaeaea", borderRadius: "5px" }}>{"Invite Pending"}</span>
                        ) : (
                            <span style={{ padding: "5px", margin: "2px", backgroundColor: "#eaeaea", borderRadius: "5px" }}>{"User"}</span>
                        )}
                    </div>
                );
            }
        },
        {
            key: "delColumn",
            name: "Remove",
            fieldName: "delColumnName",
            minWidth: 100,
            maxWidth: 150,
            isResizable: false,
            onRender: item =>
                item.isRemoving == true ? (
                    <div style={{ margin: "0px 0px 0px -10px", padding: "0", maxWidth: "50px" }}>
                        <Spinner size={SpinnerSize.small} ariaLive="assertive" />
                    </div>
                ) : (
                    <IconButton
                        iconProps={{
                            iconName: "UserRemove",
                            styles: {
                                root: { height: "15px", width: "15px", padding: "0px 0px 0px 0px", margin: "0px -2px 0px 0px", color: "rgb(241, 100, 30)" }
                            }
                        }}
                        title="Remove User Access for this Space"
                        styles={{
                            icon: { fontSize: "10px" },
                            root: { height: "10px", width: "20px", padding: "0px 0px 0px 0px", margin: "0px 0px 0px 0px", borderRadius: "5px" }
                        }}
                        ariaLabel="Delete"
                        disabled={false}
                        className={styles.remBttn}
                        onClick={async () => {
                            let confirmed;
                            if (loggedInUser.mail == item.membermail) {
                                confirmed = window.confirm(
                                    `You are about to remove yourself from this Space.Are you sure you want to remove yourself (${item.membermail}) from this Space?`
                                );
                                if (confirmed) {
                                    setUsersState(usersState.map(a => (a.memberid === item.memberid ? { ...a, isRemoving: true } : a)));
                                    updatePermissionLevel(item.memberid, item.groupid, 0, item.membermail, true);
                                }
                            } else {
                                confirmed = window.confirm(`Are you sure you want to remove ${item.membermail} from this Space?`);
                                if (confirmed) {
                                    setUsersState(usersState.map(a => (a.memberid === item.memberid ? { ...a, isRemoving: true } : a)));
                                    updatePermissionLevel(item.memberid, item.groupid, 0, item.membermail, false);
                                }
                            }
                        }}
                    />
                )
        }
    ];

    const toggleCallout = () => {
        setIsCalloutVisible(!isCalloutVisible);
        setNewInviteUser({ membermail: "", memberdisplayname: "", membercompany: "", memberlevel: 1 });
    };
    const getHeader = () => {
        return {
            ...(loggedInUser?.token ? { Authorization: `Bearer ${loggedInUser.token}` } : {}),
            "X-MS-CLIENT-PRINCIPAL-NAME": loggedInUser?.mail ? loggedInUser.mail : "",
            "X-MS-CLIENT-PRINCIPAL-ID": loggedInUser?.userId ? loggedInUser.userId : "",
            "Content-Type": "application/json"
        };
    };

    const inviteUser = async () => {
        setIsLoadingAPI(true);
        console.log("inviteUser¦invUser", newInviteUser, "selectedGroup", activeCompState, "companyid", user.companyid);

        fetch("/qAddMember", {
            method: "POST",
            headers: getHeader(),
            body: JSON.stringify({
                usermail: newInviteUser.membermail,
                level: newInviteUser.memberlevel,
                groupid: selectedGroup.selectionId,
                companyid: activeCompState
            })
        }).then(Response => {
            spaceSecurityUpdate(new Date().toISOString());
            toggleCallout();
            toast.success("User Invited");
            setIsLoadingAPI(false);
        });
    };

    const updatePermissionLevel = async (userid: string, groupid: string, memberlevel: number, usermail: string, renav: boolean) => {
        fetch("/qUpdateMemberGroupPermissionLevel", {
            method: "POST",
            headers: getHeader(),
            body: JSON.stringify({ userid: userid, groupid: groupid, memberlevel: memberlevel, usermail: usermail })
        }).then(Response => {
            setUsersState(usersState.map(a => (a.memberid === userid ? { ...a, memberlevel: memberlevel } : a)));
            if (renav == true) {
                window.location.href = window.location.origin;
            } else {
                spaceSecurityUpdate(new Date().toISOString());
            }

            //userMngmntUpdate(new Date().toISOString());
        });
    };

    const controlStyles = {
        root: {
            margin: "0",
            paddingTop: "0px !important",
            width: "100%",
            maxWidth: "420px",
            overflowY: "scroll"
        },
        commandButton: {
            marginRight: "20px",
            marginBottom: "20px"
        },
        headerWrapper: {
            position: "sticky",
            top: 0,
            zIndex: 1
        } as IStyle,
        detailsHeader: { padding: "0px" }
    };
    const getUsers = usersState.filter(a => a.groupid === selectedGroup.selectionId);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const onRenderDetailsHeader = (props: IDetailsHeaderProps, defaultRender?: (props: IDetailsHeaderProps) => JSX.Element) => {
        // Modify the style or add additional props
        const customStyles = {
            root: {
                padding: "0"
            }
        };

        return <DetailsHeader {...props} styles={customStyles} />;
    };

    return (
        <div className={styles.userListV2Wrap}>
            <div className={styles.userListHead}>
                <PrimaryButton
                    text="Add User"
                    className={styles.addUserButton}
                    iconProps={{ iconName: "AddFriend" }}
                    onClick={toggleCallout}
                    elementRef={addUserButtonRef}
                />
            </div>
            <DetailsList
                className={styles.userDetailsListV2}
                items={getUsers}
                columns={columns}
                setKey="set"
                styles={controlStyles}
                layoutMode={DetailsListLayoutMode.justified}
                selectionPreservedOnEmptyClick={true}
                ariaLabelForSelectionColumn="Toggle Selection"
                ariaLabelForSelectAllCheckbox="Toggle Selection for all items"
                onRenderDetailsHeader={(props?: IDetailsHeaderProps) => onRenderDetailsHeader(props!)}
            />
            {isCalloutVisible && (
                <Callout
                    gapSpace={0}
                    styles={controlStyles}
                    target={addUserButtonRef.current}
                    onDismiss={toggleCallout}
                    setInitialFocus
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    className={styles.calloutMain}
                >
                    <div className={styles.calloutContent}>
                        <div className={styles.invUserTitle}>Add User</div>
                        <TextField
                            styles={textFieldStylesSpaceUserInvListV2}
                            label="Email"
                            value={newInviteUser.membermail}
                            onChange={(_, newValue) => setNewInviteUser({ ...newInviteUser, membermail: newValue || "" })}
                        />

                        <Dropdown
                            label="Permission Level"
                            className={styles.dddr}
                            styles={dropdownStylesInvUser}
                            selectedKey={newInviteUser.memberlevel}
                            options={drpOptions}
                            onChange={(_, option) => setNewInviteUser({ ...newInviteUser, memberlevel: option?.key as number })}
                        />
                        <PrimaryButton
                            text="Invite"
                            onClick={inviteUser}
                            className={styles.inviteButton}
                            disabled={isLoadingAPI || newInviteUser.membermail.length < 1 || !emailRegex.test(newInviteUser.membermail)}
                        />
                    </div>
                </Callout>
            )}
        </div>
    );
};

export default SpaceUserListV2;
