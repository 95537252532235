import React from "react";
import styles from "./AssistantTypeCards.module.css";
import { DocumentCard, DocumentCardTitle, DocumentCardDetails, DocumentCardImage } from "@fluentui/react/lib/DocumentCard";
import { ThemeProvider } from "@fluentui/react";
import { ImageFit } from "@fluentui/react/lib/Image";
import { assistantCardItems } from "../../lsts";
import customTheme from "./../../theme/customTheme";
import { assistantTypeDocCardStyles } from "../../styles_glob";

interface assistantTypeProps {
    addSpace: (specializedSettings: any) => void;
}

const AssistantTypeCards: React.FC<assistantTypeProps> = ({ addSpace }) => {
    return (
        <div className={styles.cardContainer}>
            {assistantCardItems
                .filter(item => item.disabled != true)
                .map((item, index) => (
                    <DocumentCard
                        key={index}
                        className={styles.card}
                        onClick={() => {
                            addSpace(item.creationData);
                        }}
                        styles={assistantTypeDocCardStyles}
                    >
                        <div className={styles.overlay}>
                            <span className={styles.overlayText}>Create</span>
                        </div>
                        <DocumentCardImage height={150} imageFit={ImageFit.centerCover} imageSrc={item.imageSrc} />
                        <DocumentCardDetails>
                            <DocumentCardTitle title={item.title} className={styles.cardTitle} />
                            <DocumentCardTitle title={item.description} shouldTruncate showAsSecondaryTitle className={styles.cardDesc} />
                        </DocumentCardDetails>
                    </DocumentCard>
                ))}
        </div>
    );
};

export default AssistantTypeCards;
