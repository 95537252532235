import { Settings24Regular } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";

import styles from "./SettingsButton.module.css";
import { FiEdit, FiSettings } from "react-icons/fi";
import { TbSettings2 } from "react-icons/tb";
import { PiChatTeardropDotsBold } from "react-icons/pi";
interface Props {
    className?: string;
    onClick: () => void;
    buttonText?: string;
    iconId?: string;
    forceIcon?: boolean;
    iconColor?: string;
}

export const SettingsButton = ({ className, onClick, buttonText, iconId, forceIcon }: Props) => {
    return (
        <div
            className={`${styles.container} ${className ?? ""}`}
            onClick={(ev: React.MouseEvent) => {
                onClick();
                ev.preventDefault();
                ev.stopPropagation();
            }}
        >
            {window.innerWidth > 800 && forceIcon != true ? (
                <Button
                    style={{
                        minWidth: "50px"
                    }}
                >
                    <div className={styles.btnTxt}>{buttonText}</div>
                </Button>
            ) : iconId == "TbSettings2" ? (
                <Button
                    className={`${forceIcon ? styles.btnIcnNoPad : styles.btnIcn}`}
                    icon={<TbSettings2 size={17} />}
                    onClick={(ev: React.MouseEvent) => {
                        onClick();
                        ev.preventDefault();
                        ev.stopPropagation();
                    }}
                ></Button>
            ) : (
                <Button
                    className={`${forceIcon ? styles.btnIcnNoPad : styles.btnIcn}`}
                    icon={<PiChatTeardropDotsBold size={17} />}
                    onClick={(ev: React.MouseEvent) => {
                        onClick();
                        ev.preventDefault();
                        ev.stopPropagation();
                    }}
                ></Button>
            )}
        </div>
    );
};
