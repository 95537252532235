import React, { useState, useEffect } from "react";
import { TextField, Label, Stack, IconButton, ITextFieldStyles } from "@fluentui/react";
import styles from "./TagInput.module.css";

interface TagInputProps {
    onChange: (tags: string[]) => void;
    palceholderText: string;
    initialTags?: string[];
}
const textFieldStyles: Partial<ITextFieldStyles> = {
    wrapper: {
        selectors: {
            label: {
                fontFamily: "Urbanist"
            }
        }
    },
    root: {
        borderRadius: "5px"
    },
    fieldGroup: {
        maxWidth: "800px",
        borderRadius: "5px",
        selectors: {
            "::after": {
                borderRadius: "5px ",
                border: "2px solid #404040"
            }
        }
    },
    subComponentStyles: {
        label: {
            root: {
                fontSize: "14px",
                fontFamily: "Urbanist"
            }
        }
    }
};
const TagInput: React.FC<TagInputProps> = ({ onChange, palceholderText, initialTags = [] }) => {
    const [tags, setTags] = useState<string[]>(initialTags);
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        setTags(initialTags);
    }, [initialTags]);

    const handleAddTag = () => {
        if (inputValue.trim()) {
            const newTags = [...tags, inputValue.trim()];
            setTags(newTags);
            onChange(newTags);
            setInputValue("");
        }
    };

    const handleInputChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setInputValue(newValue || "");
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            handleAddTag();
            event.preventDefault();
        }
    };

    const handleRemoveTag = (index: number) => {
        const newTags = tags.filter((_, i) => i !== index);
        setTags(newTags);
        onChange(newTags);
    };

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack horizontal tokens={{ childrenGap: 5 }} wrap className={styles.mainStack}>
                {tags.map((tag, index) => (
                    <Stack horizontal key={index} tokens={{ childrenGap: 5 }} verticalAlign="center" className={styles.tagListStack}>
                        <Stack.Item className={styles.labelAndDelBut}>
                            <Label className={styles.tagLab}>{tag}</Label>
                            <IconButton
                                iconProps={{ iconName: "Cancel" }}
                                title="Remove"
                                onClick={() => handleRemoveTag(index)}
                                styles={{
                                    root: {
                                        width: 15, // Set desired width
                                        height: 15, // Set desired height
                                        borderRadius: 3, // Set border radius (e.g., 5px)
                                        marginRight: 5 // Set margin between icon and label (e.g., 5px
                                    },
                                    icon: {
                                        fontSize: 16 // Set icon size (e.g., 16px)
                                    }
                                }}
                            />
                        </Stack.Item>
                    </Stack>
                ))}
            </Stack>
            <TextField
                placeholder={palceholderText}
                value={inputValue}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                className={styles.qTagInput}
                styles={textFieldStyles}
            />
        </Stack>
    );
};

export default TagInput;
