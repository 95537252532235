import { useState, useEffect } from "react";
import { pdfjs } from "react-pdf";
import styles from "./XplrrPDF.module.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { ThemeProvider } from "@fluentui/react";
import customTheme from "./../../theme/customTheme";
import { gdiff, gfile, gselectedgroup } from "../../interfaces";
import PageSelector from "../PageSelector/PageSelector";
import XplrrBOXDIFFPDF from "../XplrrBOXPDF/XplrrBOXDIFFPDF";
import stringSimilarity from "string-similarity";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface ViewerPDFProps {
    fileItem1: gfile | undefined;
    fileItem2: gfile | undefined;
    selectedGroup: gselectedgroup;
    rndo: string;
    diff?: gdiff[];
}

const XplrrPDFDiff = ({ fileItem1, fileItem2, selectedGroup, rndo, diff }: ViewerPDFProps) => {
    const [file1, setFile1] = useState<gfile>();
    const [file2, setFile2] = useState<gfile>();
    const [currentPage1, setCurrentPage1] = useState(0);
    const [currentPage2, setCurrentPage2] = useState(0);
    const [numPages1, setNumPages1] = useState<number>();
    const [numPages2, setNumPages2] = useState<number>();
    const [pageLoaded1, setPageLoaded1] = useState(false);
    const [pageLoaded2, setPageLoaded2] = useState(false);
    const [manualSection, setManualSecton] = useState<number>();
    useEffect(() => {
        setFile1(fileItem1);
        setFile2(fileItem2);
    }, [fileItem1, fileItem2]);

    const handleScroll1 = (event: any, pageNumCur: number) => {
        let { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        setCurrentPage1(pageNumCur);
        const element = document.getElementById(`pdfmainid9${fileItem2?.fileid}`);
        if (element) {
            const scrollRatio = scrollTop / (scrollHeight - clientHeight);
            const targetScrollTop = Math.round(scrollRatio * (element.scrollHeight - element.clientHeight));
            console.log(targetScrollTop);
            element.scrollTop = targetScrollTop;
        }
    };
    const handleScroll2 = (event: any, pageNumCur: number) => {
        let { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        setCurrentPage2(pageNumCur);
        const element = document.getElementById(`pdfmainid9${fileItem1?.fileid}`);
        if (element) {
            const scrollRatio = scrollTop / (scrollHeight - clientHeight);
            const targetScrollTop = Math.round(scrollRatio * (element.scrollHeight - element.clientHeight));
            element.scrollTop = targetScrollTop;
        }
    };
    function findClosestMatches(targetLeft: number, targetTop: number, targetText: string, array: any[], numMatches = 5) {
        // Calculate distances and add similarity scores
        const scored = array.map(item => {
            const distance = Math.sqrt(Math.pow(item.left - targetLeft, 2) + Math.pow(item.top - targetTop, 2));
            const similarity = calculateTextSimilarity(targetText, item.text);
            return { ...item, distance, similarity };
        });

        // Sort by distance and get top matches
        return scored
            .sort((a, b) => a.distance - b.distance)
            .slice(0, numMatches)
            .sort((a, b) => b.similarity - a.similarity)[0];
    }
    function findClosestMatchesPack(targetLeft: number, targetTop: number, targetText: string, array: any[], numMatches = 5) {
        const scored = array.map(item => ({
            ...item,
            distance: Math.sqrt(Math.pow(item.left - targetLeft, 2) + Math.pow(item.top - targetTop, 2)),
            similarity: stringSimilarity.compareTwoStrings(targetText, item.text)
        }));

        return scored
            .sort((a, b) => a.distance - b.distance)
            .slice(0, numMatches)
            .sort((a, b) => b.similarity - a.similarity)[0];
    }
    function calculateTextSimilarity(str1: string, str2: string) {
        const s1 = str1.toLowerCase();
        const s2 = str2.toLowerCase();
        const pairs1 = getPairs(s1);
        const pairs2 = getPairs(s2);
        const union = new Set([...pairs1, ...pairs2]);
        const intersection = new Set([...pairs1].filter(x => pairs2.has(x)));
        return (2.0 * intersection.size) / union.size;
    }
    function getPairs(str: string) {
        const pairs = new Set();
        for (let i = 0; i < str.length - 1; i++) {
            pairs.add(str.slice(i, i + 2));
        }
        return pairs;
    }
    function getTextSpanPositions(itemid: string, pageidx: number) {
        //console.log("itx¦", itemid, "¦pix¦", pageidx);
        const container = document.querySelector(`[data-diff-page="DDP_${itemid}_${pageidx}"] .react-pdf__Page__canvas`);
        if (!container) {
            console.error("container not found");
            return [];
        }
        // Get container dimensions for normalization
        const containerRect = container.getBoundingClientRect();
        const containerWidth = containerRect.width;
        const containerHeight = containerRect.height;

        // Select all the text spans within the PDF text layer
        const pageitemscont = document.querySelector(`[data-diff-page="DDP_${itemid}_${pageidx}"] .react-pdf__Page__textContent.textLayer`);
        const textspans = pageitemscont?.querySelectorAll(".react-pdf__Page__textContent span");
        if (!textspans) {
            console.error("no_spans");
            return [];
        }

        // Map through each span and calculate normalized positions
        const positions = Array.from(textspans)
            .map(span => {
                const spanRect = span.getBoundingClientRect();
                const normalizedLeft = ((spanRect.left - containerRect.left) / containerWidth) * 100;
                const normalizedTop = ((spanRect.top - containerRect.top) / containerHeight) * 100;
                const normalizedBottom = ((spanRect.bottom - containerRect.top) / containerHeight) * 100;
                return {
                    text: span.textContent,
                    left: parseFloat(normalizedLeft.toFixed(4) || "0"),
                    top: parseFloat(normalizedTop.toFixed(4) || "0"),
                    bottom: parseFloat(normalizedBottom.toFixed(4) || "0"),
                    span: span,
                    spanid: span.querySelector(":first-child")?.id,
                    pageidx: pageidx
                };
            })
            .filter(span => span.text != "" && span.left > 0 && span.top > 0);

        // Log the positions array to see the result
        //console.log("spans ⇒", positions.length);
        return positions;
    }
    function findClosestMatch(targetLeft: number, targetTop: number, array: any[]) {
        return array.reduce((closest, current) => {
            const currentDistance = Math.sqrt(Math.pow(current.left - targetLeft, 2) + Math.pow(current.top - targetTop, 2));

            const closestDistance = Math.sqrt(Math.pow(closest.left - targetLeft, 2) + Math.pow(closest.top - targetTop, 2));

            return currentDistance < closestDistance ? current : closest;
        });
    }

    const checkEvenOdd = (number: number) => {
        return number % 2 === 0 ? 2 : 3;
    };
    function shadeSection(topPercentage: number, bottomPercentage: number, canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, chunkEven: number) {
        console.log("¦canvas¦", canvas);
        // Calculate actual pixel positions
        const startY = (topPercentage / 100) * canvas.height;
        const endY = (bottomPercentage / 100) * canvas.height;

        // Set the shading style (e.g., semi-transparent gray)
        if (chunkEven == 2) {
            ctx.filter = "blur(10px)";
            ctx.fillStyle = "rgba(0, 0, 0, 0.1)";
            // Draw the rectangle from left edge to right edge
            ctx.fillRect(
                0, // x: start from left edge
                startY, // y: start from calculated top position
                canvas.width, // width: full width of canvas
                endY - startY // height: difference between end and start positions
            );
        }
        //if (chunkEven == 3) ctx.fillStyle = "rgba(0, 0, 0, 0.8)";
    }

    const diffHandler = (diff: gdiff[], fileid: string, order: number) => {
        if (order == 0) setPageLoaded1(true);
        if (order == 1) setPageLoaded2(true);

        if (!diff) {
            console.log("no_diff");
            return;
        }
        console.log("¦diff¦fileid¦", diff);
        setTimeout(() => {
            // Results Array
            let pyresults = diff[0].results;
            let adjustedFileOrder = `${diff[0].fid1}` == fileid ? 1 : 2;

            window.globalDiff = diff[0].results;

            let pypagenum = -1;
            let isnewpage = true;
            let nextisnew = false;
            for (let chunkidx = 0; chunkidx < pyresults.length; chunkidx++) {
                let pychunk = pyresults[chunkidx];
                let pycoordinates = adjustedFileOrder == 1 ? pychunk.coordinates1 : pychunk.coordinates2;
                let chunkEven = checkEvenOdd(chunkidx);
                let canvas;
                let top_percentage;

                // Coordinates Array (If chunk crosspage, will be more than 1, else always 1)
                for (let cooridx = 0; cooridx < pycoordinates.length; cooridx++) {
                    let pageidx = pycoordinates[cooridx].page; // First Page of Chunk [always]
                    let pydata = pycoordinates[cooridx].data;

                    let jspositions = getTextSpanPositions(fileid, pageidx); // Get JS Positions for Page

                    for (let cooidx = 0; cooidx < (pydata.length || 0); cooidx++) {
                        let pyitem = pydata[cooidx];

                        let pycoor = pyitem["coor"];
                        let pytext = pyitem["text"];

                        let chunk_index = checkEvenOdd(chunkidx);
                        let pytagged = pyitem["text_tagged"] || "";

                        const [pycoor_left, pycoor_top] = pycoor[0];
                        let pycoor_left_perc = pycoor_left * 100;
                        let pycoor_top_perc = pycoor_top * 100;

                        let jsmatch = findClosestMatchesPack(pycoor_left_perc, pycoor_top_perc, pytext, jspositions);

                        try {
                            const span = document.getElementById(jsmatch.spanid);

                            if (!span) return;

                            isnewpage = pyitem["page_num"] != pypagenum ? true : false;
                            pypagenum = pyitem["page_num"];

                            if (cooidx != pydata.length - 1) {
                                let nextpyitem = pydata[cooidx + 1];
                                let nextopypagenum = nextpyitem["page_num"];
                                nextisnew = nextopypagenum != pypagenum ? true : false;
                            } else {
                                nextisnew = true;
                            }

                            if (cooridx == 0) {
                                if (cooidx == 0) {
                                    let parent = span.parentElement?.parentElement?.parentElement;
                                    canvas = parent?.querySelector(".react-pdf__Page__canvas");
                                    top_percentage = jsmatch.bottom;
                                }
                                if (cooidx == pydata.length - 1) {
                                    if (canvas) {
                                        const ctx = (canvas as HTMLCanvasElement).getContext("2d");
                                        if (ctx && top_percentage) {
                                            //shadeSection(top_percentage, jsmatch.bottom, canvas as HTMLCanvasElement, ctx, chunkEven);
                                        }
                                    }
                                }
                            }
                            if (cooridx == 1) {
                                if (cooidx == 0) {
                                    let parent = span.parentElement?.parentElement?.parentElement;
                                    canvas = parent?.querySelector(".react-pdf__Page__canvas");
                                    top_percentage = jsmatch.bottom;
                                }
                                if (cooidx == pydata.length - 1) {
                                    if (canvas) {
                                        const ctx = (canvas as HTMLCanvasElement).getContext("2d");
                                        if (ctx && top_percentage) {
                                            //shadeSection(top_percentage, jsmatch.bottom, canvas as HTMLCanvasElement, ctx, chunkEven);
                                        }
                                    }
                                }
                            }
                            if (pytagged != "" && pytagged != undefined && pytagged != null) {
                                span.textContent = "";
                                span.insertAdjacentHTML("beforeend", pytagged);
                            }
                        } catch {}
                    }
                }
            }
        }, 600);
    };

    const handleTimeout = (fileid: string) => {
        console.log("¦fid¦", fileid);
        if (fileid == `${file1?.fileid}`) {
            diffHandler(diff || [], fileid, 0);
        } else if (fileid == `${file2?.fileid}`) {
            diffHandler(diff || [], fileid, 1);
        }
    };
    const callBackVisibleEntries = (visibleEntries: number[]) => {};
    const setContentList = (contentList: { title: string; dest: number }[]) => {};
    const callBackIsLoading = (isLoading: boolean) => {};
    const handlePageChangeFromPageSelector1 = (event: React.ChangeEvent<HTMLSelectElement>) => {
        window.globalUserScrollLazyLoadEnabled == true;
        setManualSecton(Number(event.target.value));
        setCurrentPage1(Number(event.target.value));
        scrollToPageNoSmooth(Number(event.target.value), file1?.fileid || "0");
        reendableUserScrollHandling();
    };
    const handlePageChangeFromPageSelector2 = (event: React.ChangeEvent<HTMLSelectElement>) => {
        window.globalUserScrollLazyLoadEnabled == true;
        setManualSecton(Number(event.target.value));
        setCurrentPage2(Number(event.target.value));
        scrollToPageNoSmooth(Number(event.target.value), file2?.fileid || "0");
        reendableUserScrollHandling();
    };
    const reendableUserScrollHandling = (tm: number = 500) => {
        setTimeout(() => (window.globalUserScrollLazyLoadEnabled = true), tm);
    };
    const scrollToPageNoSmooth = (pN: number, targetFile: string) => {
        if (targetFile == "0") return;
        var node = document.querySelector(`[data-diff-page="DDP_${targetFile}_${pN - 1}"`);
        if (node) node.scrollIntoView({ behavior: "auto", block: "center" });
    };

    return (
        <div className={styles.xplrrPDFMain}>
            <div className={styles.xplrrPDFMainInternalWrap}>
                <ThemeProvider theme={customTheme}>
                    <div className={styles.pdfWrapXplrr} id="pdfWrapXplrrIDENT">
                        <div className={styles.pageSelectorXplrr}>
                            <div className={styles.pageSelectorOnly}>
                                <PageSelector
                                    currentPage={currentPage1}
                                    numPages={numPages1 ? numPages1 : 0}
                                    handlePageChange={handlePageChangeFromPageSelector1}
                                ></PageSelector>
                            </div>
                            <div className={styles.totPagesDisplay}>/ {numPages1}</div>
                        </div>
                        <div className={`${styles.pdfViewInv}`}>
                            <XplrrBOXDIFFPDF
                                key={`rndo_${rndo}_${file1?.fileid}`}
                                file={file1}
                                handleTimeout={handleTimeout}
                                handleScroll={handleScroll1}
                                setPages={(numPages: number) => {
                                    setNumPages1(numPages);
                                }}
                                startPage={1}
                                callBackVisibleEntries={callBackVisibleEntries}
                                setContentList={setContentList}
                                callBackIsLoading={callBackIsLoading}
                                companyId={selectedGroup.companyid}
                                srcLoc={"inv"}
                                diff={diff}
                            />
                        </div>

                        <div className={`${styles.pdfViewInv}`}>
                            <XplrrBOXDIFFPDF
                                key={`rndo_${rndo}_${file2?.fileid}`}
                                file={file2}
                                handleTimeout={handleTimeout}
                                handleScroll={handleScroll2}
                                setPages={(numPages: number) => {
                                    setNumPages2(numPages);
                                }}
                                startPage={1}
                                callBackVisibleEntries={callBackVisibleEntries}
                                setContentList={setContentList}
                                callBackIsLoading={callBackIsLoading}
                                companyId={selectedGroup.companyid}
                                srcLoc={"inv"}
                                diff={diff}
                            />
                            <div className={styles.pageSelectorXplrr}>
                                <div className={styles.pageSelectorOnly}>
                                    <PageSelector
                                        currentPage={currentPage2}
                                        numPages={numPages2 ? numPages2 : 0}
                                        handlePageChange={handlePageChangeFromPageSelector2}
                                    ></PageSelector>
                                </div>
                                <div className={styles.totPagesDisplay}>/ {numPages2}</div>
                            </div>
                        </div>
                    </div>
                </ThemeProvider>
            </div>
        </div>
    );
};

export default XplrrPDFDiff;
