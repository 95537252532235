import React, { useState, ChangeEvent, FormEvent } from "react";

interface ImageChangerProps {
    maxHeight?: number;
    maxWidth?: number;
    defaultImage?: string;
    customImage?: string;
    onImageChange: (imageurl: string) => void;
}

const ImageChanger: React.FC<ImageChangerProps> = ({ maxHeight = 400, maxWidth = 600, defaultImage = "", customImage = "", onImageChange }) => {
    const [imageUrl, setImageUrl] = useState<string>(customImage || defaultImage);
    const [inputUrl, setInputUrl] = useState<string>("");
    const [error, setError] = useState<string | null>(null);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError(null);
        setImageUrl(inputUrl);
        onImageChange(inputUrl);
    };
    const setDefault = () => {
        console.log("setDefault", defaultImage);
        setError(null);
        setImageUrl(defaultImage);
        onImageChange("");
    };
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInputUrl(e.target.value);
    };

    const handleImageError = () => {
        setError("Failed to load image. Please check the URL and try again.");
        setImageUrl("");
    };

    return (
        <div className="image-changer">
            <div className="image-input">
                <form onSubmit={handleSubmit} className="url-form">
                    <input type="text" value={inputUrl} onChange={handleInputChange} placeholder="Enter image URL..." className="url-input" />
                    <button type="submit" className="submit-button">
                        Update Image
                    </button>
                </form>
                <button className="def-button" onClick={setDefault}>
                    Set Default
                </button>
            </div>

            <div className="image-container">
                {imageUrl && <img src={imageUrl} alt="Assistant Header Image" onError={handleImageError} className="displayed-image" />}
                {error && <p className="error-message">{error}</p>}
                {!imageUrl && !error && <p className="placeholder-text">No Image. Please enter an image URL above.</p>}
            </div>

            <style>{`
              .image-input{
                  display: flex;
                }
              .def-button {
                  height: 38px;
                  margin-left: 10px;
                  padding: 8px 16px;
                  background-color: #e65d1c;
                  color: white;
                  border: none;
                  border-radius: 4px;
                  cursor: pointer;
                  font-size: 16px;
                  transition: background-color 0.2s;
                  border: none;
                }

                .def-button:hover {
                    background-color: #e74c3c;
                }
                .image-changer {
                    max-width: 100%;
                    margin: 5px auto;
                    padding: 5px;
                }

                .url-form {
                    display: flex;
                    gap: 10px;
                    margin-bottom: 20px;
                }

                .url-input {
                    flex: 1;
                    padding: 8px 12px;
                    border-radius: 4px;
                    font: normal normal 100 13px/13px "Urbanist", "Roboto";
                    box-sizing: border-box; /* Include padding and border in element's total width and height */
                    border: 1px solid rgba(180, 0, 170, 1);
                    background-color: transparent; /* No background color */
                    color:rgb(47, 72, 88);
                    transition: all 0.5s ease-out; /* Smooth transition for all changes */
                    border-radius: 9px;
                    width: 100%;
                }

                .url-input:focus {
                    outline: none;
                    border-color: #0066cc;
                }

                .submit-button {
                    padding: 8px 16px;
                    background-color: #e65d1c;
                    color: white;
                    border: none;
                    border-radius: 4px;
                    cursor: pointer;
                    font-size: 16px;
                    transition: background-color 0.2s;
                }

                .submit-button:hover {
                    background-color: #e74c3c;
                }

                .image-container {
                    border: 2px dashed #ddd;
                    border-radius: 8px;
                    padding: 20px;
                    min-height: 200px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 300px;
                }

                .displayed-image {
                    max-width: 100%;
                    max-height: ${maxHeight}px;
                    object-fit: contain;
                }

                .error-message {
                    color: #dc3545;
                    text-align: center;
                }

                .placeholder-text {
                    color: #666;
                    text-align: center;
                }
            `}</style>
        </div>
    );
};

export default ImageChanger;
